import classNames from 'classnames';
import React from 'react';
import FeatherIcon from 'feather-icons-react';

function IconButton(
  { className = '', iconClassName = '', needPadding = true, icon, onClick, disabled, isPrimary = true, ...rest },
  ref
) {
  return (
    <button
      type="button"
      ref={ref}
      className={classNames('btn', className, {
        'p-0': needPadding,
      })}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <FeatherIcon
        icon={icon}
        className={classNames(iconClassName, 'tl-stroke-width-2 wd-20-f ht-20-f ', {
          'tx-primary': !disabled && isPrimary,
          'tx-color-04': disabled,
          'tx-danger': rest?.dangerIcon,
        })}
      />
    </button>
  );
}

export default React.forwardRef(IconButton);
