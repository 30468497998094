import { BehaviorSubject } from 'rxjs';
import { PROJECT_NAME } from '../../utils/constants/AppConstant';
import { getCookieByName } from '../../utils/utils';

export const tokenKey = `${PROJECT_NAME}-userToken`;

const currentUserToken = new BehaviorSubject(getCookieByName(tokenKey));

export const authStorage = {
  setToken,
  removeToken,
  isAuthenticated,
  getToken,
  currentUser: currentUserToken.asObservable(),
};

function setToken(token) {
  document.cookie = `${tokenKey}=${token}; path=/;`;
  currentUserToken.next(token);
  return token;
}

function getToken() {
  return currentUserToken.value;
}

function isAuthenticated() {
  return currentUserToken.value;
}

function removeToken() {
  // remove token from local storage to log user out
  currentUserToken.next(null);
  document.cookie = `${tokenKey}=; path=/;`;
}
