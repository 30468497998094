import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FEEDSPACE_REPORT_BUG_VIDEO_LINK, OVERALL_FEEDBACK_FEEDSPACE_ID } from '../../utils/constants/AppConstant';
import TLFeedspace from './TL-Feedspace';

const Footer = () => {
  const user = useSelector((state) => state?.user[0]);
  const { details } = user;
  const userDetails = {};

  // in else if use other user types and there details
  if (details?.therapist) {
    userDetails.id = details.id;
    userDetails.name = details.name;
    userDetails.type = details.therapist.type.id;
    userDetails.page = window.location.pathname;
  }

  useEffect(() => {
    if (window.feedspace) {
      window.feedspace?.init();
    }
  }, []);

  return (
    <>
      <footer className="footer">
        <div>
          <span>&copy; STAR</span> <span>{new Date().getFullYear()}</span>
        </div>
        <div>
          <nav className="nav">
            <a href={FEEDSPACE_REPORT_BUG_VIDEO_LINK} className="nav-link" target="_blank" rel="noreferrer">
              Report A Bug
            </a>
            <div className="bd bd-r-1 mg-x-10" />
            <TLFeedspace
              href="#"
              as="a"
              className="nav-link"
              label="Give Feedback"
              id={OVERALL_FEEDBACK_FEEDSPACE_ID}
              userDetails={userDetails}
            />
          </nav>
        </div>
      </footer>
    </>
  );
};

export default Footer;
