import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import StudentLoginRightInfo from '../../components/student/login/RightInfo';
import StudentLoginForm from '../../components/student/login/Form';
import AnotherWayLogin from '../../components/student/login/AnotherWayLogin';
import { authStorage } from '../../services/localStorage/authStorage';
import path from '../../routes/path';
import { GetActivity } from '../../utils/student';
import { APP_NAME } from '../../utils/constants/AppConstant';
import { fbEvents, gtm } from '../../utils';
import useQueryParams from '../../hooks/useQueryParams';
import { loginReference } from '../../services/api/student/authService';

const Login = ({ history }) => {
  const [show, setShow] = useState(false);
  const query = useQueryParams();
  const ref = query.get('ref');

  useEffect(() => {
    if (ref) {
      try {
        const sendRef = async () => {
          await loginReference({
            ref,
          });
        };
        sendRef();
      } catch (_) {
        //
      }
    }
  }, [ref]);

  useEffect(() => {
    if (authStorage.isAuthenticated()) history.replace(path.student.home);
  }, [history]);

  useEffect(() => {
    GetActivity(window.location.pathname);

    gtm();
    fbEvents();
  }, []);

  return (
    <>
      <Helmet title={`Login - Student - ${APP_NAME}`}>
        <meta name="robots" content="index,follow" />
        <meta name="description" content="E-Therapy: Student Login" />
      </Helmet>
      <Container className="container d-md-flex justify-content-center align-items-center pd-l-15-f pd-r-15-f">
        <div className="justify-content-center student-login-container wd-100p wd-xl-950 wd-lg-850 wd-md-800">
          {show && <AnotherWayLogin />}
          <Card className="media align-items-stretch justify-content-center bd bd-5 rounded-10 shadow-base d-block d-lg-flex d-sm-block bg-white flex-row">
            <Card.Body className="sign-wrapper pd-t-0 pd-b-0 pd-x-20 mg-t-20 mg-b-20 mg-r-auto mg-l-auto pd-md-0 mg-lg-30 mg-lg-50 wd-100p wd-sm-450-f wd-md-350-f">
              <StudentLoginForm setShowAnotherWayLogin={(a) => setShow(a)} />
            </Card.Body>
            <Card.Body className="align-items-center d-lg-flex bg-gray-100 pd-20 pd-lg-l-30 pd-lg-r-30 pd-lg-t-50 pd-lg-b-50">
              <StudentLoginRightInfo />
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default Login;
