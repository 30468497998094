import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Skeleton from 'react-loading-skeleton';

const CalendarSkeleton = () => (
  <>
    <Row className="align-items-center">
      <Col md={3}>
        <Skeleton className="ht-30" />
      </Col>
      <Col md={6}>
        <Skeleton className="ht-15" />
      </Col>
      <Col md={3}>
        <Skeleton className="ht-30" />
      </Col>
    </Row>
    <Table responsive className="table table-striped dataTable mg-t-20">
      <thead>
        <tr>
          <th>
            <Skeleton className="ht-10" />
          </th>
          <th>
            <Skeleton className="ht-10" />
          </th>
          <th>
            <Skeleton className="ht-10" />
          </th>
          <th>
            <Skeleton className="ht-10" />
          </th>
          <th>
            <Skeleton className="ht-10" />
          </th>
          <th>
            <Skeleton className="ht-10" />
          </th>
          <th>
            <Skeleton className="ht-10" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
          <td>
            <Skeleton className="ht-60" />
          </td>
        </tr>
      </tbody>
    </Table>
  </>
);

export default CalendarSkeleton;
