import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toggleTheme } from '../../../store/themeSlice';
import { sendAmplitudeData } from '../../../utils/amplitude';

const TLChangeContrast = () => {
  const [changeContrast, setChangeContrast] = useState('Change Contrast');
  const dispatch = useDispatch();

  const onChangeContrast = () => {
    setChangeContrast(changeContrast === 'Change Contrast' ? 'Reset Contrast' : 'Change Contrast');
    dispatch(toggleTheme('student'));
    sendAmplitudeData(changeContrast);
  };

  useEffect(() => {
    const bodyClass = document.body.classList;
    setChangeContrast(bodyClass.contains('contrast-mode') ? 'Reset Contrast' : 'Change Contrast');
  }, []);

  return (
    <Button
      onClick={onChangeContrast}
      className="change-contrast btn btn-outline-purple tx-bold d-inline-flex pd-y-0 ht-35 align-items-center"
    >
      {changeContrast}
    </Button>
  );
};

export default TLChangeContrast;
