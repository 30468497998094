import React, { useEffect } from 'react';
import { FEEDSPACE_ID_GIVE_FEEDBACK } from '../../utils/constants/AppConstant';
import TLFeedspace from './TL-Feedspace';

function TLGiveFeedbackBtn({ userDetails }) {
  useEffect(() => {
    if (window.feedspace) {
      window.feedspace?.init();
    }
  }, []);

  return (
    <div className="mg-t-15 ">
      <TLFeedspace
        as="button"
        className="btn btn-success mg-t-7 tx-white btn-block"
        label="Give Feedback"
        id={FEEDSPACE_ID_GIVE_FEEDBACK}
        userDetails={userDetails}
      />
    </div>
  );
}

export default React.memo(TLGiveFeedbackBtn);
