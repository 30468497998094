import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import Routes from './routes';
import 'react-loading-skeleton/dist/skeleton.css';
import { useScript } from './hooks/useScript';
import { setFeedspaceHasInstantiated } from './store/feedspaceSlice';
import TLToast from './components/common/TLToast';
import { setError } from './store/generalSlice';
import TLFullScreenLoader from './components/common/TL-FullScreenLoader';
import { isWindowsOS } from './utils/utils';

const isWindow = isWindowsOS();

function App() {
  // include feedspace
  const { hasLoaded } = useScript('https://js.feedspace.io/v1/');
  const dispatch = useDispatch();
  const error = useSelector((state) => state.general.error);
  const showFullScreenLoader = useSelector((state) => state.general.showFullScreenLoader);

  useEffect(() => {
    if (hasLoaded) {
      dispatch(setFeedspaceHasInstantiated(true));
    }
  }, [dispatch, hasLoaded]);

  const handleOnlineNetworkStatus = () => {
    toast.dismiss();
    toast.success("You're back online.", {
      icon: <FeatherIcon className="tx-50" icon="wifi" />,
    });
  };

  const handleOfflineNetworkStatus = () => {
    toast.dismiss();
    toast.error('No Internet Connection.', {
      icon: <FeatherIcon className="tx-50" icon="wifi-off" />,
    });
  };

  useEffect(() => {
    window.addEventListener('online', handleOnlineNetworkStatus);
    window.addEventListener('offline', handleOfflineNetworkStatus);

    return () => {
      window.removeEventListener('online', handleOnlineNetworkStatus);
      window.removeEventListener('offline', handleOfflineNetworkStatus);
    };
  }, []);

  return (
    <div className={`App ${isWindow ? 'window-Os' : ''}`}>
      <div className="app-main">
        <div className="app-container">
          <TLToast
            toastTitle={error.title}
            message={error.message}
            show={error.hasError}
            variant={error.variant}
            handleClose={() => {
              dispatch(
                setError({
                  hasError: false,
                  message: null,
                  variant: '',
                  title: '',
                })
              );
            }}
          />
          {showFullScreenLoader ? <TLFullScreenLoader /> : null}
          <Routes />
        </div>
      </div>
    </div>
  );
}

export default App;
