import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import StudentProfileTopBox from '../../components/student/profile/TopInfo';
import StudentProfileTab from '../../components/student/profile/Tabs';

import { GetActivity } from '../../utils/student';
import { Profile } from '../../services/api/student/authService';
import { APP_NAME } from '../../utils/constants/AppConstant';
import { sendAmplitudeData } from '../../utils/amplitude';

const StudentProfile = () => {
  const [data, setData] = useState('');

  async function getData() {
    try {
      const res = await Profile();
      if (res) {
        setData(res.data.data);
      }
    } catch (_) {
      //
    }
  }

  useEffect(() => {
    GetActivity(window.location.pathname);
    getData();
    sendAmplitudeData('Profile');
  }, []);

  return (
    <>
      <Helmet title={`Profile - Student - ${APP_NAME}`}>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container>
        <StudentProfileTopBox data={data} />
        <StudentProfileTab data={data} />
      </Container>
    </>
  );
};

export default StudentProfile;
