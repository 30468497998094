import React, { useState, useRef, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Keyboard from 'react-simple-keyboard';
import { useHistory, useLocation } from 'react-router-dom';
import 'react-simple-keyboard/build/css/index.css';
import validator from 'validator';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import path from '../../../routes/path';
import { setUser } from '../../../store/userSlice';
import httpService from '../../../services/api/httpService';
import { login } from '../../../services/api/student/authService';
import { CAPTCHA_KEY } from '../../../utils/constants/AppConstant';
import keyboardIcon from '../../../assets/images/keyboard.svg';
import { sendAmplitudeData, setAmplitudeUserId } from '../../../utils/amplitude';

const useOutsideClick = (keyboardRef, userRef, dobRef, parentRef, callback) => {
  useEffect(() => {
    const onMouseDown = (e) => {
      if (keyboardRef.current) {
        if (!keyboardRef.current.contains(e.target) && userRef.current && !userRef.current.contains(e.target)) {
          callback(false);
        } else if (!keyboardRef.current.contains(e.target) && dobRef.current && !dobRef.current.contains(e.target)) {
          callback(false);
        } else if (
          !keyboardRef.current.contains(e.target) &&
          parentRef.current &&
          !parentRef.current.contains(e.target)
        ) {
          callback(false);
        } else if (!keyboardRef.current.contains(e.target)) {
          callback(false);
        }
      }
    };
    document.addEventListener('mousedown', onMouseDown);

    return () => document.removeEventListener('mousedown', onMouseDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboardRef, userRef, dobRef, parentRef]);
};

const AnotherWayLogin = ({ isStaffLogin }) => {
  const dispatch = useDispatch();
  const [loginLoading, setLoginLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [parentName, setParentName] = useState('');
  const [parentNameType, setparentNameType] = useState('hidden');
  const [parentNameError, setParentNameError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [monthError, setMonthError] = useState('');
  const [dayError, setDayError] = useState('');
  const [yearError, setYearError] = useState('');
  const [apiError, setApiError] = useState('');
  const [activeField, setActiveField] = useState('Initials');
  const recaptchaRef = React.createRef();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    resetAllInputs();
    resetErrors();
  };

  const [inputs, setInputs] = useState([]);
  const [inputName, setInputName] = useState('shift');
  const [layout, setLayout] = useState('shift');
  const keyboard = useRef();
  const keyboardRef = useRef(null);

  const [showKeyboard, setShowKeyboard] = useState(false);
  const [pattern, setPattern] = useState('');
  const [maxLength, setMaxLength] = useState('');
  const FirstNameFocus = useRef(null);
  const LastNameFocus = useRef(null);
  const loginParentNameFocus = useRef(null);
  const [showStudent, setShowStudent] = useState('hidden');
  const [showAdditionalInfo, setShowAdditionalInfo] = useState([]);
  const history = useHistory();
  const predefineMonth = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const fineMonthNumeric = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  useOutsideClick(keyboardRef, FirstNameFocus, LastNameFocus, loginParentNameFocus, setShowKeyboard);

  function onClickShowPopup() {
    setShow(true);
  }
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        FirstNameFocus.current.focus();
      }, [firstName]);
    }
  }, [show, firstName]);

  useEffect(() => {
    if (inputs.firstName !== undefined) {
      setFirstName(inputs.firstName.charAt(0).toUpperCase());
    }
    if (inputs.lastName !== undefined) {
      setLastName(inputs.lastName.charAt(0).toUpperCase());
    }
    if (parentNameType === 'text') {
      if (inputs.parentName !== undefined) {
        setParentName(inputs.parentName);
      }
    }
  }, [inputs, parentNameType]);

  useEffect(() => {
    if (month !== undefined) {
      setMonth(month);
    }
    if (day !== undefined) {
      setDay(day);
    }
    if (year !== undefined) {
      setYear(year);
    }
  }, [month, day, year]);

  function FirstNameCall() {
    setActiveField('firstName');
    FirstNameFocus.current.focus();
    setPattern(getNameRegx());
    setShowKeyboard((prevState) => !prevState);
    setPattern();
    setMaxLength(50);
  }

  function LastNameCall() {
    setActiveField('lastName');
    LastNameFocus.current.focus();
    setPattern(getNameRegx());
    setShowKeyboard((prevState) => !prevState);
    setPattern();
    setMaxLength(50);
  }

  function handleFocusparentName() {
    setActiveField('parentName');
    setLayout('default');
    loginParentNameFocus.current.focus();
    setPattern(getNameRegx());
    setShowKeyboard((prevState) => !prevState);
    setPattern();
    setMaxLength(50);
    setPattern();
  }

  const onChangeAll = (value) => {
    const keyInput = Object.keys(value)[0];
    setInputs((prevState) => ({
      ...prevState,
      [keyInput]: value[keyInput],
    }));
  };

  const onKeyPress = (button) => {
    if (button === '{shift}' || button === '{lock}') handleShift();
  };

  const handleShift = () => {
    const newLayoutName = layout === 'default' ? 'shift' : 'default';
    setLayout(newLayoutName);
  };

  const FirstNameInput = (event) => {
    if (event.target.value.length === 15) return;
    const inputVal = event.target.value.replace(/[^a-zA-Z0-9\s.'-]+$/gi, '');

    setInputs((value) => ({
      ...value,
      [inputName]: inputVal,
    }));
  };

  const LastNameInput = (event) => {
    if (event.target.value.length === 15) return;
    const inputVal = event.target.value.replace(/[^a-zA-Z0-9\s.'-]+$/gi, '');

    setInputs((value) => ({
      ...value,
      [inputName]: inputVal,
    }));
  };

  const onChangeParentNameInput = (event) => {
    const inputVal = event.target.value;

    setInputs((value) => ({
      ...value,
      [inputName]: inputVal,
    }));
  };

  const getInputValue = (value) => inputs[value] || '';

  const generateYearOptions = () => {
    const arr = [];
    let startYear = 1990;
    if (isStaffLogin) {
      startYear = 1900;
    }
    const endYear = new Date().getFullYear();
    for (let i = endYear - 2; i >= startYear; i--) {
      arr.push(<option value={i}>{i}</option>);
    }
    return arr;
  };

  const generateDayOptions = () => {
    const arr = [];
    const startDay = '1';
    const endDay = '31';

    for (let i = startDay; i <= endDay; i++) {
      if (i < 10) {
        arr.push(<option value={`0${i}`}>{`0${i}`}</option>);
      } else {
        arr.push(<option value={i}>{i}</option>);
      }
    }
    return arr;
  };

  async function StudentLogin(studentId = null) {
    const recaptchaValue = await recaptchaRef?.current?.executeAsync();
    setLoginLoading(true);
    const res = await login({
      password: `${firstName + lastName}-${month}${day}${year}`,
      captcha: recaptchaValue || crypto.randomUUID(),
      captcha_validated: 1,
      parent_name: parentName,
      student_id: studentId,
      email: isStaffLogin ? parentName : undefined,
      staff: isStaffLogin ? 1 : 0,
    });
    setLoginLoading(false);
    if (res) {
      const { access_token, user, has_multiple_password, has_parents_name_same, type, students } = res.data.data;
      if (has_multiple_password) {
        setparentNameType('text');
        setApiError(res.data.message);
      }
      if (has_parents_name_same) {
        setShowStudent('text');
        setShowAdditionalInfo(students);
        setApiError(res.data.message);
      } else if (access_token || type === 1 || type === 6) {
        httpService.setToken(access_token);
        const isStaffUser = user?.is_staff;
        dispatch(setUser({ ...user, loggedIn: true, studentLogin: true, isStaffUser }));
        const event = 'Another Way Log In Successful';
        const eventProperties = {
          'User Type':
            // eslint-disable-next-line no-nested-ternary
            type === 1 ? 'Student' : type === 6 ? 'FLVS' : '',
        };
        setAmplitudeUserId(user.star_user_id);
        sendAmplitudeData(event, eventProperties);
        if (location.state && location.state.pathname && !location.state.pathname?.includes('logout')) {
          const url = `${location.state.pathname}${location.state?.search || ''}`;
          history.push(url);
        } else {
          history.push(path.student.home);
        }
        setShow(false);
      }
    }
  }

  async function handleStudentId(id) {
    StudentLogin(id);
  }

  function resetErrors() {
    setFirstNameError('');
    setLastNameError('');
    setMonthError('');
    setDayError('');
    setYearError('');
    setParentNameError('');
  }

  function resetAllInputs() {
    setInputs((prevState) => ({
      ...prevState,
      firstName: '',
      lastName: '',
      parentName: '',
    }));
    setMonth('');
    setDay('');
    setYear('');
  }

  const studentSubmit = async (e) => {
    e.preventDefault();
    let isError = false;
    try {
      resetErrors();
      if (validator.isEmpty(firstName)) {
        setFirstNameError('First Name is required ');
        isError = true;
      }

      if (validator.isEmpty(lastName)) {
        setLastNameError('Last Name is required');
        isError = true;
      }

      if (validator.isEmpty(month)) {
        setMonthError('Month is required');
        isError = true;
      }

      if (validator.isEmpty(day)) {
        setDayError('Day is required');
        isError = true;
      }

      if (validator.isEmpty(year)) {
        setYearError('Year is required');
        isError = true;
      }

      if (validator.isEmpty(parentName) && parentNameType === 'text') {
        if (isStaffLogin) {
          setParentNameError('Email ID is required');
        } else {
          setParentNameError('Parent Name is required');
        }
        isError = true;
      }

      if (isError) return;

      await StudentLogin();
    } catch (error) {
      setLoginLoading(false);
      setApiError(error?.response?.data?.message || 'Something went wrong');
    }
  };

  // Allow `spaces`, `'` (apostrophe), `-` (hyphens), `.` (period), `0-9` (numbers)
  function getNameRegx() {
    return /[^a-zA-Z0-9\s.'-]+$/;
  }

  return (
    <>
      {CAPTCHA_KEY ? <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={CAPTCHA_KEY} /> : null}
      <Card className="mg-b-15 mg-md-b-30 mg-lg-b-40 bd bd-2 pd-15 text-center text-danger bd-danger">
        <p className="mg-b-0">
          If you have trouble logging into system then please{' '}
          <u>
            <span style={{ cursor: 'pointer' }} className="text-danger tx-semibold" onClick={() => onClickShowPopup()}>
              Click Here
            </span>
          </u>{' '}
          to try another way to login
        </p>
      </Card>
      <Modal show={show} onHide={handleClose} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title>{isStaffLogin ? 'Staff' : 'Student'} Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="pos-relative">
              <Form.Group>
                <div className="input-group mg-b-0">
                  <Form.Control
                    tabIndex="1"
                    type="text"
                    placeholder="First Name"
                    autoComplete="off"
                    onChange={FirstNameInput}
                    ref={FirstNameFocus}
                    value={getInputValue('firstName')}
                    onFocus={() => {
                      setInputName('firstName');
                      setMaxLength(50);
                    }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" onClick={() => FirstNameCall()}>
                      <img className="pos-relative t--3" src={keyboardIcon} alt="Keyboard Icon" />
                    </span>
                  </div>
                </div>
                <Form.Text className="tx-13 tx-danger">{firstNameError}</Form.Text>
              </Form.Group>
              <Form.Group>
                <div className="input-group mg-b-0 mg-t-20">
                  <Form.Control
                    tabIndex="1"
                    type="text"
                    placeholder="Last Name"
                    autoComplete="off"
                    onChange={LastNameInput}
                    ref={LastNameFocus}
                    value={getInputValue('lastName')}
                    onFocus={() => {
                      setInputName('lastName');
                      setPattern(getNameRegx());
                      setMaxLength(50);
                    }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" onClick={() => LastNameCall()}>
                      <img className="pos-relative t--3" src={keyboardIcon} alt="Keyboard Icon" />
                    </span>
                  </div>
                </div>
                <Form.Text className="tx-13 tx-danger">{lastNameError}</Form.Text>
              </Form.Group>
              <Form.Label className="d-block mg-t-10">Date of Birth:</Form.Label>
              <Row className="mb-3 row-xs">
                <Form.Group as={Col}>
                  <Form.Control
                    as="select"
                    tabIndex="3"
                    aria-label="Default select example"
                    className="form-control"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                  >
                    <option>Month</option>
                    {predefineMonth.map((value, index) => (
                      <option key={index} value={fineMonthNumeric[index]}>
                        {value}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Text className="tx-13 tx-danger">{monthError}</Form.Text>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control
                    as="select"
                    tabIndex="4"
                    aria-label="Default select example"
                    className="form-control"
                    value={day}
                    onChange={(e) => setDay(e.target.value)}
                  >
                    <option>Day</option>
                    {generateDayOptions()}
                  </Form.Control>
                  <Form.Text className="tx-13 tx-danger">{dayError}</Form.Text>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Control
                    as="select"
                    tabIndex="5"
                    aria-label="Default select example"
                    className="form-control"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option>Year</option>
                    {generateYearOptions()}
                  </Form.Control>
                  <Form.Text className="tx-13 tx-danger">{yearError}</Form.Text>
                </Form.Group>
              </Row>
              {parentNameType === 'text' && (
                <Form.Group>
                  <div className="input-group mg-b-20">
                    {isStaffLogin ? (
                      <Form.Control
                        tabIndex="3"
                        type={parentNameType}
                        placeholder="Email ID"
                        autoComplete="off"
                        value={getInputValue('parentName')}
                        ref={loginParentNameFocus}
                        onChange={onChangeParentNameInput}
                        onFocus={() => {
                          setInputName('parentName');
                          setMaxLength();
                        }}
                      />
                    ) : (
                      <Form.Control
                        tabIndex="6"
                        type={parentNameType}
                        placeholder="Parent's First Name"
                        autoComplete="off"
                        value={getInputValue('parentName')}
                        ref={loginParentNameFocus}
                        onChange={onChangeParentNameInput}
                        onFocus={() => {
                          setInputName('parentName');
                          setPattern(getNameRegx());
                          setMaxLength(50);
                        }}
                      />
                    )}
                    <div className="input-group-append">
                      <span className="input-group-text" onClick={handleFocusparentName}>
                        <img className="pos-relative t--3" src={keyboardIcon} alt="Keyboard Icon" />
                      </span>
                    </div>
                  </div>
                  <Form.Text className="tx-13 tx-danger">{parentNameError}</Form.Text>
                </Form.Group>
              )}
              {showKeyboard && (
                <div ref={keyboardRef} className="pos-md-absolute z-index-200 wd-100p">
                  <Keyboard
                    keyboardRef={(r) => (keyboard.current = r)}
                    onChangeAll={onChangeAll}
                    onKeyPress={onKeyPress}
                    layoutName={layout}
                    inputName={inputName}
                    inputPattern={pattern}
                    maxLength={maxLength}
                    onInit={(kb) => kb.setInput(inputs[activeField])}
                  />
                  <Button className="btn-xs btn-dark float-right" onClick={() => setShowKeyboard(false)}>
                    Close
                  </Button>
                </div>
              )}
            </div>
            {showStudent === 'text' && (
              <div className="d-xxs-block mg-b-10">
                <hr className="mg-t-20" />
                <p className="text-center tx-14 mg-b-5">Which one is you? Click on the name to login.</p>
                <p className="text-center mg-0">
                  {showAdditionalInfo.map((resp) => (
                    <Button
                      onClick={() => handleStudentId(resp.id)}
                      className="btn-outline-purple d-block pd-10 bd bd-1 rounded bg-gray-100 tx-black mg-t-10 wd-100p tx-left tx-normal tx-normal"
                    >
                      <div className="d-flex align-items-center student-box">
                        <div className="icon wd-50 ht-50 bd bd-2 mg-r-15 d-inline-flex align-items-center justify-content-center rounded-circle">
                          <FeatherIcon className="d-inline-block" icon="user" />
                        </div>
                        <div className="info">
                          <span className="d-block tx-bold">{resp.name}</span>
                          <span className="d-block tx-medium tx-gray-600">Parent: {resp.parent}</span>
                          <span className="d-block tx-medium tx-gray-600">School: {resp.school}</span>
                        </div>
                      </div>
                    </Button>
                  ))}
                </p>
              </div>
            )}
            <div className="invalid-feedback mg-b-5 tx-13 text-center d-block">{apiError}</div>
            <button
              tabIndex="3"
              type="submit"
              onClick={studentSubmit}
              disabled={loginLoading}
              className="btn btn-purple btn-block mg-t-15"
            >
              Login
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AnotherWayLogin;
