import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import BrandLogo from './BrandLogo';
import ETherapyLogo from '../../assets/images/ETherapy-logo.png';
import HeaderRights from './HeaderRight';
import Navigation from './Navigation';
import { authStorage } from '../../services/localStorage/authStorage';
import Notification from './Notification';

const Header = ({ navigation = [] }) => {
  const isAuthenticated = authStorage.isAuthenticated();
  const [fetchingUserData, setFetchingUserData] = useState(false);

  return (
    <header id="tl-header" className="navbar navbar-header position-sticky t-0">
      <Notification />
      <div
        className={`order-md-1 ${
          !isAuthenticated
            ? 'wd-100p pd-x-15 pd-md-x-20 header-inner d-flex justify-content-between align-items-center pos-relative pos-lg-none'
            : 'navbar-brand'
        }`}
      >
        {fetchingUserData ? <Skeleton className="wd-60-f ht-30" /> : <BrandLogo />}
        {!isAuthenticated ? <img src={ETherapyLogo} alt="E Therapy Logo" className="wd-150 ht-25" /> : null}
      </div>
      {isAuthenticated ? (
        <div className="d-flex align-items-center order-lg-2 justify-content-md-center flex-md-1">
          <Navigation navigation={navigation} />
        </div>
      ) : null}
      {isAuthenticated ? (
        <HeaderRights fetchingUserData={fetchingUserData} setFetchingUserData={setFetchingUserData} />
      ) : null}
    </header>
  );
};

export default Header;
