import FeatherIcon from 'feather-icons-react';

function TLFullScreenLoader() {
  return (
    <div className="loader_main">
      <div className="loader">
        <FeatherIcon className="tx-50" icon="loader" />
      </div>
    </div>
  );
}

export default TLFullScreenLoader;
