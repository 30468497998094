import axios from 'axios';
import { toast } from 'react-toastify';
import FeatherIcon from 'feather-icons-react';
import { authStorage, tokenKey } from '../localStorage/authStorage';
import { API_BASE_URL } from '../../utils/constants/AppConstant';
import { getCookieByName } from '../../utils/utils';

axios.defaults.baseURL = API_BASE_URL;

axios.interceptors.response.use(null, (error) => {
  if (error?.response?.status === 401) {
    localStorage.clear();
    authStorage.removeToken();
    // Navigate to login page with a unique timestamp to prevent caching
    const currentPath = window.location.pathname;
    if (currentPath.startsWith('/student')) {
      window.location.href = `${process.env.PUBLIC_URL}/student/login?timestamp=${new Date().getTime()}`;
    } else if (currentPath.startsWith('/therapist')) {
      window.location.href = `${process.env.PUBLIC_URL}/login?timestamp=${new Date().getTime()}`;
    } else {
      window.location.href = `${process.env.PUBLIC_URL}/login?timestamp=${new Date().getTime()}`;
    }
  }

  if (error?.code === 'ERR_NETWORK') {
    toast.dismiss();
    toast.error('No Internet Connection.', {
      icon: <FeatherIcon className="tx-50" icon="wifi-off" />,
    });
  }

  return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
  config.headers.common.RefererPage = window.location.href;

  return config;
});

const storedToken = getCookieByName(tokenKey);

if (storedToken != null) {
  setToken(storedToken);
}

function setToken(token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  authStorage.setToken(token);
}

const http = {
  get: axios.get,
  post: axios.post,
  patch: axios.patch,
  put: axios.put,
  delete: axios.delete,
  setToken,
};

export default http;
