import { studentActivity } from '../../services/api/student/authService';

export const GetActivity = async (location) => {
  const pathName = location?.split('/') || [];
  if (pathName.length === 0) {
    return;
  }
  const data = { page: pathName.length > 3 ? `${pathName[2]}/${pathName[3]}` : pathName[2] };
  studentActivity(data)
    .then((res) => {})
    .catch((err) => {});
};
