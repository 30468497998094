import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FeatherIcon from 'feather-icons-react';

const TableFilter = ({ title, buttonName, ...rest }) => {
  const submitSearch = (e) => {
    e.preventDefault();
    rest.setSearch(rest.search);
    rest.handleSearch();
  };

  const clearSearch = () => {
    rest.handleClear();
  };

  return (
    <div className="data-filter mg-b-15">
      {title && <div className="mg-b-15 text-right order-1">{title && <h5>{title}</h5>}</div>}
      <div className="data-filter-main d-flex flex-wrap d-md-block text-md-right clearfix justify-content-between">
        {rest.paginationdata.total >= 11 && (
          <div className="order-2 d-inline-flex align-items-center float-left">
            Show
            <Form.Control
              as="select"
              className="form-control custom-select mg-x-5"
              style={{ maxWidth: 100 }}
              onChange={(e) => rest.setPageId(e.target.value)}
              value={rest.perpage}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </Form.Control>
            entries
          </div>
        )}
        {rest.searchBar && (
          <div className="order-1 d-inline-flex wd-100p wd-md-auto mg-b-15 mg-md-b-0">
            <Form
              onSubmit={submitSearch}
              className="search-form d-block d-md-flex wd-100p wd-md-250 justify-content-md-end"
            >
              <Form.Group className="tl-search-box-group">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    value={rest.search}
                    onChange={(e) => rest.setSearch(e.target.value)}
                    className="tl-search-box"
                  />
                  <InputGroup.Text className="search-icon pd-0 rounded-0 bg-white bd-l-0">
                    <FeatherIcon
                      onClick={clearSearch}
                      icon="x"
                      className={`tl-cursor-pointer wd-15 mg-r-5 pos-relative t--1 ${
                        rest?.search?.length > 0 ? 'opacity-1 ' : 'opacity-0 pointer-none'
                      }`}
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Button type="submit" className="btn" variant="success">
                <FeatherIcon className="text-white" icon="search" />
              </Button>
            </Form>
          </div>
        )}
        {rest.paginationdata.total > 0 && rest.exportButton && (
          <div className="order-3 mg-md-l-15 d-inline-flex">
            <Button className="btn btn-success text-nowrap" type="button" onClick={() => rest.downloadExportToExcel()}>
              {buttonName}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableFilter;
