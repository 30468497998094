import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import path from '../routes/path';

import ScrollToTop from './ScrollToTop';

export const therapistNavLinks = [
  {
    label: 'Home',
    link: path.therapist.index,
  },
  {
    label: 'Schedule',
    link: path.therapist.schedule,
  },
  {
    label: 'Students',
    link: path.therapist['my-students'],
    subMenus: [
      {
        label: 'My Students',
        link: path.therapist['my-students'],
      },
      {
        label: 'Assigned Frequency',
        link: path.therapist['assigned-frequency'],
      },
    ],
  },
  {
    label: 'Schools',
    link: path.therapist.schools,
  },
  {
    label: 'Sessions',
    link: path.therapist.sessions,
    subMenus: [
      {
        label: 'My Sessions',
        link: path.therapist['my-sessions'],
      },
      {
        label: 'Add Non-Schedule Session',
        link: path.therapist['add-non-schedule-session'],
      },
      {
        label: 'Session Documents',
        link: path.therapist['session-documents'],
      },
    ],
  },
  {
    label: 'Billing',
    link: path.therapist.billing,
  },
  {
    label: 'Training',
    link: path.therapist.training,
    subMenus: [
      {
        label: 'CA Special Training',
        link: path.therapist['ca-special-training'],
      },
      {
        label: 'Onboarding Tasks',
        link: path.therapist['onboarding-tasks'],
      },
      {
        label: 'Onboarding Videos',
        link: path.therapist['onboarding-videos'],
      },
      {
        label: 'Professional Development',
        link: path.therapist['professional-development'],
      },
      {
        label: 'STAR Help',
        link: path.therapist['star-help'],
      },
      {
        label: 'Zoom Help',
        link: path.therapist['zoom-help'],
      },
    ],
  },
];

const Layout = ({ children }) => {
  const layoutContainerClassName = useSelector((state) => state.theme.layoutContainerClassName);

  return (
    <ScrollToTop>
      <div className="d-flex flex-column justify-content-between">
        <Header navigation={therapistNavLinks} />
        <Container className={classNames('tl-flex-grow-1 pd-y-25 page-main', layoutContainerClassName)}>
          {children}
        </Container>
        <Footer />
      </div>
    </ScrollToTop>
  );
};

export default Layout;
