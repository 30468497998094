import React from 'react';
import FeatherIcon from 'feather-icons-react';
import ListGroup from 'react-bootstrap/ListGroup';
import { useSelector } from 'react-redux';

const StudentFullProfileData = ({ data }) => {
  const user = useSelector((state) => state.user[0]);
  const isStaffUser = user?.isStaffUser;

  return (
    <ListGroup>
      <ListGroup.Item className="bd-0 rounded-0 d-flex align-items-center pd-0 mg-t-10">
        <span className="d-flex align-items-center">
          <span className="wd-40 ht-40 bg-gray-100 d-flex pd-0 justify-content-center align-items-center mg-r-10 rounded">
            <FeatherIcon className="d-inline-block" icon="user" />
          </span>
          <strong className="mg-r-5">Name:</strong>
        </span>
        <span>{data?.name}</span>
      </ListGroup.Item>
      {data?.parents?.map((d, i) => (
        <div key={`student-${i}`}>
          {!isStaffUser ? (
            <ListGroup.Item className="bd-0 rounded-0 d-flex align-items-center pd-0 mg-t-10">
              <span className="d-flex align-items-center">
                <span className="wd-40 ht-40 bg-gray-100 d-flex pd-0 justify-content-center align-items-center mg-r-10 rounded">
                  <FeatherIcon className="d-inline-block" icon="user" />
                </span>
                <strong className="mg-r-5">Parent Name:</strong>
              </span>
              <span>{d.name}</span>
            </ListGroup.Item>
          ) : null}
          <ListGroup.Item className="bd-0 rounded-0 d-flex align-items-center pd-0 mg-t-10">
            <span className="d-flex align-items-center">
              <span className="wd-40 ht-40 bg-gray-100 d-flex pd-0 justify-content-center align-items-center mg-r-10 rounded">
                <FeatherIcon className="d-inline-block" icon="mail" />
              </span>
              <strong className="mg-r-5">{isStaffUser ? 'Contact' : 'Parent'} Email:</strong>
            </span>
            <span>{d.email}</span>
          </ListGroup.Item>
          <ListGroup.Item className="bd-0 rounded-0 d-flex align-items-center pd-0 mg-t-10">
            <span className="d-flex align-items-center">
              <span className="wd-40 ht-40 bg-gray-100 d-flex pd-0 justify-content-center align-items-center mg-r-10 rounded">
                <FeatherIcon className="d-inline-block" icon="phone" />
              </span>
              <strong className="mg-r-5">{isStaffUser ? 'Contact' : 'Parent'} Phone:</strong>
            </span>
            <span>{d.phone}</span>
          </ListGroup.Item>
        </div>
      ))}

      <ListGroup.Item className="bd-0 rounded-0 d-flex align-items-center pd-0 mg-t-10">
        <span className="d-flex align-items-center">
          <span className="wd-40 ht-40 bg-gray-100 d-flex pd-0 justify-content-center align-items-center mg-r-10 rounded">
            <FeatherIcon className="d-inline-block" icon="user" />
          </span>
          <strong className="mg-r-5">Gender:</strong>
        </span>
        <span>{data?.gender}</span>
      </ListGroup.Item>
      <ListGroup.Item className="bd-0 rounded-0 d-flex align-items-center pd-0 mg-t-10">
        <span className="d-flex align-items-center">
          <span className="wd-40 ht-40 bg-gray-100 d-flex pd-0 justify-content-center align-items-center mg-r-10 rounded">
            <FeatherIcon className="d-inline-block" icon="gift" />
          </span>
          <strong className="mg-r-5">DOB:</strong>
        </span>
        <span>{data?.date_of_birth}</span>
      </ListGroup.Item>

      {!isStaffUser ? (
        <>
          <ListGroup.Item className="bd-0 rounded-0 d-flex align-items-center pd-0 mg-t-10">
            <span className="d-flex align-items-center">
              <span className="wd-40 ht-40 bg-gray-100 d-flex pd-0 justify-content-center align-items-center mg-r-10 rounded">
                <FeatherIcon className="d-inline-block" icon="book" />
              </span>
              <strong className="mg-r-5">Class:</strong>
            </span>
            <span>{data?.grade}</span>
          </ListGroup.Item>
          <ListGroup.Item className="bd-0 rounded-0 d-flex pd-0 mg-t-10 align-items-start">
            <span className="d-flex align-items-center">
              <span className="wd-40 ht-40 bg-gray-100 d-flex pd-0 justify-content-center align-items-center mg-r-10 rounded">
                <FeatherIcon className="d-inline-block" icon="map-pin" />
              </span>
              <strong className="mg-r-5">Address:</strong>
            </span>
            {/* eslint-disable-next-line react/no-danger */}
            <div className="d-inline-flex pd-t-10" dangerouslySetInnerHTML={{ __html: data?.address }} />
          </ListGroup.Item>
        </>
      ) : null}
    </ListGroup>
  );
};

export default StudentFullProfileData;
