import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../../components/student/common/Footer';
import Header from '../../components/student/common/Header';
import StudentRoutes from '../../routes/student';
import { authStorage } from '../../services/localStorage/authStorage';
import { setThemeMode } from '../../store/themeSlice';

class Student extends Component {
  componentDidMount() {
    this.props.setThemeMode('student');
  }

  render() {
    return (
      <div className="tl-font-system">
        <Header login={this.props.loggedIn} />
        <div
          className={`content-fixed  pd-y-25 pd-x-0 d-flex ${
            authStorage.isAuthenticated()
              ? 'mg-lg-t-100 content-auth-100 content'
              : ' content-auth bg-box pd-t-25 pd-b-25'
          }`}
        >
          <StudentRoutes />
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state?.user[0]?.loggedIn,
});

const mapDispatchToProps = { setThemeMode };

export default connect(mapStateToProps, mapDispatchToProps)(Student);
