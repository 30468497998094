import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const TableSkeleton = ({ col = 6, row = 10 }) => (
  <>
    <Row className="mg-b-15 d-xxs-block">
      <Col md={8}>
        <Skeleton height={40} count={0.25} />
      </Col>
      <Col md={4}>
        <Skeleton height={40} count={1} />
      </Col>
    </Row>
    <Table responsive className="table table-bordered table-striped">
      <thead style={{ textAlign: 'center' }}>
        <tr>
          {[...Array(col)].map((_, i) => (
            <th key={`header-${i}`}>
              <Skeleton count={0.5} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...Array(row)].map((_, i) => (
          <tr key={`row-${i}`}>
            {[...Array(col)].map((__, idx) => (
              <td key={`col-${idx}`}>
                <Skeleton count={1} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  </>
);

export default TableSkeleton;
