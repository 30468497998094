import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import FeatherIcon from 'feather-icons-react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PaginationBox from './Pagination';
import TableFilter from './TableFilter';
import TableSkeleton from '../../common/skeleton/TableSkeleton';
import { getSchoolMeetingUrl } from '../../../services/api/student/authService';
import TLToast from '../../common/TLToast';
import { setMeetingUrl } from '../../../store/userSlice';
import { sendAmplitudeData } from '../../../utils/amplitude';

const Datatable = ({
  apiMethod,
  searchBar = true,
  exportButton = true,
  noDataMessage = 'No data found',
  skeleton = { row: 6, col: 6 },
  actions = [],
  initialSort = '',
  getApiData,
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('');
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(null);
  const [perpage, setPerPage] = useState(10);
  const [pageId, setPageId] = useState(1);
  const [sort, setSort] = useState(initialSort);
  const [loading, setLoading] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [columns, setColumns] = useState([]);
  const [paginate, setPaginate] = useState([]);
  const [searchFlag, setSearchFlag] = useState(false);
  const dispatch = useDispatch();
  const [meetingData, setMeetingData] = useState({
    provider: '',
    meetingUrl: '',
    newTab: false,
    redirect: false,
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perpage, pageId]);

  const getData = async (sorting) => {
    setLoading(true);
    const res = await apiMethod(pageId, search, perpage, sorting || sort);
    if (res.status === 200) {
      const tableData = res.data.data;
      setData(tableData.results);
      setColumns(tableData.columns);
      const sortKey = Object.keys(tableData.order[0])[0];
      setSort(`${sortKey}-${tableData.order[0][sortKey]}`);
      setLoading(false);
      setPaginate(res.data.meta);
      getApiData?.(res.data);
    }
  };

  const getPageIdData = async (value) => {
    setPageId(value);
  };

  const getPerPageData = async (value) => {
    setPageId(1);
    setPerPage(value);
  };

  const setSearchData = async (value) => {
    setPageId(1);
    setSearch(value);
  };

  const sortCol = async (col) => {
    if (col.order) {
      const sortData = sort.split('-');

      if (sortData[0] === col.name) {
        if (sortData[1] === 'asc') sortData[1] = 'desc';
        else if (sortData[1] === 'desc') {
          sortData[1] = 'asc';
        }
      } else {
        sortData[0] = col.name;
        sortData[1] = 'asc';
      }
      setSort(`${sortData[0]}-${sortData[1]}`);
      getData(`${sortData[0]}-${sortData[1]}`);
    }
  };

  if (loading) {
    return <TableSkeleton row={skeleton.row} col={skeleton.col} />;
  }

  const downloadExportToExcel = async () => {
    setDownloadLoader(true);
    const response = await apiMethod(pageId, search, perpage, sort, 'xlsx');
    const filename = response.headers['content-disposition'].split('"')[1].trim();
    const type = response.headers['content-type'];
    const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    sendAmplitudeData('Export to Excel', { 'File Name': filename });
    setDownloadLoader(false);
  };

  const getSortClass = (d) => {
    const sortData = sort.split('-');
    if (d.order) {
      if (d.name === sortData[0]) {
        if (sortData[1] === 'asc') return 'sorting_asc';
        return 'sorting_desc';
      }
      return 'sorting';
    }
    return '';
  };

  const meetOnWhereBy = (url, newTab) => {
    dispatch(setMeetingUrl(url));
    setMeetingData({
      provider: 'whereby',
      meetingUrl: '/student/school/whereby',
      newTab,
      redirect: true,
    });
  };

  const meetOnZoom = (url, newTab) => {
    window.open(url, newTab ? '_blank' : '_self');
  };

  const getMeeting = async (url) => {
    try {
      setDownloadLoader(true);
      setToastMessage('');
      setToastVariant('');
      setShowToast(false);
      const query = new URLSearchParams(url.split('?')[1]);
      const eventId = query.get('event_id');
      const res = await getSchoolMeetingUrl(eventId);
      const { provider, meeting_url, new_tab } = res.data.data;

      if (provider === 'whereby') {
        meetOnWhereBy(meeting_url, false);
      } else if (provider === 'zoom') {
        meetOnZoom(meeting_url, new_tab);
      }
    } catch (error) {
      setToastMessage(error?.response?.data?.message || 'Something went wrong');
      setToastVariant('danger');
      setShowToast(true);
    } finally {
      setDownloadLoader(false);
    }
  };

  const CloseToast = () => {
    setShowToast(false);
  };

  const handleSearch = () => {
    if (search || searchFlag) {
      setSearchFlag(!!search);
      getData();
      sendAmplitudeData('Search', { value: search });
    }
  };

  const handleClear = async () => {
    setSearch('');
    setSearchFlag(false);
    try {
      setLoading(true);
      const res = await apiMethod(pageId, '', perpage, sort);
      const tableData = res.data.data;
      setData(tableData.results);
      setColumns(tableData.columns);
      const sortKey = Object.keys(tableData.order[0])[0];
      setSort(`${sortKey}-${tableData.order[0][sortKey]}`);
      setLoading(false);
      setPaginate(res.data.meta);
    } catch (error) {
      setLoading(false);
      setToastMessage(error?.response?.data?.message || 'Something went wrong');
      setToastVariant('danger');
      setShowToast(true);
    }
  };

  if (meetingData.redirect && meetingData.meetingUrl) {
    return <Redirect to={meetingData.meetingUrl} target={meetingData.newTab ? '_blank' : '_self'} />;
  }

  return (
    <>
      {showToast && <TLToast show={showToast} message={toastMessage} handleClose={CloseToast} variant={toastVariant} />}
      {downloadLoader && (
        <div className="loader_main">
          <div className="loader">
            <FeatherIcon className="tx-50" icon="loader" />
          </div>
        </div>
      )}
      {(data.length > 0 || searchFlag) && (
        <TableFilter
          searchBar={searchBar}
          exportButton={exportButton}
          buttonName="Export To Excel"
          search={search}
          setPageId={getPerPageData}
          perpage={perpage}
          paginationdata={paginate}
          setSearch={setSearchData}
          downloadExportToExcel={downloadExportToExcel}
          handleSearch={handleSearch}
          handleClear={handleClear}
        />
      )}
      <Table responsive className="table table-striped dataTable">
        <thead>
          <tr>
            {columns.map((d, i) => (
              <th
                className={`bg-gray-200 ${d.order ? 'tl-cursor-pointer' : ''} ${getSortClass(d)}`}
                key={i}
                onClick={() => sortCol(d)}
              >
                {d.label}
              </th>
            ))}
          </tr>
        </thead>
        {data.length ? (
          <tbody>
            {data.map((d, i) => (
              <tr key={`row-${i}`}>
                {columns.map((val, j) => {
                  if (val.name === 'get_meeting_url') {
                    return (
                      <td key={`data-${j}`} className="valign-middle-f">
                        <Button className="btn btn-success" onClick={() => getMeeting(d[val.name])}>
                          Join Meeting
                        </Button>
                      </td>
                    );
                  }
                  if (val.name === 'action') {
                    return (
                      <td key={`data-${j}`} className="valign-middle-f">
                        {actions.map((action) => (
                          <Button
                            {...action}
                            onClick={() => action.onClick(d, i)}
                            disabled={
                              action.disabled ||
                              (action.selectedRow.index === i && action.selectedRow.key === action.key)
                            }
                          >
                            {action.label}
                          </Button>
                        ))}
                      </td>
                    );
                  }
                  return (
                    <td key={`data-${j}`} className="valign-middle-f">
                      {d[val.name]}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={columns.length}>
                <p className="text-center mg-0">{search ? 'No data found' : noDataMessage}</p>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
      {data.length > 0 && <PaginationBox paginationdata={paginate} getPageIdData={getPageIdData} />}
    </>
  );
};

export default Datatable;
