import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

function useUrlParams() {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  return queryParams;
}

export default useUrlParams;
