import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import StudentFullProfileData from './Information';
import StudentLoginActivity from './LoginActivity';

const StudentProfileTab = ({ data }) => (
  <Tabs defaultActiveKey="info" id="uncontrolled-tab-example">
    <Tab eventKey="info" title="Info" key={1}>
      <StudentFullProfileData data={data} />
    </Tab>
    <Tab eventKey="loginactivity" title="Login Activity" key={2}>
      <StudentLoginActivity />
    </Tab>
  </Tabs>
);

export default StudentProfileTab;
