import React, { useState } from 'react';
import { Card, Container, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import UploadBox from '../../components/student/common/UploadBox';
import Datatable from '../../components/student/common/Datatable';
import { fileAction, getInsuranceDocuments, uploadInsuranceDocument } from '../../services/api/student/authService';
import TLToast from '../../components/common/TLToast';
import TableSkeleton from '../../components/common/skeleton/TableSkeleton';
import { APP_NAME } from '../../utils/constants/AppConstant';
import { isDevelopment } from '../../utils/utils';

const allowedExtensions = '.pdf,.doc,.docx,.jpg,.jpeg,.png';
const allowedExtensionsRegex = /(\.pdf|\.doc|\.docx|\.jpg|\.jpeg|\.png)$/i;

const Documents = () => {
  const [document, setDocument] = useState();
  const [uploading, setUploading] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    show: false,
    message: '',
    variant: 'success',
  });
  const [selectedRow, setSelectedRow] = useState({
    index: '',
    key: '',
  });
  const [showUpload, setShowUpload] = useState(false);

  const onDocumentUpload = (e) => {
    const image = e.target.files[0];
    const { name, size } = image;

    if (!allowedExtensionsRegex.exec(name)) {
      const message = `Invalid file type. Only ${allowedExtensions} files are allowed.`;
      setToastMessage({
        show: true,
        message,
        variant: 'danger',
      });
      e.target.value = '';
      return false;
    }

    // create kb or mb size
    const sizeInKb = size / 1024;
    const sizeInMb = sizeInKb / 1024;

    setDocument((prev) => ({
      ...prev,
      file: image,
      name,
      size: sizeInKb > 1024 ? `${sizeInMb.toFixed(2)} MB` : `${sizeInKb.toFixed(2)} KB`,
    }));

    e.target.value = '';
  };

  const onDocumentRemove = () => {
    setDocument((prev) => ({
      ...prev,
      file: null,
      name: '',
      size: '',
    }));
  };

  const onUploadInsuranceDocument = async () => {
    try {
      setUploading(true);
      const formData = new FormData();

      if (document.file) {
        formData.append('file', document.file, document.name);
      }

      const res = await uploadInsuranceDocument(formData);

      if (res?.status === 200) {
        const message = res?.data?.message || 'Insurance document uploaded successfully';
        setToastMessage({
          show: true,
          message,
          variant: 'success',
        });
        setDocument((prev) => ({
          ...prev,
          file: null,
          name: '',
          size: '',
        }));
      }
    } catch (error) {
      const message = error?.response?.data?.message || 'Something went wrong';
      if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.log('err ', error);
      }
      setToastMessage({
        show: true,
        message,
        variant: 'danger',
      });
    } finally {
      setUploading(false);
    }
  };

  const onAction = async ({ action = 'view', row, index }) => {
    try {
      setSelectedRow({
        index,
        key: action,
      });
      const res = await fileAction({
        path: row.filehash,
        action,
      });
      if (res?.status === 200) {
        const filePath = res?.data?.data;
        window.open(filePath, '_blank');
      }
    } catch (error) {
      const message = error?.response?.data?.message || 'Something went wrong';
      if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.log('err ', error);
      }
      setToastMessage({
        show: true,
        message,
        variant: 'danger',
      });
    } finally {
      setSelectedRow({
        index: '',
        key: '',
      });
    }
  };

  const getApiData = (data) => {
    const isFlvsStudent = data?.data?.flvs_student;
    setShowUpload(!isFlvsStudent);
  };

  return (
    <>
      <Helmet title={`Documents - Student - ${APP_NAME}`}>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {toastMessage.show && (
        <TLToast
          show={toastMessage.show}
          message={toastMessage.message}
          handleClose={() => {
            setToastMessage((prev) => ({
              ...prev,
              show: false,
            }));
          }}
          variant={toastMessage.variant}
        />
      )}
      <Container>
        {showUpload && (
          <Card className="wd-100p">
            <Card.Body className="wd-100p d-flex flex-column align-items-center">
              <div className="wd-50p">
                <h4 className="mg-b-15">Please Upload Your Insurance Document Here</h4>
                <UploadBox
                  accept={allowedExtensions}
                  data={document}
                  onChange={onDocumentUpload}
                  onDelete={onDocumentRemove}
                />
                <div className="tx-center">
                  <Button
                    onClick={onUploadInsuranceDocument}
                    className="btn btn-purple mg-t-15 pd-x-60 pd-y-10 tx-uppercase"
                    disabled={uploading || !document?.file}
                  >
                    {uploading ? 'Uploading...' : 'Upload'}
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
        <div
          className={classNames({
            'mg-t-40': showUpload,
          })}
        >
          {uploading ? (
            <TableSkeleton row={6} col={6} />
          ) : (
            <Datatable
              getApiData={getApiData}
              actions={[
                {
                  label: 'Download',
                  key: 'download',
                  className: 'btn btn-purple mg-r-10',
                  onClick: (row, index) =>
                    onAction({
                      action: 'download',
                      row,
                      index,
                    }),
                  selectedRow,
                },
                {
                  label: 'View',
                  key: 'view',
                  className: 'btn btn-success',
                  onClick: (row, index) =>
                    onAction({
                      action: 'view',
                      row,
                      index,
                    }),
                  selectedRow,
                },
              ]}
              apiMethod={getInsuranceDocuments}
              noDataMessage="No Documents Uploaded Yet"
              exportButton={false}
              initialSort="dt-desc"
            />
          )}
        </div>
      </Container>
    </>
  );
};

export default Documents;
