import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ScheduleBox from './Box';

const ScheduleList = ({ data, future_schedule, message }) => {
  if (data === null || data === false || data.length === 0) {
    return (
      <Row>
        <Col sm={12}>
          <h5 className="mb-0 pd-t-10 pd-b-10 text-center tx-16 tx-sm-15 tx-lg-16">{message}</h5>
        </Col>
      </Row>
    );
  }

  if (future_schedule) {
    return (
      <Row>
        <Col sm={12}>
          <h5 className="mb-0 pd-t-10 pd-b-10 text-center tx-16 tx-sm-15 tx-lg-16">{`You will see a button to meet therapist on ${data.start_date} for the session scheduled at ${data.start_time} to ${data.end_time}`}</h5>
        </Col>
      </Row>
    );
  }
  return (
    <Row className="row-xs">
      {data.map((item, index) => (
        <ScheduleBox
          key={index}
          title={item.zoom_room.room_name}
          name={`${item.therapist.name} - ${item.therapist.position}`}
          time={`${item.start_date} & ${item.start_time} to ${item.end_time}`}
          location={item.student_timezone}
          scheduleurl={item.zoom_room.direct_meeting_url}
          eventID={item.event_id}
          zoomRoomID={item.zoom_room.id}
        />
      ))}
    </Row>
  );
};

export default ScheduleList;
