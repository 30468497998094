import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Datatable from '../../components/student/common/Datatable';
import { sessions } from '../../services/api/student/authService';
import { sendAmplitudeData } from '../../utils/amplitude';
import { APP_NAME } from '../../utils/constants/AppConstant';
import { GetActivity } from '../../utils/student';

const Sessions = () => {
  useEffect(() => {
    GetActivity(window.location.pathname);
    sendAmplitudeData('My Sessions');
  }, []);

  const tableHeader = ['date', 'end_time', 'service_name', 'session_id', 'start_time', 'therapist'];

  return (
    <>
      <Helmet title={`My Sessions - Student - ${APP_NAME}`}>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container>
        <h4 className="d-block d-md-none mg-b-15">My Sessions</h4>

        <Datatable apiMethod={sessions} tableHeader={tableHeader} noDataMessage="You did not have any Sessions yet" />
      </Container>
    </>
  );
};

export default Sessions;
