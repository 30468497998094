import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authStorage } from '../services/localStorage/authStorage';
import path from './path';

// use v2=true if you face re-rendering issue
export default function RouteWrapper({ component: Component, isPrivate, path: Path, v2, children, ...rest }) {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const loggedIn = authStorage.isAuthenticated();
  const logoutFrom = useSelector((state) => state?.general?.logoutFrom);

  if (isPrivate && !loggedIn) {
    return (
      <Redirect
        to={{
          pathname: logoutFrom === 'student' ? path.student.login : path.login,
          state: location,
        }}
      />
    );
  }

  const studentPaths = Object.values(path.student).filter((x) => !['/student/login', '/student/logout'].includes(x));

  const schoolPaths = Object.values(path.school).filter((x) => x !== '/student/school/login');

  if (
    (user[0] && user[0].studentLogin && Path.includes('student/login') && ![1, 6].includes(user[0].type)) ||
    (user[0].studentLogin && studentPaths.includes(Path) && ![1, 6].includes(user[0].type))
  ) {
    return <Redirect to={path.school.home} />;
  }

  if (
    (user[0] && user[0].studentLogin && Path.includes('school/login') && ![3, 5].includes(user[0].type)) ||
    (user[0].studentLogin && schoolPaths.includes(Path) && ![3, 5].includes(user[0].type))
  ) {
    return <Redirect to={path.student.home} />;
  }

  if (v2) {
    return <Route {...rest}>{children}</Route>;
  }

  return <Route {...rest} component={Component} />;
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.any.isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
