import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import FeatherIcon from 'feather-icons-react';
import { LoginActivity } from '../../../services/api/student/authService';
import TLTooltip from '../../common/TL-Tooltip';

const StudentLoginActivity = () => {
  const [data, setData] = useState([]);
  const [timezone, setTimezone] = useState('');

  async function getData() {
    try {
      const res = await LoginActivity();
      if (res) {
        setData(res.data.data.data);
        setTimezone(res.data.data.timezone);
      }
    } catch (_) {
      //
    }
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <Table responsive className="table table-bordered table-striped">
      <thead>
        <tr>
          <th className="bg-gray-200">Status</th>
          <th className="bg-gray-200">
            <div className="d-flex align-items-center">
              <span className="mg-r-4">Date &amp; Time</span>
              {timezone && (
                <TLTooltip tooltip={timezone}>
                  <span className="d-flex tl-cursor-pointer">
                    <FeatherIcon className="ht-15 wd-15-f" icon="info" />
                  </span>
                </TLTooltip>
              )}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((u, i) => (
          <tr key={i}>
            <td>{u.label}</td>
            <td>{u.date}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default StudentLoginActivity;
