import { useEffect, useState } from 'react';

export const useScript = (url) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      setHasLoaded(true);
    };
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
  return { hasLoaded };
};
