import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { schoolDetail } from '../../../services/api/student/authService';
import DainaPic from '../../../assets/images/daina-pic.jpg';

const ProfileBox = () => {
  const [data, setData] = useState([]);
  async function getData() {
    try {
      const res = await schoolDetail();

      if (res) {
        setData(res.data.data);
      }
    } catch (_) {
      //
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card className="wd-100p">
      <Card.Img variant="top" src={DainaPic} />
      <Card.Body>
        <Card.Text className="tx-20">Name: {data.name}</Card.Text>
        <Card.Text className="mg-b-5">
          <strong className="mg-r-5">Email:</strong>
          {data.email}
        </Card.Text>
        <Card.Text>
          <strong className="mg-r-5">Phone:</strong>
          {data.mobile}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProfileBox;
