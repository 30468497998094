export const fbEvents = () => {
  (function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    // eslint-disable-next-line no-multi-assign, no-param-reassign
    n = f.fbq = function () {
      // eslint-disable-next-line prefer-spread, prefer-rest-params
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    // eslint-disable-next-line no-param-reassign
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    // eslint-disable-next-line prefer-destructuring, no-param-reassign
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  window?.fbq('init', '1320994428075466');
  window?.fbq('track', 'PageView');
};

export const gtm = () => {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-M2MLH5Z');
};

export const isLastItem = (index, length) => index + 1 === length;

export const debounce = (callback, delay = 500) => {
  let timeout;
  return (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      callback(...args);
    }, delay);
  };
};

export const throttle = (callback, limit) => {
  let wait = false;
  return (...args) => {
    if (!wait) {
      callback(...args);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
};

export const isStudentSystem = () => window.location.pathname.startsWith('/student');
