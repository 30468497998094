import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: {
    hasError: false,
    message: null,
    variant: '',
    title: '',
  },
  logoutFrom: '',
  showFullScreenLoader: false,
  navigation: [],
  notification: {
    visible: false,
    type: '',
    title: '',
    children: '',
    delay: 2000,
    position: 'center',
  },
  assignedStudent: {
    loading: false,
    count: 0,
  },
  fetchTodaysSchedule: false,
  fetchAssignedStudents: false,
  forceGetLicenseExpirationDateMessage: false,
  activeMenu: '',
  shouldGetBookingData: false,
};

const reducers = {
  setError: (state, action) => {
    const data = action.payload;
    for (const [key, value] of Object.entries(data)) {
      // check if key exists in state
      if (key in state.error) {
        state.error[key] = value;
      }
    }
  },
  setLogoutFrom: (state, action) => {
    state.logoutFrom = action.payload;
  },
  setShowFullScreenLoader: (state, action) => {
    state.showFullScreenLoader = action.payload;
  },
  setNavigation: (state, action) => {
    state.navigation = action.payload;
  },
  setNotification: (state, action) => {
    const data = action.payload;
    for (const [key, value] of Object.entries(data)) {
      // check if key exists in state
      if (key in state.notification) {
        state.notification[key] = value;
      }
    }
  },
  setAssignedStudentsCount: (state, action) => {
    state.assignedStudent.count = action.payload;
  },
  setAssignedStudentsLoading: (state, action) => {
    state.assignedStudent.loading = action.payload;
  },
  setFetchTodaysSchedule: (state, action) => {
    state.fetchTodaysSchedule = action.payload;
  },
  setFetchAssignedStudents: (state, action) => {
    state.fetchAssignedStudents = action.payload;
  },
  setForceGetLicenseExpirationDateMessage: (state, action) => {
    state.forceGetLicenseExpirationDateMessage = action.payload;
  },
  setActiveMenu: (state, action) => {
    state.activeMenu = action.payload;
  },
  setShouldGetBookingData: (state, action) => {
    state.shouldGetBookingData = action.payload;
  },
};

const slice = createSlice({
  name: 'general',
  initialState,
  reducers,
});

export const {
  setError,
  setLogoutFrom,
  setShowFullScreenLoader,
  setNavigation,
  setNotification,
  setAssignedStudentsCount,
  setAssignedStudentsLoading,
  setFetchTodaysSchedule,
  setFetchAssignedStudents,
  setForceGetLicenseExpirationDateMessage,
  setActiveMenu,
  setShouldGetBookingData,
} = slice.actions;

export default slice.reducer;
