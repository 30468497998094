import Toast from 'react-bootstrap/Toast';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { useState } from 'react';
import { setNotification } from '../../store/generalSlice';
import IconButton from '../student/common/IconButton';

function Notification() {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.general.notification);
  const { visible = true, type, title = '', delay, children } = notification;
  const [autoHide, setAutoHide] = useState(true);

  const handleClose = () => {
    dispatch(setNotification({ visible: false, type: '', title: '', children: '', delay: 2000 }));
  };

  const handleMouseEnter = () => {
    setAutoHide(false);
  };

  const handleMouseLeave = () => {
    setAutoHide(true);
  };

  return createPortal(
    <div
      className={classNames('pos-fixed t-10 r-10  tl-notification-wrapper center')}
      style={{
        zIndex: 1055,
      }}
    >
      <Toast
        onClose={handleClose}
        show={visible}
        delay={delay}
        className="pd-20 mx-wd-100p"
        animation
        transition={Collapse}
        autohide={autoHide}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {type === 'success' ? (
          <div className="d-flex align-items-center">
            <div className="tx-success d-flex align-items-center flex-grow">
              <FeatherIcon icon="check-circle" className="wd-15 ht-15 mg-r-8 flex-shrink-0" />
              <p className="mg-0 tx-medium">{title}</p>
            </div>
            <div className="mg-l-40">{children}</div>
            <IconButton
              icon="x"
              className="mg-l-15"
              iconClassName="tx-gray-600"
              isPrimary={false}
              onClick={handleClose}
            />
          </div>
        ) : null}

        {type === 'error' ? (
          <div className="d-flex align-items-center">
            <div className="tx-danger d-flex align-items-center flex-grow">
              <FeatherIcon icon="x-circle" className="wd-15 ht-15 mg-r-8" />
              <p className="mg-0 tx-medium">{title}</p>
            </div>
            <div className="mg-l-40">{children}</div>
            <IconButton
              icon="x"
              className="mg-l-15"
              iconClassName="tx-gray-600"
              isPrimary={false}
              onClick={handleClose}
            />
          </div>
        ) : null}

        {type === 'warning' ? (
          <div className="d-flex align-items-center">
            <div className="tx-warning d-flex align-items-center flex-grow">
              <FeatherIcon icon="x-circle" className="wd-15 ht-15 mg-r-8" />
              <p className="mg-0 tx-medium">{title}</p>
            </div>
            <div className="mg-l-40">{children}</div>
            <IconButton
              icon="x"
              className="mg-l-15"
              iconClassName="tx-gray-600"
              isPrimary={false}
              onClick={handleClose}
            />
          </div>
        ) : null}
      </Toast>
    </div>,
    document.body
  );
}

export default Notification;
