import React from 'react';
import Nav from 'react-bootstrap/Nav';
import FeatherIcon from 'feather-icons-react';
import { sendAmplitudeData } from '../../../utils/amplitude';
import { systemRequirementClick } from '../../../services/api/student/authService';

const ButtonBox = () => {
  const onSystemRequirementClick = async () => {
    try {
      await systemRequirementClick();
    } catch (_) {
      //
    }
  };

  const links = [
    {
      name: 'Check System Requirement',
      link: 'https://support.zoom.us/hc/en-us/articles/201362023-System-requirements-for-Windows-macOS-and-Linux',
      onClick: onSystemRequirementClick,
    },
    {
      name: 'Update ZOOM',
      link: 'https://zoom.us/download',
    },
  ];
  return (
    <Nav variant="pills" className="justify-content-start mg-0 d-none d-lg-flex" activeKey="/home">
      {links.map((link, index) => (
        <Nav.Item
          className={`
          ${index === 0 ? 'mg-r-20' : ''}
        `}
          key={index}
        >
          <p className="mg-0 d-flex align-items-center">
            <FeatherIcon className="wd-15 ht-15 tx-12 mg-r-6 tl-color-purple" icon="external-link" />
            <a
              className="ext-link tl-color-purple tx-medium"
              onClick={() => {
                sendAmplitudeData(link.name);
                link.onClick && link.onClick();
              }}
              target="_blank"
              href={link.link}
              rel="noreferrer"
            >
              {link.name}
            </a>
          </p>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default ButtonBox;
