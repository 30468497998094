import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SidebarSkeleton = () => (
  <>
    <Skeleton count={5} />
    <Skeleton className="mg-t-15 ht-20" />
  </>
);

export default SidebarSkeleton;
