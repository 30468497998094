import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, withRouter } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { navigation } from '../../../services/api/student/authService';
import MenuLogo from '../../../assets/images/ETherapy-logo.svg';

const useOutsideClick = (ref, setExpanded) => {
  useEffect(() => {
    const onMouseDown = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setExpanded(false);
      }
    };
    document.addEventListener('mousedown', onMouseDown);

    return () => document.removeEventListener('mousedown', onMouseDown);
  }, [ref, setExpanded]);
};

const Navigation = () => {
  const menuRef = useRef(null);
  const [expanded, setExpanded] = useState(false);

  useOutsideClick(menuRef, setExpanded);

  useEffect(() => {
    getNavigation();
  }, []);

  const [primaryMenu, setprimaryMenu] = useState([]);
  const [secondaryMenu, setsecondaryMenu] = useState([]);

  async function getNavigation() {
    try {
      const res = await navigation();
      const resdata = res.data.data;
      let primarymenu = [];
      let secondarymenu = [];
      const searchIndex = resdata.findIndex((element) => element.title === '-');
      primarymenu = resdata.slice(0, searchIndex);
      secondarymenu = resdata.slice(searchIndex + 1);

      setprimaryMenu(primarymenu);
      setsecondaryMenu(secondarymenu);
    } catch (_) {
      //
    }
  }

  return (
    <>
      {expanded ? <div className="backdrop visible op-none d-lg-none" /> : null}
      <div className="nav_sec pd-lg-t-10 pd-lg-10 bg-gray-100 pd-xxs-0" ref={menuRef}>
        {/* This expanded is manually handled which needs to be restricted for
        medium and smaller screen. */}
        <Navbar expanded={expanded} expand="lg" className="pd-0">
          <Container>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="burger-menu wd-35 ht-35 mg-xxs-l-0 pd-8 tx-white rounded pos-relative z-in bg-purple btn btn-primary"
              onClick={() => {
                setExpanded(!expanded);
              }}
            />
            <Navbar.Collapse
              className="pd-0 d-xl-flex justify-content-between navbar-menu-wrapper"
              id="basic-navbar-nav"
            >
              <div className="navbar-menu-header">
                <NavLink exact activeClassName="active" to="./" className="nav-link">
                  <img src={MenuLogo} className="df-logo" alt="Logo" />
                </NavLink>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="menuClose rounded-0"
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                />
              </div>
              <ul className="me-auto nav navbar-menu wd-auto">
                <li className="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>
                {primaryMenu.map(
                  (u, i) =>
                    u.active && (
                      <li className="nav-item" key={i}>
                        {u.url ? (
                          <NavLink
                            exact
                            activeClassName="active"
                            to={`/${u.url}`}
                            className="nav-link"
                            onClick={() => {
                              setExpanded(!expanded);
                            }}
                          >
                            <FeatherIcon icon={u.icon} /> {u.title}
                          </NavLink>
                        ) : (
                          <span className="nav-link">
                            <FeatherIcon icon={u.icon} /> {u.title}
                          </span>
                        )}
                      </li>
                    )
                )}
              </ul>
              <ul className="nav navbar-menu justify-content-end wd-auto">
                {secondaryMenu.map(
                  (u, i) =>
                    u.active && (
                      <li className="nav-item" key={i}>
                        {u.url ? (
                          <NavLink
                            exact
                            activeClassName="active"
                            to={`/${u.url}`}
                            className="nav-link"
                            onClick={() => {
                              setExpanded(!expanded);
                            }}
                          >
                            <FeatherIcon icon={u.icon} /> {u.title}
                          </NavLink>
                        ) : (
                          <span className="nav-link">
                            <FeatherIcon icon={u.icon} /> {u.title}
                          </span>
                        )}
                      </li>
                    )
                )}
              </ul>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default withRouter(Navigation);
