import { toast } from 'react-toastify';

export function toastError(text, autoClose = 5000) {
  toast.error(text, {
    position: 'top-right',
    autoClose,
    theme: 'colored',
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
