import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import TLTooltip from './TL-Tooltip';

function Textarea({
  row,
  disabled,
  placeholder = '',
  label,
  id,
  required = true,
  labelClassName,
  tooltip,
  error,
  onChange,
  defaultValue,
  value,
}) {
  const textareaRef = useRef(null);

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    resizeTextarea();
  }, [value]); // adjust this dependency if needed

  return (
    <div>
      {label && id ? (
        <div className="d-flex align-items-baseline">
          <label
            htmlFor={id}
            className={classNames('d-flex align-items-center tx-interui tx-12 tx-uppercase tx-medium', labelClassName)}
          >
            <span
              className={classNames({
                'mg-r-4': tooltip,
                'input-required': required,
              })}
            >
              {label}
            </span>
            {tooltip && <TLTooltip tooltip={tooltip} />}
          </label>
        </div>
      ) : null}
      <textarea
        ref={textareaRef}
        onChange={(e) => {
          onChange && onChange(e);
          resizeTextarea();
        }}
        id={id}
        className={classNames('form-control', {
          'bd-danger': error,
        })}
        rows={row}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
      />
      {error && <div className="text-danger tx-12">{error}</div>}
    </div>
  );
}

export default React.memo(Textarea);
