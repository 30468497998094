import classNames from 'classnames';
import React from 'react';

function UserAvatar({ initials, height = 32, width = 32, className }) {
  return (
    <div
      className="avatar"
      style={{
        height,
        width,
      }}
    >
      <span className={classNames('avatar-initial rounded-circle tl-bg-color-6423D4', className)}>{initials}</span>
    </div>
  );
}

export default UserAvatar;
