import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import IconButton from './IconButton';

function TLUpload({
  accept = '*',
  onChange,
  data = {
    name: '',
    size: '',
  },
  onDelete,
  label = 'Add Document',
  disabled = false,
  required = false,
  error,
  labelClassName,
}) {
  return (
    <div className="file-upload">
      <label htmlFor="file-upload" className={`${labelClassName || 'text-uppercase tx-black'}`}>
        {label} {required === true ? <sup className="tx-danger">*</sup> : ''}
      </label>
      <div
        className={classNames('tx-center pos-relative  bd-2 rounded bd-color-C0CCDA uploadBox', {
          'bd-dashed tl-bg-F7FDFF pd-y-35': !data.name,
          'tl-bd-solid-i pd-y-20': data.name,
          'bd-danger-i': error,
        })}
      >
        {!data.name && (
          <input
            className="pos-absolute mg-0 pd-0 wd-100p-f ht-100p-f outline-none op-0 tl-cursor-pointer tl-top-0 tl-left-0"
            type="file"
            accept={accept}
            onChange={onChange}
            id="file-upload"
            disabled={disabled}
          />
        )}
        {data.name !== '' && (
          <div className="d-flex justify-content-between align-items-center pd-x-15">
            <div className="d-flex flex-row align-items-center">
              <FeatherIcon icon="file" className="wd-30 ht-30 d-inline-block mg-r-15" />
              <div className="d-flex flex-column align-items-start">
                <span className="tx-medium tx-14 tx-left">{data.name}</span>
                <span className="tx-11 tl-color-8392A5">{data.size}</span>
              </div>
            </div>
            <div className="bd-0 tl-bd-l-1 tl-bd-solid tl-bd-color-E7E7E7 tl-bd-solid pd-l-15 pd-y-10">
              <IconButton
                icon="trash-2"
                iconClassName="wd-20 ht-20 d-inline-block tl-color-DC3545"
                onClick={onDelete}
                disabled={disabled}
              />
            </div>
          </div>
        )}
        {!data.name && (
          <div className="drag-text">
            <span className="tl-color-808C9A">Click or Drag 'n' Drop files</span>
          </div>
        )}
      </div>
      {error && <div className="text-danger tx-12">{error}</div>}
    </div>
  );
}

export default TLUpload;
