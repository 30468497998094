export default {
  root: '/',
  login: '/login',
  forgetPassword: '/forget-password',
  ResetPassword: '/password/reset',
  student: {
    index: '/student',
    login: '/student/login',
    home: '/student/home',
    whereby: '/student/whereby',
    schedule: '/student/schedules',
    sessions: '/student/sessions',
    progress: '/student/progress',
    frequency: '/student/frequency',
    documents: '/student/documents',
    profile: '/student/profile',
    logout: '/student/logout',
    joinMeet: '/student/join-meet',
  },
  school: {
    login: '/student/school/login',
    home: '/student/school/home',
    whereby: '/student/school/whereby',
  },
  staff: {
    login: '/student/staff/login',
  },
  therapist: {
    index: '/therapist',
    login: '/therapist/login',
    home: '/therapist/home',
    newsletter: '/therapist/newsletter',
    schedules: '/therapist/schedules',
    'my-students': '/therapist/my-students',
    'assigned-frequency': '/therapist/assigned-frequency',
    'flvs-hosted-iep': '/therapist/schedule/flvs-hosted-iep',
    schools: '/therapist/schools',
    sessions: '/therapist/sessions',
    'pending-sessions': '/therapist/sessions/pending',
    'add-session': '/therapist/sessions/new',
    'my-sessions': '/therapist/my-sessions',
    'add-non-schedule-session': '/therapist/sessions/non-schedule/new',
    'session-documents': '/therapist/sessions/documents',
    billing: '/therapist/billing',
    training: '/therapist/training',
    'ca-special-training': '/therapist/training/special',
    'onboarding-tasks': '/therapist/onboarding/tasks',
    'onboarding-videos': '/therapist/onboarding/videos',
    'professional-development': '/therapist/professional-development',
    'star-help': '/therapist/help/star',
    'zoom-help': '/therapist/help/zoom',
    'speech-therapy-tools': '/therapist/help/tools/speech-therapy-tools',
    'occupational-therapy-tools': '/therapist/help/tools/occupational-therapy-tools',
    'counseling-tools': '/therapist/help/tools/counseling-tools',
    'technology-help': '/therapist/help/tools/technology-help',
    'physical-therapy': '/therapist/help/tools/physical-therapy',
    faq: '/therapist/help/faq',
    notification: '/therapist/notification',
    profile: '/therapist/profile',
    changePassword: '/therapist/change-password',
    assignedFrequency: '/therapist/frequency',
    student: '/therapist/students',
    'student-info': '/therapist/student/student-info',
    'refer-students': '/therapist/refer-student',
    'gened-consent-details': '/therapist/gened-consent-details',
    'assessment-calendar': '/therapist/assessment-calendar',
    schoolSessionManagement: '/therapist/school-session-management',
  },
};
