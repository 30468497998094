import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProfileBox from '../common/Box';

const StudentAccountManagerList = ({ data }) => {
  if (data === null || data === false || data.length === 0) {
    return (
      <Row>
        <Col sm={12}>
          <h5 className="mb-0 pd-t-10 pd-b-10 text-center tx-16 tx-sm-15 tx-lg-16">
            No Account Manager has been assigned to you.
          </h5>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="row-xs">
      {data.map((item, index) => (
        <ProfileBox key={index} name={item.name} email={item.email} image={item.image} />
      ))}
    </Row>
  );
};

export default StudentAccountManagerList;
