import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { getNavigation } from '../../services/api/authService';
import { setActiveMenu, setNavigation, setNotification } from '../../store/generalSlice';
import { isDevelopment } from '../../utils/utils';
import ShareResourceModal from '../../screens/therapist/share-a-resource-tools/ShareAResourceModal';

const Navigation = () => {
  const [expanded, setExpanded] = useState(false);
  // const [navigation, setNavigation] = useState([]);
  const dispatch = useDispatch();
  const navigation = useSelector((state) => state.general.navigation);
  const activeMenu = useSelector((state) => state.general.activeMenu);

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const res = await getNavigation();
        if (res?.status === 200) {
          dispatch(setNavigation(res.data.data));
        }
      } catch (err) {
        dispatch(
          setNotification({
            visible: true,
            title: err?.response?.data?.message || 'Something went wrong',
            type: 'error',
            delay: 4000,
          })
        );
        if (isDevelopment) {
          // eslint-disable-next-line no-console
          console.log('err ', err);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch]);

  const resourceModal = (i) => {
    setOpen(i);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      {open && <ShareResourceModal showModal={open} hide={closeModal} setModalShow={setOpen} />}
      {loading ? (
        <div className="d-none d-lg-flex tl-col-gap-20">
          {new Array(8).fill('').map((_, i) => (
            <Skeleton key={i} className="wd-60-f ht-15" />
          ))}
        </div>
      ) : (
        <Navbar expanded={expanded} expand="lg">
          <Navbar.Toggle
            as="a"
            aria-controls="basic-navbar-nav"
            className="burger-menu pos-static-f"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <FeatherIcon icon="menu" />
          </Navbar.Toggle>
          <Navbar.Collapse className="pd-0 d-xl-flex navbar-menu-wrapper" id="basic-navbar-nav">
            <div className="navbar-menu-header">
              <NavLink
                exact
                activeClassName="active"
                to="./"
                className="df-logo nav-link pd-l-0 d-flex align-items-center"
              >
                {/* <FeatherIcon icon="star" className="d-inline-block mg-r-5 tx-black" /> */}
                <span className="tx-primary">STAR</span>
              </NavLink>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="menuClose rounded-0 wd-40 ht-100p tx-gray-800 "
                onClick={() => {
                  setExpanded(!expanded);
                }}
              />
            </div>
            <ul className="nav navbar-menu mx-wd-100p justify-content-center">
              <li className="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>

              {navigation.map((navLinks, i) => (
                <CustomNavLink
                  key={navLinks.url || i}
                  {...navLinks}
                  setExpanded={setExpanded}
                  activeMenu={activeMenu}
                  modalItems={resourceModal}
                />
              ))}
            </ul>
          </Navbar.Collapse>
          <div
            className={classNames('backdrop', {
              show: expanded,
            })}
            onClick={() => {
              setExpanded(!expanded);
            }}
          />
        </Navbar>
      )}
    </>
  );
};

const CustomNavLink = ({ title, url, icon, children: subMenus, setExpanded, activeMenu, modalItems }) => {
  const dispatch = useDispatch();

  if (subMenus && subMenus.length) {
    return (
      <Dropdown as="li" className="nav-item with-sub">
        <Dropdown.Toggle
          as="a"
          className={classNames('nav-link tl-cursor-pointer', {
            active: activeMenu === title,
          })}
        >
          {icon ? <FeatherIcon icon={icon} className="d-inline d-lg-none" /> : null}
          {title}
        </Dropdown.Toggle>
        <Dropdown.Menu as="ul" className="navbar-menu-sub">
          {subMenus.map(({ title: subMenuLabel, url: subMenuLink }) => {
            const isExternalLink = subMenuLink.includes('https:') || subMenuLink.includes('http:');
            const modalLink = subMenuLink === '#share-resource';
            return (
              <Dropdown.Item key={subMenuLink} className="pd-0 nav-sub-item" as="li">
                <NavLink
                  to={{
                    // eslint-disable-next-line no-nested-ternary
                    pathname: modalLink ? undefined : isExternalLink ? subMenuLink : `/${subMenuLink}`,
                  }}
                  exact
                  className={(isActive) => `${isActive ? 'active' : ''} nav-sub-link`}
                  onClick={(event) => {
                    modalLink &&
                      (function () {
                        event.preventDefault();
                        modalItems(true);
                      })();
                    setExpanded(false);
                    dispatch(setActiveMenu(title));
                  }}
                  // eslint-disable-next-line no-nested-ternary
                  target={modalLink ? undefined : isExternalLink ? '_blank' : undefined}
                >
                  {subMenuLabel}
                </NavLink>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  return (
    <li className="nav-item">
      <NavLink
        exact
        to={{
          pathname: `/${url}`,
        }}
        className={(isActive) => `${isActive ? 'active' : ''} nav-link`}
        onClick={() => {
          setExpanded(false);
          dispatch(setActiveMenu(title));
        }}
      >
        {icon ? <FeatherIcon icon={icon} className="d-inline d-lg-none" /> : null}
        {title}
      </NavLink>
    </li>
  );
};

export default Navigation;
