/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import Dropdown from 'react-bootstrap/Dropdown';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { isEqual } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import TLSearchBox from './TL-Searchbox';
import TLTooltip from './TL-Tooltip';

const TOP_MARGIN = 45;

const Item = ({ icon, label, itemsClassName, textOverflow, showKeyWithLabel, id, showHashWithKey, showKeyAsValue }) => (
  <>
    {textOverflow ? (
      <span className="selected-label">
        <div className={`d-flex align-items-center tl-col-gap-10 ${itemsClassName || 'tx-normal'}`}>
          {icon && <FeatherIcon icon={icon} className="d-inline-block wd-15 ht-15 tx-black" />}
          {showKeyAsValue ? '' : label} {showKeyWithLabel && id ? `(${showHashWithKey ? '#' : ''}${id})` : ''}
        </div>
      </span>
    ) : (
      <div className={`d-flex align-items-center tl-col-gap-10 ${itemsClassName || 'tx-normal'}`}>
        {icon && <FeatherIcon icon={icon} className="d-inline-block wd-15 ht-15 tx-black" />}
        {showKeyAsValue ? '' : label} {showKeyWithLabel && id ? `(${showHashWithKey ? '#' : ''}${id})` : ''}
      </div>
    )}
  </>
);

let enterKeyPressedRecently = false;

function TLDropDownButton({
  items: listItems = [],
  onItemClick,
  defaultLabel = '',
  searchable = false,
  menuClassName = '',
  alignRight = true,
  className = '',
  initialSelectedItem = '',
  id = 'dd',
  label = '',
  required = true,
  tooltip = '',
  onSearchChange,
  displayDefaultLabelAsItem = false,
  disabled,
  error,
  icon,
  size = 'sm',
  contentLabel,
  toggleBtnClassName = '',
  toggleBtnItemsClassName = '',
  wrapperClassName = '',
  prefixItems = [],
  variant = 'white',
  textOverFlow = false,
  shouldChangeDDText = true,
  showKeyWithLabel,
  loading,
  loaderClassName,
  loaderContainerClassName,
  labelClassName,
  allowClear,
  onClear,
  dropdownTooltip,
  initialSelectedItems,
  isMultiple,
  onMultipleItemsChange,
  showHashWithKey = true,
  showKeyAsValue,
  maxItems,
  maxItemsMessage,
  showLoaderIcon,
  displayError = true,
  clearBtnClassName = '',
  isStatic = false,
}) {
  const [selectedItem, setSelectedItem] = useState(initialSelectedItem);
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems || []);
  const allItems = [...prefixItems, ...listItems];
  const [items, setItems] = useState([...prefixItems, ...listItems]);
  const [focusedItemIndex, setFocusedItemIndex] = useState();
  const dropdownMenuRef = useRef(null);
  const itemRefs = useRef([]);
  const [hovering, setHovering] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const searchInputRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (isDropdownOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    const newItems = [...prefixItems, ...listItems];
    if (!isEqual(newItems, items) && !filterValue) {
      setItems(newItems);
    }
  }, [filterValue, items, listItems, prefixItems]);

  useEffect(() => {
    if (shouldChangeDDText) {
      if (isMultiple) {
        setSelectedItems(initialSelectedItems || []);
      } else {
        setSelectedItem(initialSelectedItem);
      }
    }
  }, [initialSelectedItem, initialSelectedItems, isMultiple, shouldChangeDDText]);

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'ArrowDown':
        // Move the focus down
        if (focusedItemIndex < items.length - 1) {
          if (shouldChangeDDText) {
            setFocusedItemIndex((prevIndex) => prevIndex + 1);
          }
          ensureItemVisible(focusedItemIndex + 1); // Make sure the next item is visible
        }
        break;
      case 'ArrowUp':
        // Move the focus up
        if (focusedItemIndex > 0) {
          if (shouldChangeDDText) {
            setFocusedItemIndex((prevIndex) => prevIndex - 1);
          }
          ensureItemVisible(focusedItemIndex - 1); // Make sure the previous item is visible
        }
        break;
      case 'Enter':
        // Prevent double selection
        if (enterKeyPressedRecently) return;
        enterKeyPressedRecently = true;
        setTimeout(() => (enterKeyPressedRecently = false), 300);

        // Select the item when Enter is pressed
        if (focusedItemIndex >= 0 && isDropdownOpen) {
          const selectedItem = items[focusedItemIndex];
          if (shouldChangeDDText && selectedItem) {
            if (isMultiple) {
              // setSelectedItems((prev) => [...prev, selectedItem.key]);

              if (selectedItems.includes(selectedItem.key)) {
                const newItems = selectedItems.filter((itemKey) => itemKey !== selectedItem.key);
                onMultipleItemsChange && onMultipleItemsChange(newItems);
                setSelectedItems(newItems);
              } else {
                const newItems = [...selectedItems, selectedItem.key];
                onMultipleItemsChange && onMultipleItemsChange(newItems);
                setSelectedItems(newItems);
              }
            } else {
              setSelectedItem(selectedItem.key);
              onItemClick && onItemClick(selectedItem.key);
            }
          }

          setIsDropdownOpen(false);

          // Prevent default action and stop event propagation
          event.preventDefault();
          event.stopPropagation();
          setFilterValue('');
        }
        break;
      default:
        break;
    }
  };

  const handleDropdownToggle = (isOpen) => {
    if (enterKeyPressedRecently) return;

    setIsDropdownOpen(isOpen);

    // Check if the dropdown is opening
    if (isOpen && shouldChangeDDText) {
      const selectedIndex = allItems.findIndex((item) => item.key === (isMultiple ? selectedItems[0] : selectedItem));
      if (shouldChangeDDText) {
        setFocusedItemIndex(selectedIndex !== -1 ? selectedIndex : 0);
      }
    } else {
      setFocusedItemIndex();
      setItems(allItems);
      setFilterValue('');
    }
  };

  const ensureItemVisible = (index) => {
    if (dropdownMenuRef.current) {
      const menu = dropdownMenuRef.current;
      const selectedItem = itemRefs.current[index];

      if (selectedItem) {
        const { offsetTop, offsetHeight } = selectedItem;
        const scrollBottom = menu.scrollTop + menu.clientHeight;

        // Check if item is above the current scroll position AND the search box
        if (offsetTop < menu.scrollTop + TOP_MARGIN) {
          menu.scrollTop = offsetTop - TOP_MARGIN;
        } else if (offsetTop + offsetHeight > scrollBottom) {
          menu.scrollTop = offsetTop + offsetHeight - menu.clientHeight;
        }
      }
    }
  };

  const hasSelectedMaxItems = isMultiple && maxItems && maxItems === selectedItems?.length;

  return (
    <div onKeyDown={handleKeyDown} className={wrapperClassName}>
      {label ? (
        <div className="d-flex">
          <label
            htmlFor={id}
            className={classNames(`d-flex align-items-center tx-medium tx-interui tx-uppercase ${labelClassName}`, {
              'input-required': required,
              'tx-12': !labelClassName,
            })}
          >
            <span>{label}</span>
          </label>
          {tooltip && <TLTooltip tooltip={tooltip} className="mg-l-4 mg-b-8" />}
        </div>
      ) : null}
      {loading && !showLoaderIcon ? (
        <Skeleton className={loaderClassName} containerClassName={classNames('d-flex', loaderContainerClassName)} />
      ) : (
        <Dropdown
          id={id}
          className={classNames('tl-dropdown-list', className)}
          alignRight={alignRight}
          onToggle={handleDropdownToggle}
          show={isDropdownOpen}
          focusFirstItemOnShow
        >
          <TLTooltip tooltip={dropdownTooltip}>
            <Dropdown.Toggle
              variant={variant}
              size={size}
              className={classNames(
                `wd-100p d-flex align-items-center tl-col-gap-5 tl-dropdown-btn `,
                {
                  'bd-danger tx-danger': error,
                  'tl-dropdown-allow-clear': allowClear && selectedItem && !isMultiple,
                  'tl-dd-multiple-select flex-wrap tl-row-gap-5': isMultiple && selectedItems?.length,
                  'tl-dd-loading': loading,
                  'tl-static-dd': isStatic,
                },
                toggleBtnClassName
              )}
              disabled={disabled}
            >
              {icon && <FeatherIcon className="tl-mg-t-0.5-f" icon={icon} />}

              {selectedItems.length > 0
                ? selectedItems.map((itemKey) => {
                    const item = allItems.find((s) => s.key === itemKey);
                    if (item) {
                      return (
                        <div
                          className="d-inline-block btn btn-primary btn-xs d-flex align-items-center tx-12 pd-4 tl-dd-multiple-select-btn"
                          key={itemKey}
                        >
                          <FeatherIcon
                            icon="x"
                            onClick={(e) => {
                              e.stopPropagation(); // prevent dropdown from closing
                              const newItems = selectedItems.filter((i) => i !== itemKey);
                              setSelectedItems(newItems);
                              onMultipleItemsChange && onMultipleItemsChange(newItems);
                              setIsDropdownOpen(false);
                            }}
                            className="mg-0-f mg-r-2-f op-5 wd-10-f ht-10-f"
                          />
                          <span className="">{item.label}</span>
                        </div>
                      );
                    }
                    return null;
                  })
                : null}

              {isMultiple && selectedItems?.length ? null : selectedItem ? (
                <Item
                  textOverflow={textOverFlow}
                  itemsClassName={toggleBtnItemsClassName}
                  label={allItems.find((s) => s.key === selectedItem)?.label || defaultLabel}
                  icon={allItems.find((s) => s.key === selectedItem)?.icon}
                  showKeyWithLabel={showKeyWithLabel}
                  id={selectedItem}
                  showHashWithKey={showHashWithKey}
                  showKeyAsValue={showKeyAsValue}
                />
              ) : (
                <Item
                  textOverflow={textOverFlow}
                  itemsClassName={toggleBtnItemsClassName}
                  label={allItems.find((s) => s.key === selectedItem)?.label || defaultLabel}
                  icon={allItems.find((s) => s.key === selectedItem)?.icon}
                  showKeyWithLabel={showKeyWithLabel}
                  id={selectedItem}
                  showHashWithKey={showHashWithKey}
                  showKeyAsValue={showKeyAsValue}
                />
              )}

              {/* Clearable functionality */}
              {allowClear && selectedItem && !isMultiple && (
                <TLTooltip tooltip="Clear">
                  <FeatherIcon
                    icon="x"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isMultiple) {
                        setSelectedItems([]);
                      } else {
                        setSelectedItem('');
                      }
                      onClear && onClear();
                    }}
                    className={classNames('mg-0-f tl-dropdown-clear-btn', clearBtnClassName)}
                  />
                </TLTooltip>
              )}

              {showLoaderIcon && loading ? (
                <div className="loader tl-dd-loader">
                  <FeatherIcon className="tx-50 wd-15-f ht-15-f" icon="loader" />
                </div>
              ) : null}
            </Dropdown.Toggle>
          </TLTooltip>
          <Dropdown.Menu ref={dropdownMenuRef} className={classNames('dropdown-menu small_scroll', menuClassName)}>
            {contentLabel ? <div className="pd-5 tx-10 tx-uppercase tx-medium tx-black">{contentLabel}</div> : null}
            {searchable && !hasSelectedMaxItems ? (
              <>
                <Dropdown.Item as="div" className="tl-searchable-dropdown-item bd-b pd-t-0 pd-l-0 pd-r-0 pd-b-5 mg-b-5">
                  <TLSearchBox
                    ref={searchInputRef}
                    noBtn
                    value={filterValue}
                    onChange={(value) => {
                      setFilterValue(value);
                      onSearchChange && onSearchChange(value);
                      const baseFilter = (item) => item.label.toLowerCase().includes(value.toLowerCase());
                      const keyFilter = (item) => item.key.toString().toLowerCase().includes(value.toLowerCase());

                      const filteredItems = allItems.filter(
                        showKeyWithLabel ? (item) => baseFilter(item) || keyFilter(item) : baseFilter
                      );

                      setItems(filteredItems);
                      setFocusedItemIndex(
                        filteredItems.findIndex((item) => item.key === selectedItem) !== -1
                          ? filteredItems.findIndex((item) => item.key === selectedItem)
                          : 0
                      );
                    }}
                    onInputClick={(e) => {
                      e.stopPropagation();
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    isLoading={false}
                    // onClear={onSearchClear}
                    // onBlur={onSearchBlur}
                  />
                </Dropdown.Item>
              </>
            ) : null}

            {defaultLabel && displayDefaultLabelAsItem && (
              <Dropdown.Item
                onClick={() => {
                  if (isMultiple) {
                    onMultipleItemsChange && onMultipleItemsChange([]);
                  } else {
                    onItemClick && onItemClick('');
                  }
                  if (shouldChangeDDText) {
                    if (isMultiple) {
                      setSelectedItems([]);
                    } else {
                      setSelectedItem('');
                    }
                  }
                }}
              >
                {defaultLabel}
              </Dropdown.Item>
            )}

            {[
              ...(hasSelectedMaxItems
                ? [
                    {
                      key: 'max-selection',
                      label: maxItemsMessage || `You can only select ${maxItems} ${maxItems > 1 ? 'items' : 'item'}.`,
                      icon: null,
                      disabled: true,
                    },
                  ]
                : items),
            ].map(({ key, label, icon, disabled }, index) => (
              <Dropdown.Item
                disabled={disabled}
                ref={(el) => (itemRefs.current[index] = el)}
                onClick={() => {
                  if (isMultiple) {
                    if (selectedItems.includes(key)) {
                      const newItems = selectedItems.filter((itemKey) => itemKey !== key);
                      onMultipleItemsChange && onMultipleItemsChange(newItems);
                      setSelectedItems(newItems);
                    } else {
                      const newItems = [...selectedItems, key];
                      onMultipleItemsChange && onMultipleItemsChange(newItems);
                      setSelectedItems(newItems);
                    }
                  } else {
                    onItemClick && onItemClick(key);
                    if (shouldChangeDDText) {
                      setSelectedItem(key);
                    }
                  }
                }}
                key={key}
                className={classNames({
                  // active: isMultiple ? selectedItems.includes(key) : key === selectedItem && !hovering,
                  // 'focused-item': index === focusedItemIndex && !hovering,
                  active: isMultiple
                    ? selectedItems?.length
                      ? selectedItems[0] === key
                      : false
                    : key === selectedItem && !hovering,
                  'focused-item': index === focusedItemIndex && !hovering,
                })}
                onMouseEnter={() => {
                  if (shouldChangeDDText) {
                    setFocusedItemIndex(index);
                  }
                  setHovering(true);
                }}
                onMouseLeave={() => {
                  if (shouldChangeDDText) {
                    if (!selectedItem && !isMultiple) {
                      setFocusedItemIndex(0);
                    } else {
                      setFocusedItemIndex();
                    }
                  }
                  setHovering(false);
                }}
              >
                <Item
                  icon={icon}
                  label={
                    showKeyWithLabel && !hasSelectedMaxItems ? `${label} (${showHashWithKey ? '#' : ''}${key})` : label
                  }
                />
              </Dropdown.Item>
            ))}

            {items.length === 0 ? 'No data found' : null}
          </Dropdown.Menu>
        </Dropdown>
      )}
      {error && displayError ? <div className="text-danger tx-12">{error}</div> : null}
    </div>
  );
}

export default TLDropDownButton;
