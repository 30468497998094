import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import Schedule from './Schedule';
import ProfileBox from './ProfileBox';
import SectionBox from '../dashboard/common/SectionBox';

const ScheduleWithProfile = ({ handle }) => (
  <Row>
    <Col lg={9} md={12} sm={12}>
      <SectionBox Title="Today's Schedules" Icon="video" HomeData={<Schedule />} />
    </Col>
    <Col lg={3} md={5} sm={6}>
      <ProfileBox />
      <Button className="btn btn-secondary mg-t-15 tx-white btn-block d-none" onClick={handle}>
        <FeatherIcon icon="arrow-left" className="mg-r-5" />
        Switch Back To Old Theme
      </Button>
    </Col>
  </Row>
);

export default ScheduleWithProfile;
