import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getStudentMeetingUrl, zoomAccess } from '../../../../services/api/student/authService';
import { setMeetingUrl } from '../../../../store/userSlice';
import TLFullScreenLoader from '../../../common/TL-FullScreenLoader';
import { setFeedspaceStateByKeys } from '../../../../store/feedspaceSlice';
import { sendAmplitudeData } from '../../../../utils/amplitude';

const ScheduleBox = ({ title, name, time, location, scheduleurl, ...rest }) => {
  const { eventID, zoomRoomID } = rest;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [meetingData, setMeetingData] = useState({
    provider: '',
    meetingUrl: '',
    newTab: false,
    redirect: false,
  });

  const meetOnWhereBy = (url, newTab) => {
    sendAmplitudeData('WhereBy Meeting');
    dispatch(setMeetingUrl(url));
    setMeetingData({
      provider: 'whereby',
      meetingUrl: '/student/whereby',
      newTab,
      redirect: true,
    });
  };

  const meetOnZoom = (url, newTab) => {
    sendAmplitudeData('Zoom Meeting');
    window.open(url, newTab ? '_blank' : '_self');
  };

  const onMeetMyTherapist = async () => {
    dispatch(
      setFeedspaceStateByKeys({
        id: null,
        openPopup: false,
      })
    );
    sendAmplitudeData('Meet My Therapist');

    try {
      if (zoomRoomID) {
        const data = { zoom_room_id: zoomRoomID, event_id: eventID };
        await zoomAccess(data);
      }
    } catch (_) {
      //
    }

    try {
      setIsLoading(true);

      const meetingRes = await getStudentMeetingUrl(eventID);

      const resData = meetingRes.data;
      const { provider, meeting_url, new_tab } = resData.data;
      if (provider === 'whereby') {
        meetOnWhereBy(meeting_url, false);
      } else if (provider === 'zoom') {
        meetOnZoom(meeting_url, new_tab);
      }
      if (resData?.message) {
        setError(resData.message);
      } else {
        setError('');
      }
    } catch (error) {
      setError(error?.response?.data?.message || '');
    } finally {
      setIsLoading(false);
    }
  };

  if (meetingData.redirect && meetingData.meetingUrl) {
    return <Redirect to={meetingData.meetingUrl} target={meetingData.newTab ? '_blank' : '_self'} />;
  }

  return (
    <>
      {isLoading && <TLFullScreenLoader />}
      {error && <p className="invalid-feedback mg-b-10 tx-13 text-center d-block">{error}</p>}
      <div className="col-md-6 mg-t-5 mg-b-5" onClick={onMeetMyTherapist}>
        <Nav.Link className="d-block pd-0">
          <Card className="text-center media pd-10 bd bd-2 tx-bold align-items-center justify-content-center pos-relative ht-100p tx-color-02 bg-gray-100 rounded">
            <Card.Body className="pd-0">
              <Card.Text>
                <span className="room-box text-center tx-12">
                  <span className="d-block tx-20 tx-bold">{title}</span>
                  <span className="d-block">{name}</span>
                  <span className="d-block">{time}</span>
                  <span className="d-block">{location}</span>
                </span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Nav.Link>
      </div>
    </>
  );
};

export default ScheduleBox;
