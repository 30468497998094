import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasInstantiated: false,
  openPopup: false,
  id: null,
};

const reducers = {
  setHasInstantiated: (state, action) => {
    state.hasInstantiated = action.payload;
  },
  setOpenPopup: (state, action) => {
    state.openPopup = action.payload;
  },
  setId: (state, action) => {
    state.id = action.payload;
  },
  setStateByKeys: (state, action) => {
    Object.keys(action.payload).forEach((key) => {
      state[key] = action.payload[key];
    });
  },
};

const slice = createSlice({
  name: 'feedspace',
  initialState,
  reducers,
});

export const {
  setHasInstantiated: setFeedspaceHasInstantiated,
  setOpenPopup: setFeedspaceOpenPopup,
  setId: setFeedspaceId,
  setStateByKeys: setFeedspaceStateByKeys,
} = slice.actions;

export default slice.reducer;
