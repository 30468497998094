import React from 'react';
import TherapistRouter from '../../routes/therapist';
import Layout from '../../layouts/Layout';

const Therapist = () => (
  <>
    <Layout>
      <TherapistRouter />
    </Layout>
  </>
);

export default Therapist;
