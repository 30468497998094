import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TLTooltip from '../../common/TL-Tooltip';

const PaginationBox = ({ paginationdata, getPageIdData }) => {
  const [pageIndex, setPageIndex] = useState([]);

  useEffect(() => {
    setPagesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPagesData = () => {
    const pagesIndex = [];
    const pageCount = 5;
    let count = 0;
    const maxCount = paginationdata.last_page >= pageCount ? pageCount : paginationdata.last_page;

    for (let i = paginationdata.current_page - 2; count < maxCount; i++) {
      if (i > paginationdata.last_page) break;
      if (i > 0) {
        pagesIndex.push(i);
        count++;
      }
    }
    setPageIndex(pagesIndex);
  };

  return (
    <Row>
      {paginationdata.total > 1 && (
        <Col md={6} sm={12}>
          <p className="text-center text-md-left mg-md-b-0">
            Showing {paginationdata.from} to {paginationdata.to} of {paginationdata.total} entries
          </p>
        </Col>
      )}
      {paginationdata.total > 10 && (
        <Col md={6} sm={12}>
          <Pagination className="d-flex justify-content-center justify-content-md-end">
            <TLTooltip tooltip="First">
              <Pagination.First
                className="first-page"
                disabled={paginationdata.current_page === 1}
                onClick={() => getPageIdData(1)}
              />
            </TLTooltip>
            <TLTooltip tooltip="Previous">
              <Pagination.Prev
                className="prev-btn"
                disabled={paginationdata.current_page === 1}
                onClick={() => getPageIdData(paginationdata.current_page - 1)}
              />
            </TLTooltip>
            {pageIndex.map((i) => {
              if (i === paginationdata.current_page) {
                return (
                  <Pagination.Item key={i} active>
                    {i}
                  </Pagination.Item>
                );
              }
              return (
                <Pagination.Item key={i} onClick={() => getPageIdData(i)}>
                  {i}
                </Pagination.Item>
              );
            })}
            <TLTooltip tooltip="Next">
              <Pagination.Next
                className="next-btn"
                disabled={paginationdata.current_page === paginationdata.last_page}
                onClick={() => getPageIdData(paginationdata.current_page + 1)}
              />
            </TLTooltip>
            <TLTooltip tooltip="Last">
              <Pagination.Last
                className="last-page"
                disabled={paginationdata.current_page === paginationdata.last_page}
                onClick={() => getPageIdData(paginationdata.last_page)}
              />
            </TLTooltip>
          </Pagination>
        </Col>
      )}
    </Row>
  );
};

export default PaginationBox;
