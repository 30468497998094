import React from 'react';
import { Nav, Col, Container } from 'react-bootstrap';
import MainLogo from '../../../assets/images/ETherapy-logo.svg';
import TLSideBarSetting from '../../common/TL-SideBarSetting';
import { useWindowSize } from '../../../hooks/useWindowSize';
import TLChangeContrast from './TL-ChangeContrast';
import TLFontsIncrease from './TL-FontsIncrease';

const HeaderTop = () => {
  const size = useWindowSize();

  return (
    <>
      {size.width < 992 && <TLSideBarSetting />}
      <Container className="d-block d-sm-flex d-md-flex">
        <Col md={12} lg={4} sm={12} className="pd-sm-l-15 pd-sm-r-15 pd-xxs-0 pd-0 pd-sm-t-10 pd-sm-b-10">
          <div className="navbar-brand d-block pd-xxs-0 text-center text-lg-left">
            <Nav.Link
              href="https://www.electronic-therapy.com/"
              target="_blank"
              className="df-logo d-inline-block pd-0"
            >
              <img src={MainLogo} alt="Logo" />
            </Nav.Link>
          </div>
        </Col>
        {size.width > 991 && (
          <Col md={8} sm={8} className="pd-r-15 pd-l-15 pd-t-10 pd-sm-t-0 d-xxs-block d-sm-block">
            <div className="navbar-right xss-text-left pd-xxs-0">
              <TLChangeContrast />
              <TLFontsIncrease />
            </div>
          </Col>
        )}
      </Container>
    </>
  );
};

export default HeaderTop;
