import classNames from 'classnames';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

// Variant = primary, secondary, success, danger, warning, info, light, dark
const TLToast = ({ toastTitle = 'Notification', message, show, handleClose, variant }) => (
  <div className="pos-fixed t-10 r-0 l-0 wd-100p tx-center toast-wrap tl-toast">
    <Row className="justify-content-center">
      <Col xs={12}>
        <Toast className="mg-l-auto mg-r-auto" onClose={handleClose} show={show} delay={3000} autohide>
          <div
            className={classNames('alert alert-dismissible fade show mg-0', {
              'alert-success': variant === 'success',
              'alert-danger': variant === 'danger',
            })}
            role="alert"
          >
            <h4 className="alert-heading tx-16 tx-left mg-0">{toastTitle}</h4>
            <button onClick={handleClose} type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <hr />
            <p className="mg-0 tx-left message">{message}</p>
          </div>
        </Toast>
      </Col>
    </Row>
  </div>
);

export default TLToast;
