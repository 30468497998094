import React, { useState, useEffect, useRef } from 'react';
import { Nav, Form } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import MainLogo from '../../assets/images/ETherapy-logo.svg';
import TLChangeContrast from '../student/common/TL-ChangeContrast';
import TLFontsIncrease from '../student/common/TL-FontsIncrease';

const TLSideBarSetting = () => {
  const settingWrapperRef = useRef(null);
  const [settingShow, setSettingShow] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (settingWrapperRef.current && !settingWrapperRef.current.contains(event.target)) {
      setSettingShow(false);
    }
  };

  function settingClick() {
    setSettingShow((current) => !current);
  }
  return (
    <>
      <div className={`${settingShow ? 'backdrop visible op-none d-lg-none' : ''}`} />
      <div className={`df-settings ${settingShow ? 'show' : ''}`} ref={settingWrapperRef}>
        <span
          className="df-settings-link wd-35 ht-35 d-inline-flex justify-content-center align-items-center"
          onClick={settingClick}
        >
          <FeatherIcon className="tx-50" icon="settings" />
        </span>
        <div className="df-settings-body">
          <div className="pd-y-20">
            <h3 className="tx-bold tx-spacing--2 tx-brand-02 mg-b-0">
              <Nav.Link href="https://www.electronic-therapy.com/" target="_blank" className="df-logo d-block pd-0">
                <img className="mx-wd-90p-f" src={MainLogo} alt="Logo" />
              </Nav.Link>
            </h3>
          </div>

          <div className="pd-y-20 bd-t">
            <Form.Label className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">
              Change Theme
            </Form.Label>
            <TLChangeContrast />
          </div>

          <div className="pd-y-20 bd-t">
            <Form.Label className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">
              Change Font Size
            </Form.Label>
            <div className="font-box">
              <TLFontsIncrease />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TLSideBarSetting;
