import { Col, Container, Row } from 'react-bootstrap';
// eslint-disable-next-line import/no-unresolved
import '@whereby.com/browser-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { setFeedspaceStateByKeys } from '../../store/feedspaceSlice';
import { FEEDSPACE_ID_WHEREBY_POST_SESSION, APP_NAME } from '../../utils/constants/AppConstant';

function Whereby() {
  const user = useSelector((state) => state?.user[0]);
  const displayName = user?.name;
  const type = user?.type;
  const [meetingUrl, setMeetingUrl] = useState('');
  const oldMeetingUrl = user?.meetingUrl;
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (oldMeetingUrl) {
      const params = oldMeetingUrl.split('?');
      const hasQueryParams = params.length > 1;
      if (hasQueryParams) {
        setMeetingUrl(`${oldMeetingUrl}&roomIntegrations=on`);
      } else {
        setMeetingUrl(`${oldMeetingUrl}?roomIntegrations=on`);
      }
    }
  }, [oldMeetingUrl]);

  const wherebyRef = useCallback(
    (node) => {
      if (node !== null) {
        const onLeave = () => {
          node?.removeEventListener('leave', onLeave);
          if (type === 1) {
            dispatch(
              setFeedspaceStateByKeys({
                id: FEEDSPACE_ID_WHEREBY_POST_SESSION,
                openPopup: true,
              })
            );
          }
          // redirect the user
          setRedirect(true);
        };
        node?.removeEventListener('leave', onLeave);
        node?.addEventListener('leave', onLeave);
      }
    },
    [dispatch, type]
  );

  const redirectStudent = () => <Redirect to="/student/home" />;
  const redirectSchool = () => <Redirect to="/student/school/home" />;

  if (redirect) {
    if (type === 1) {
      return redirectStudent();
    }
    if (type === 3) {
      return redirectSchool();
    }
  }

  return (
    <>
      <Helmet title={`WhereBy - ${type === 1 ? 'Student' : 'School'} - ${APP_NAME}`}>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {meetingUrl ? (
        <Container className="tl-whereby-wrapper">
          <Row className="h-100">
            <Col>
              <whereby-embed
                ref={wherebyRef}
                room={meetingUrl}
                background="off"
                displayName={displayName}
                screenshare
                leaveButton
                chat
                participant_join
                participant_leave
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <p className="invalid-feedback mg-b-10 tx-13 text-center d-block">Invalid Meeting URL</p>
      )}
    </>
  );
}

export default Whereby;
