import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import themeReducer from './themeSlice';
import feedspaceReducer from './feedspaceSlice';
import generalReducer from './generalSlice';

export const rootReducer = combineReducers({
  user: userReducer,
  theme: themeReducer,
  feedspace: feedspaceReducer,
  general: generalReducer,
});
