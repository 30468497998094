import classNames from 'classnames';
import React from 'react';
import { Modal } from 'react-bootstrap';

function TLModal({
  titleInvisible = false,
  children,
  show,
  onHide,
  title = '',
  className = '',
  size = 'md',
  isCentered = true,
  headerRight,
  titleClassName,
  headerClassName = '',
  backdrop,
  keyboard,
  bodyClassName = '',
  modalId,
  backdropClassName = '',
}) {
  return (
    <Modal
      id={modalId}
      size={size}
      centered={isCentered}
      show={show}
      onHide={onHide}
      className={className}
      backdrop={backdrop}
      keyboard={keyboard}
      backdropClassName={backdropClassName}
    >
      <div className="modal-content">
        {titleInvisible === false && (
          <div className={classNames('modal-header align-items-center', headerClassName)}>
            <div className="d-flex justify-content-between align-items-md-center wd-100p flex-column flex-md-row align-items-start tl-row-gap-10">
              <h6 className={`modal-title tx-left ${titleClassName || ''}`}>{title}</h6>
              {headerRight}
            </div>
            <button onClick={onHide} type="button" className="close" data-dismiss="modal" aria-label="Close">
              {onHide ? <span aria-hidden="true">×</span> : null}
            </button>
          </div>
        )}
        <div className={classNames('modal-body', bodyClassName)}>
          {titleInvisible === true && (
            <button onClick={onHide} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          )}
          {children}
        </div>
      </div>
    </Modal>
  );
}

export default TLModal;
