import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FooterLogo from '../../../assets/images/footer-logo.svg';

const Footer = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  window.addEventListener('scroll', toggleVisible);

  return (
    <footer className="footer bg-purple" id="footer">
      <Container className="pd-0">
        <Row>
          <Col sm={12} md={12} className="d-flex justify-content-between align-items-center">
            <img src={FooterLogo} alt="Logo" className="wd-150 ht-auto mx-wd-100p" />
            {/* <TLFeedspace
              as="a"
              className="tx-white text-capitalize cursor-pointer tx-roboto "
              label="Give Feedback"
              id={FEEDSPACE_ID_GIVE_FEEDBACK}
            /> */}
          </Col>
        </Row>
      </Container>
      <button
        className="scroll-top"
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }}
        style={{ display: visible ? 'block' : 'none' }}
      >
        <span>Top</span>
      </button>
    </footer>
  );
};

export default Footer;
