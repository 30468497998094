import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setThemeFontNormal, setThemeFontLarge, setThemeFontExtraLarge } from '../../../store/themeSlice';
import { sendAmplitudeData } from '../../../utils/amplitude';

const TLFontsIncrease = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.theme);
  const { fontSize } = theme.student;
  const { darkMode } = theme.student;

  const handleSmallFont = () => {
    dispatch(setThemeFontNormal('student'));
    sendAmplitudeData('Change to Small Font');
  };

  const handleMediumFont = () => {
    dispatch(setThemeFontLarge('student'));
    sendAmplitudeData('Change to Medium Font');
  };

  const handleLargeFont = () => {
    dispatch(setThemeFontExtraLarge('student'));
    sendAmplitudeData('Change to Large Font');
  };

  return (
    <>
      <OverlayTrigger placement="bottom" overlay={<Tooltip>Click to change original size</Tooltip>}>
        <span className="d-inline-block">
          <Button
            onClick={handleSmallFont}
            className={`change-font mg-l-5 mg-sm-l-10 btn  pd-0 d-inline-flex align-items-center justify-content-center ht-35 wd-35 text-center normal-fonts-button ${
              fontSize === 'font-normal' ? 'tl-opacity-1' : 'btn-white'
            }
            ${darkMode && fontSize !== 'font-normal' ? 'bg-transparent' : ''}
            `}
            variant="light"
          >
            <span className="tx-14">A</span>
          </Button>
        </span>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip>Click to change font size +</Tooltip>}>
        <span className="d-inline-block">
          <Button
            onClick={handleMediumFont}
            className={`change-font mg-l-5 mg-sm-l-10 btn pd-0 d-inline-flex align-items-center justify-content-center ht-35 wd-35 text-center large-fonts-button ${
              fontSize === 'font-large' ? 'tl-opacity-1' : 'btn-white'
            } ${darkMode && fontSize !== 'font-large' ? 'bg-transparent' : ''}`}
            variant="light"
          >
            <span className="tl-tx-17">A</span>
          </Button>
        </span>
      </OverlayTrigger>

      <OverlayTrigger placement="bottom" overlay={<Tooltip>Click to change font size ++</Tooltip>}>
        <span className="d-inline-block">
          <Button
            onClick={handleLargeFont}
            className={`change-font mg-l-5 mg-sm-l-10 btn  pd-0 d-inline-flex justify-content-center align-items-center ht-35 wd-35 text-center extra-large-fonts-button ${
              fontSize === 'font-extra-large' ? 'tl-opacity-1' : 'btn-white'
            } ${darkMode && fontSize !== 'font-extra-large' ? 'bg-transparent' : ''}`}
            variant="light"
          >
            <span className="tx-20">A</span>
          </Button>
        </span>
      </OverlayTrigger>
    </>
  );
};

export default TLFontsIncrease;
