import React from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Skeleton from 'react-loading-skeleton';

const AccountManagerSkeleton = () => (
  <Row>
    <Col md={6} className="mg-t-5 mg-b-5">
      <Card className="media pd-10 bd bd-2 align-items-center justify-content-center pos-relative ht-100p rounded flex-row">
        <Card className="wd-60 ht-60 wd-lg-80 ht-lg-80 bg-gray-100 bd bd-2 rounded d-flex align-self-start justify-content-center pd-5">
          <Skeleton className="wd-60 ht-45 wd-lg-80 ht-lg-65 t--3 rounded" />
        </Card>
        <Card.Body className="media-body pd-0 pd-l-15 wd-less-80">
          <Card.Title className="mg-b-5">
            <Skeleton className="ht-20" />
          </Card.Title>
          <Card.Text>
            <Skeleton />
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);

export default AccountManagerSkeleton;
