import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { Helmet } from 'react-helmet';
import ScheduleWithProfile from '../../../components/student/schoolhome/ScheduleWithProfile';
import SchoolHomeTable from '../../../components/student/schoolhome/SchoolHomeTable';
import Modal from '../../../components/student/common/Modal';
import { backToOldTheme } from '../../../services/api/student/authService';
import { APP_NAME } from '../../../utils/constants/AppConstant';

const SchoolHome = () => {
  const userType = useSelector((state) => state.user[0].type);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [backThemeError, setBackThemeError] = useState('');
  const handleOldTheme = async () => {
    setLoader(true);
    try {
      const res = await backToOldTheme();
      const { data } = res.data;
      window.open(data.link, '_self');
      setTimeout(setLoader(false), 5000);
    } catch (error) {
      setLoader(false);
      setShowModal(true);
      setBackThemeError(error?.response?.data?.message || 'Something went wrong');
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <Helmet title={`Home - School - ${APP_NAME}`}>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {loader && (
        <div className="loader_main">
          <div className="loader">
            <FeatherIcon className="tx-50" icon="loader" />
          </div>
        </div>
      )}
      <Modal
        size="md"
        data={
          backThemeError || (
            <p className="mg-0 tx-16 text-center tx-danger">
              <FeatherIcon className="tx-80 d-block wd-10 ht-10 mg-l-auto mg-r-auto" icon="alert-triangle" />
              Something went wrong. Please try again after sometime.
            </p>
          )
        }
        show={showModal}
        onHide={handleClose}
        centered
      />
      <Container>
        {userType === 5 ? <ScheduleWithProfile handle={handleOldTheme} /> : <SchoolHomeTable handle={handleOldTheme} />}
      </Container>
    </>
  );
};

export default SchoolHome;
