import { API_STUDENTS_PATH, API_STUDENT_PATH, API_VERSION_PATH } from '../../../utils/constants/AppConstant';
import http from '../httpService';

const exportHeader = {
  responseType: 'arraybuffer',
};

export function login(data) {
  return http.post(`${API_STUDENT_PATH}/login`, data);
}

export function loginWithHash(hash) {
  return http.post(`${API_STUDENT_PATH}/back-to-new-system`, { hash });
}

export function schoolLogin(data) {
  return http.post(`${API_STUDENT_PATH}/school/login`, data);
}
export function schoolDetail(data) {
  return http.get(`${API_STUDENT_PATH}/school/details`, data);
}
export function studentActivity(data) {
  return http.post(`${API_STUDENT_PATH}/activity`, data);
}

export function schoolMeeting(url, data) {
  return http.post(url, data);
}

export function schoolHomePage(pageId = 1, q = '', per_page = 10, sort = 'dt-asc', exports = '') {
  if (q) {
    return http.get(
      `${API_STUDENT_PATH}/school/home?q=${q}&page=${pageId}&per_page=${per_page}&sort=${sort}&export=${exports}`
    );
  }
  return http.get(`${API_STUDENT_PATH}/school/home?page=${pageId}&per_page=${per_page}&sort=${sort}&export=${exports}`);
}

export function logout() {
  return http.post(`${API_STUDENT_PATH}/logout`);
}

export function getUser() {
  return http.get(`${API_STUDENT_PATH}/details`);
}

export function sessions(pageid = 1, q = '', per_page = 10, sort = 'dt-asc', exports = '') {
  let path = `${API_STUDENT_PATH}/sessions?page=${pageid}&per_page=${per_page}&sort=${sort}&export=${exports}`;
  const header = exports.length > 0 ? exportHeader : {};
  if (q) path += `&q=${q}`;
  return http.get(path, header);
}

export function frequency(pageid = 1, q = '', per_page = 10, sort = 'dt-asc', exports = '') {
  let path = `${API_STUDENT_PATH}/frequency?page=${pageid}&per_page=${per_page}&sort=${sort}&export=${exports}`;
  const header = exports.length > 0 ? exportHeader : {};
  if (q) path += `&q=${q}`;
  return http.get(path, header);
}

export function getInsuranceDocuments(pageid = 1, q = '', per_page = 10, sort = 'dt-desc', exports = '') {
  let path = `${API_STUDENT_PATH}/documents?page=${pageid}&per_page=${per_page}&sort=${sort}&export=${exports}`;
  const header = exports.length > 0 ? exportHeader : {};
  if (q) path += `&q=${q}`;
  return http.get(path, header);
}

export function fileAction({ action = 'view', ...rest }) {
  return http.post(`${API_STUDENT_PATH}/documents/file`, { action, ...rest });
}

export function dashboard() {
  return http.get(`${API_STUDENT_PATH}/dashboard`);
}
export function zoomAccess(data) {
  return http.post(`${API_STUDENT_PATH}/zoom/room-accessed`, data);
}

export function getStudentMeetingUrl(eventId) {
  return http.post(`${API_STUDENT_PATH}/get-meeting`, {
    event_id: eventId,
  });
}

export function getMeetingUrl(data) {
  return http.post(`${API_STUDENT_PATH}/get-meeting`, data);
}

export function getSchoolMeetingUrl(eventId) {
  return http.post(`${API_STUDENT_PATH}/school/get-meeting`, {
    event_id: eventId,
  });
}

export function profileDetails() {
  return http.get(`${API_STUDENT_PATH}/details`);
}
export function toggleProfileDetails() {
  return http.post(`${API_STUDENT_PATH}/toggle-profile-details`);
}

export function genEdAvailed() {
  return http.get(`${API_STUDENT_PATH}/gen-ed/has-availed`);
}

export function intakeAvailed() {
  return http.get(`${API_STUDENT_PATH}/intake/has-availed`);
}

export function intakeGetConcerns() {
  return http.get(`${API_STUDENT_PATH}/intake/concerns`);
}

export function submitIntake(data) {
  return http.post(`${API_STUDENT_PATH}/intake/submit-intake`, data);
}

export function progressDetails() {
  return http.get(`${API_STUDENT_PATH}/progress`);
}

export function updateStudentInfo(data) {
  return http.put(`${API_STUDENT_PATH}/update-info`, data);
}

export function schedule(pageid = 1, q = '', per_page = 10, sort = 'dt-asc', exports = '') {
  let path = `${API_STUDENT_PATH}/schedules?page=${pageid}&per_page=${per_page}&sort=${sort}&export=${exports}`;
  const header = exports.length > 0 ? exportHeader : {};
  if (q) path += `&q=${q}`;
  return http.get(path, header);
}

export function Profile() {
  return http.get(`${API_STUDENT_PATH}/profile`);
}

export function LoginActivity() {
  return http.get(`${API_STUDENT_PATH}/activity/login`);
}

export function genEdSubmitConsent(data) {
  return http.post(`${API_STUDENT_PATH}/gen-ed/submit-consent`, data);
}

export function navigation() {
  return http.get(`${API_STUDENT_PATH}/navigation`);
}

export function scheduleCalendar(period) {
  return http.get(`${API_STUDENT_PATH}/schedules/calendar?month=${period}`);
}

export function backToOldTheme(url, hash) {
  return http.post(`${API_STUDENT_PATH}/back-to-old-system`, hash);
}

export function loginReference(data) {
  return http.post(`${API_STUDENT_PATH}/login-reference`, data);
}

export function uploadInsuranceDocument(data) {
  return http.post(`${API_STUDENT_PATH}/documents/upload`, data);
}

export function systemRequirementClick() {
  return http.post(`${API_STUDENT_PATH}/click`, {
    attribute: 'check-system-requirement',
  });
}

export const getStudentDetails = ({ id, params = '' }) => http.get(`${API_STUDENTS_PATH}/${id}?${params}`);
export const deactivateStudentFrequencyW2 = ({ id = '', data }) =>
  http.put(`${API_STUDENTS_PATH}/frequency/${id}`, data);

export const studentDetailsEdit = ({ id = '', data }) => http.put(`${API_STUDENTS_PATH}/${id}`, data);
export const studentActiveDeactivate = ({ id = '', data }) => http.put(`${API_STUDENTS_PATH}/status/${id}`, data);

export const getStudentFrequency = ({ params = '' }) => http.get(`${API_STUDENTS_PATH}/frequency?${params}`);
export const addStudentFrequency = ({ data }) => http.post(`${API_STUDENTS_PATH}/frequency`, data);
export const frequencyService = ({ params = '' }) => http.get(`${API_VERSION_PATH}/frequency/services?${params}`);
export const frequencyServiceType = ({ params = '' }) => http.get(`${API_VERSION_PATH}/frequency/types?${params}`);
export const frequencyPeriod = ({ params = '' }) => http.get(`${API_VERSION_PATH}/frequency/periods?${params}`);

export const getStudentSingleFrequency = ({ params = '', id = '' }) =>
  http.get(`${API_STUDENTS_PATH}/frequency/${id}?${params}`);

export const getStudentSession = ({ params = '' }) => http.get(`${API_STUDENTS_PATH}/session?${params}`);

export const getStudentDocuments = ({ params = '' }) => http.get(`${API_STUDENTS_PATH}/documents?${params}`);

export const getStudentDocumentsTypes = ({ params = '' }) => http.get(`${API_STUDENTS_PATH}/documents/types?${params}`);

export const uploadStudentDocument = ({ data }) => http.post(`${API_STUDENTS_PATH}/documents`, data);

export const sendFeedback = ({ data }) => http.post(`${API_VERSION_PATH}/feedbacks`, data);

export const getFeedbackInfo = ({ data, params = '' }) => http.get(`${API_VERSION_PATH}/feedbacks?${params}`, data);

export const getStudentComments = ({ params = '' }) => http.get(`${API_STUDENTS_PATH}/comments?${params}`);

export const addStudentNote = ({ data }) => http.post(`${API_STUDENTS_PATH}/comments`, data);

export const getStudentGoals = ({ params = '' }) => http.get(`${API_STUDENTS_PATH}/goals/?${params}`);

export const addStudentGoal = ({ data }) => http.post(`${API_STUDENTS_PATH}/goals`, data);

export const trackActivityClick = ({ data }) => http.post(`${API_VERSION_PATH}/activity/click`, data);

export const trackHitActivity = ({ data }) => http.post(`${API_VERSION_PATH}/activity/hit`, data);

export const trackActivityVideo = ({ data }) => http.post(`${API_VERSION_PATH}/activity/video`, data);

export const getStudents = ({ params }) => http.get(`${API_STUDENTS_PATH}?${params}`);
