import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParams from '../../hooks/useQueryParams';
import path from '../../routes/path';
import { sendAmplitudeData } from '../../utils/amplitude';
import { setMeetingUrl } from '../../store/userSlice';
import TLFullScreenLoader from '../../components/common/TL-FullScreenLoader';
import { getMeetingUrl } from '../../services/api/student/authService';
import { setError } from '../../store/generalSlice';
import { isDevelopment } from '../../utils/utils';

function JoinMeetPage() {
  const query = useQueryParams();
  const id = query.get('id');
  const type = query.get('type');
  const [meetingData, setMeetingData] = useState({
    provider: '',
    meetingUrl: '',
    newTab: false,
    redirect: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const error = useSelector((state) => state.general.error);

  useEffect(() => {
    if (id && type) {
      const getMeetingUrlFromId = async () => {
        setIsLoading(true);
        dispatch(
          setError({
            hasError: false,
            message: null,
            variant: '',
            title: '',
          })
        );
        try {
          const meetingRes = await getMeetingUrl({
            event_id: id,
            type,
          });

          const resData = meetingRes.data;

          const { provider, meeting_url, new_tab } = resData.data;
          if (provider === 'whereby') {
            sendAmplitudeData('WhereBy Meeting');
            dispatch(setMeetingUrl(meeting_url));
            setMeetingData({
              provider: 'whereby',
              meetingUrl: '/student/whereby',
              newTab: false,
              redirect: true,
            });
          } else if (provider === 'zoom') {
            sendAmplitudeData('Zoom Meeting');
            window.open(meeting_url, new_tab ? '_blank' : '_self');
          }
        } catch (error) {
          dispatch(
            setError({
              hasError: true,
              message: error?.response?.data?.message || 'Something went wrong.',
              variant: 'danger',
              title: 'Failed to join meeting',
            })
          );
          if (isDevelopment) {
            // eslint-disable-next-line no-console
            console.log('err ', error);
          }
        } finally {
          setIsLoading(false);
        }
      };

      getMeetingUrlFromId();
    }
  }, [dispatch, id, type]);

  if (isLoading) {
    return <TLFullScreenLoader />;
  }

  if (!id || !type || error.hasError) {
    return <Redirect to={path.student.index} />;
  }

  if (meetingData.redirect && meetingData.meetingUrl) {
    return <Redirect to={meetingData.meetingUrl} target={meetingData.newTab ? '_blank' : '_self'} />;
  }
}

export default JoinMeetPage;
