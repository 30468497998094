import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setLayoutContainerClassName } from '../store/themeSlice';

export default function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setLayoutContainerClassName(''));
  }, [dispatch, pathname]);

  return children;
}
