import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Datatable from '../../components/student/common/Datatable';
import { frequency } from '../../services/api/student/authService';
import { sendAmplitudeData } from '../../utils/amplitude';
import { APP_NAME } from '../../utils/constants/AppConstant';
import { GetActivity } from '../../utils/student';

const Frequency = () => {
  useEffect(() => {
    GetActivity(window.location.pathname);
    sendAmplitudeData('Frequency');
  }, []);

  return (
    <>
      <Helmet title={`Frequency - Student - ${APP_NAME}`}>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container>
        <h4 className="d-block d-md-none mg-b-15">Frequency</h4>

        <Datatable apiMethod={frequency} noDataMessage="No frequencies assigned to you" />
      </Container>
    </>
  );
};

export default Frequency;
