import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import classNames from 'classnames';

function TLTooltip({
  placement = 'top',
  tooltip = '',
  children,
  icon = 'info',
  className = '',
  trigger,
  parentElementClassName = '',
}) {
  if (!tooltip) {
    return children;
  }
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip>{tooltip}</Tooltip>} trigger={trigger || ['hover', '']}>
      {children || (
        <div className={`tl-cursor-pointer ${parentElementClassName}`}>
          <FeatherIcon preserveAspectRatio="none" icon={icon} className={classNames('wd-15 ht-15', className)} />
        </div>
      )}
    </OverlayTrigger>
  );
}

export default TLTooltip;
