import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const ProgressChart = (props) => {
  const Data = props.data;
  const OptionsYTitle = props['y-axis'];
  const OptionsXTitle = props['x-axis'];

  const labels = Data.slice(1).map((x) => x[0]);
  const options = {
    elements: {
      point: {
        align: 'end',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label(tooltip) {
            return `${tooltip.dataset.label}: ${tooltip.raw}%`;
          },
        },
        titleFont: {
          size: 16,
          family: 'Arial',
        },
        bodyFont: {
          size: 14,
          family: 'Arial',
        },
        footerFont: {
          size: 14,
          family: 'Arial',
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: OptionsXTitle,
          font: {
            size: 14,
            family: 'Arial',
          },
        },
        ticks: {
          font: {
            size: 12,
            family: 'Arial',
            weight: 'normal',
          },
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: OptionsYTitle,
          font: {
            size: 14,
            family: 'Arial',
          },
        },
        ticks: {
          stepSize: 20,
          font: {
            size: 12,
            family: 'Arial',
            weight: 'normal',
          },
        },
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        label: Data[0][1],
        data: Data.slice(1).map((x) => x[1]),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return <Line className="graph" height={450} options={options} data={data} />;
};

export default ProgressChart;
