import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Skeleton from 'react-loading-skeleton';

const ProgressChartSkeleton = () => (
  <>
    <Card className="mg-b-20 mg-lg-b-25">
      <Card.Header className="pd-y-10 pd-x-10 pd-md-x-15 bg-gray-100">
        <Row className="align-items-center justify-content-between">
          <Col md={4}>
            <Skeleton className="ht-20" />
          </Col>
          <Col md={6} />
          <Col md={2}>
            <Skeleton className="ht-40" />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="pd-10 pd-lg-15">
        <Row>
          <Col md={12}>
            <Skeleton count={10} className="ht-5" />
          </Col>
        </Row>
      </Card.Body>
    </Card>
    <Card className="mg-b-20 mg-lg-b-25">
      <Card.Header className="pd-y-10 pd-x-10 pd-md-x-15 bg-gray-100">
        <Row className="align-items-center justify-content-between">
          <Col md={4}>
            <Skeleton className="ht-20" />
          </Col>
          <Col md={6} />
          <Col md={2}>
            <Skeleton className="ht-40" />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="pd-10 pd-lg-15">
        <Row>
          <Col md={12}>
            <Skeleton count={10} className="ht-5" />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </>
);

export default ProgressChartSkeleton;
