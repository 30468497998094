import { createSlice } from '@reduxjs/toolkit';

const themeFont = {
  normal: 'font-normal',
  large: 'font-large',
  extraLarge: 'font-extra-large',
};

const reducers = {
  toggleTheme: (theme, action) => {
    theme[action.payload].darkMode = !theme[action.payload].darkMode;
    setTheme(theme, action);
  },
  getMode: (theme, action) => theme[action.payload].darkMode,
  setThemeMode: setTheme,
  setThemeFontNormal: (theme, action) => {
    theme[action.payload].fontSize = themeFont.normal;
    setThemeFont(theme, action);
  },
  setThemeFontLarge: (theme, action) => {
    theme[action.payload].fontSize = themeFont.large;
    setThemeFont(theme, action);
  },
  setThemeFontExtraLarge: (theme, action) => {
    theme[action.payload].fontSize = themeFont.extraLarge;
    setThemeFont(theme, action);
  },
  setLayoutContainerClassName: (theme, action) => {
    theme.layoutContainerClassName = action.payload;
  },
};

export const slice = createSlice({
  name: 'theme',
  initialState: {
    student: {
      darkMode: false,
      fontSize: themeFont.normal,
    },
    school: {
      darkMode: false,
      fontSize: themeFont.normal,
    },
  },
  reducers,
});

export const {
  setLayoutContainerClassName,
  toggleTheme,
  setThemeMode,
  setThemeFontNormal,
  setThemeFontLarge,
  setThemeFontExtraLarge,
} = slice.actions;

export default slice.reducer;

function setTheme(theme, action) {
  setThemeFont(theme, action);
  if (theme[action.payload].darkMode) document.body.classList.add('contrast-mode');
  else document.body.classList.remove('contrast-mode');
}
function setThemeFont(theme, action) {
  document.body.classList.remove(themeFont.normal);
  document.body.classList.remove(themeFont.large);
  document.body.classList.remove(themeFont.extraLarge);
  document.body.classList.add(theme[action.payload].fontSize);
}
