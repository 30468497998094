import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import validator from 'validator';
import Keyboard from 'react-simple-keyboard';
import KeyboardImg from '../../../assets/images/keyboard.svg';
import 'react-simple-keyboard/build/css/index.css';
import { updateStudentInfo } from '../../../services/api/student/authService';

const useOutsideClick = (keyboardRef, userRef, dobRef, parentRef, phoneRef, callback) => {
  useEffect(() => {
    const onMouseDown = (e) => {
      if (keyboardRef.current) {
        if (!keyboardRef.current.contains(e.target) && userRef.current && !userRef.current.contains(e.target)) {
          callback(false);
        } else if (!keyboardRef.current.contains(e.target) && dobRef.current && !dobRef.current.contains(e.target)) {
          callback(false);
        } else if (
          !keyboardRef.current.contains(e.target) &&
          parentRef.current &&
          !parentRef.current.contains(e.target)
        ) {
          callback(false);
        } else if (
          !keyboardRef.current.contains(e.target) &&
          phoneRef.current &&
          !phoneRef.current.contains(e.target)
        ) {
          callback(false);
        } else if (!keyboardRef.current.contains(e.target)) {
          callback(false);
        }
      }
    };
    document.addEventListener('mousedown', onMouseDown);

    return () => document.removeEventListener('mousedown', onMouseDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboardRef, userRef, dobRef, parentRef, phoneRef]);
};

const StudentConsentForm = ({ setGetStudentDetail }) => {
  const [loginLoading, setLoginLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [activeField, setActiveField] = useState('firstName');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [monthError, setMonthError] = useState('');
  const [dayError, setDayError] = useState('');
  const [yearError, setYearError] = useState('');
  const [apiError, setApiError] = useState('');

  const [inputs, setInputs] = useState([]);
  const [inputName, setInputName] = useState('shift');
  const [layout, setLayout] = useState('default');
  const keyboard = useRef();
  const keyboardRef = useRef(null);
  const firstNameFocus = useRef(null);
  const lastNameFocus = useRef(null);
  const emailFocus = useRef(null);
  const phoneFocus = useRef(null);

  const [showKeyboard, setShowKeyboard] = useState(false);
  const [pattern, setPattern] = useState('');
  const [maxLength, setMaxLength] = useState('');

  useOutsideClick(keyboardRef, firstNameFocus, lastNameFocus, emailFocus, phoneFocus, setShowKeyboard);

  useEffect(() => {
    setTimeout(() => {
      firstNameFocus.current.focus();
    }, [firstName]);
  }, [firstName]);

  useEffect(() => {
    if (inputs.firstName !== undefined) {
      setFirstName(inputs.firstName);
    }
    if (inputs.lastName !== undefined) {
      setLastName(inputs.lastName);
    }
    if (inputs.email !== undefined) {
      setEmail(inputs.email);
    }
    if (inputs.phone !== undefined) {
      setPhone(inputs.phone);
    }
  }, [inputs]);

  useEffect(() => {
    if (month !== undefined) {
      setMonth(month);
    }
    if (day !== undefined) {
      setDay(day);
    }
    if (year !== undefined) {
      setYear(year);
    }
  }, [month, day, year]);

  function FirstNameCall() {
    setActiveField('firstName');
    firstNameFocus.current.focus();
    setShowKeyboard((prevState) => !prevState);
    setPattern();
    setMaxLength();
  }

  function LastNameCall() {
    setActiveField('lastName');
    lastNameFocus.current.focus();
    setShowKeyboard((prevState) => !prevState);
    setPattern();
    setMaxLength();
  }

  function EmailCall() {
    setActiveField('email');
    emailFocus.current.focus();
    setShowKeyboard((prevState) => !prevState);
    setPattern();
    setMaxLength();
  }

  function PhoneCall() {
    setActiveField('phone');
    phoneFocus.current.focus();
    setShowKeyboard((prevState) => !prevState);
    setPattern();
    setMaxLength();
  }

  const onChangeAll = (value) => {
    const keyInput = Object.keys(value)[0];
    setInputs((prevState) => ({
      ...prevState,
      [keyInput]: value[keyInput],
    }));
  };

  const onKeyPress = (button) => {
    if (button === '{shift}' || button === '{lock}') handleShift();
  };

  const handleShift = () => {
    const newLayoutName = layout === 'default' ? 'shift' : 'default';
    setLayout(newLayoutName);
  };

  const FirstNameInput = (event) => {
    if (event.target.value.length < 0) return;
    const inputVal = event.target.value.replace(/[^a-z]/gi, '');

    setInputs((value) => ({
      ...value,
      [inputName]: inputVal,
    }));
  };

  const LastNameInput = (event) => {
    if (event.target.value.length < 0) return;
    const inputVal = event.target.value.replace(/[^a-z]/gi, '');

    setInputs((value) => ({
      ...value,
      [inputName]: inputVal,
    }));
  };

  const EmailInput = (event) => {
    if (event.target.value.length < 0) return;
    const inputVal = event.target.value;

    setInputs((value) => ({
      ...value,
      [inputName]: inputVal,
    }));
  };

  const PhoneInput = (event) => {
    if (event.target.value.length > 10) return;
    const inputVal = event.target.value.replace(/\D/g, '');

    setInputs((value) => ({
      ...value,
      [inputName]: inputVal,
    }));
  };

  const getInputValue = (value) => inputs[value] || '';
  const predefineMonth = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const predefineMonthNumeric = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const predefineDay = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
  ];
  const predefineYear = [];
  const currentYear = new Date().getFullYear();
  for (let i = 1990; i <= currentYear - 2; i++) {
    predefineYear.push(i);
  }

  const resetErrors = () => {
    setFirstNameError('');
    setLastNameError('');
    setEmailError('');
    setPhoneError('');
    setMonthError('');
    setDayError('');
    setYearError('');
    setApiError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isError = false;
    resetErrors();
    setLoginLoading(true);
    try {
      if (validator.isEmpty(firstName)) {
        setFirstNameError('First Name is required ');
        isError = true;
      }

      if (validator.isEmpty(lastName)) {
        setLastNameError('Last Name is required');
        isError = true;
      }

      if (validator.isEmpty(email)) {
        setEmailError('Please enter valid email address');
        isError = true;
      }
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setEmailError('Please enter valid email address');
        isError = true;
      }

      if (validator.isEmpty(phone)) {
        setPhoneError('Phone number is required');
        isError = true;
      }

      if (validator.isEmpty(month)) {
        setMonthError('Month is required');
        isError = true;
      }

      if (validator.isEmpty(day)) {
        setDayError('Day is required');
        isError = true;
      }

      if (validator.isEmpty(year)) {
        setYearError('Year is required');
        isError = true;
      }

      if (isError) return;

      const dob = `${year}-${month}-${day}`;
      const data = {
        pfname: firstName,
        plname: lastName,
        email,
        phone,
        dob,
      };
      const res = await updateStudentInfo(data);
      if (res.status === 200) {
        setGetStudentDetail(false);
      }
    } catch (error) {
      const { errors } = error.response.data;
      if (errors && Object.keys(errors).length > 0) {
        // get first error
        const firstError = Object.keys(errors)[0];
        const firstErrorValue = errors[firstError];
        setApiError(firstErrorValue[0]);
      } else {
        setApiError(error?.response?.data?.message || 'Something went wrong');
      }
    } finally {
      setLoginLoading(false);
    }
  };

  return (
    <Form id="student_consent_form" name="student_consent_form">
      <div className="pos-relative">
        <Form.Group className="mb-3">
          <InputGroup className="mb-0">
            <FormControl
              type="text"
              placeholder="Parent First Name*"
              autoComplete="off"
              onChange={FirstNameInput}
              ref={firstNameFocus}
              value={getInputValue('firstName')}
              onFocus={() => {
                setInputName('firstName');
                setPattern(/^[a-zA-Z]+$/);
                setMaxLength();
              }}
            />
            <div className="input-group-append">
              <span onClick={() => FirstNameCall()} className="input-group-text">
                <img className="pos-relative t--3" src={KeyboardImg} alt="keyboard icn" />
              </span>
            </div>
          </InputGroup>
          <Form.Text className="tx-13 tx-danger">{firstNameError}</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3">
          <InputGroup className="mb-0">
            <FormControl
              type="text"
              placeholder="Parent Last Name*"
              autoComplete="off"
              onChange={LastNameInput}
              ref={lastNameFocus}
              value={getInputValue('lastName')}
              onFocus={() => {
                setInputName('lastName');
                setPattern(/^[a-zA-Z]+$/);
                setMaxLength();
              }}
            />
            <div className="input-group-append">
              <span onClick={() => LastNameCall()} className="input-group-text">
                <img className="pos-relative t--3" src={KeyboardImg} alt="keyboard icon" />
              </span>
            </div>
          </InputGroup>
          <Form.Text className="tx-13 tx-danger">{lastNameError}</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="parent_email">
          <InputGroup className="mb-0">
            <FormControl
              placeholder="Parent Email*"
              autoComplete="off"
              onChange={EmailInput}
              ref={emailFocus}
              value={getInputValue('email')}
              onFocus={() => {
                setInputName('email');
                setPattern();
                setMaxLength();
              }}
              type="email"
            />
            <div className="input-group-append">
              <span onClick={() => EmailCall()} className="input-group-text">
                <img className="pos-relative t--3" src={KeyboardImg} alt="keyboard icon" />
              </span>
            </div>
          </InputGroup>
          <Form.Text className="tx-13 tx-danger">{emailError}</Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="parent_phone">
          <InputGroup className="mb-0">
            <FormControl
              placeholder="Parent Phone*"
              autoComplete="off"
              onChange={PhoneInput}
              ref={phoneFocus}
              value={getInputValue('phone')}
              onFocus={() => {
                setInputName('phone');
                setPattern(/^[0-9\b]+$/);
                setMaxLength();
              }}
              type="text"
              min={0}
              max={10}
            />
            <div className="input-group-append">
              <span onClick={() => PhoneCall()} className="input-group-text">
                <img className="pos-relative t--3" src={KeyboardImg} alt="keyboard icon" />
              </span>
            </div>
          </InputGroup>
          <Form.Text className="tx-13 tx-danger mg-t-0">{phoneError}</Form.Text>
        </Form.Group>
        {showKeyboard && (
          <div ref={keyboardRef} className="pos-md-absolute z-index-200 wd-100p">
            <Keyboard
              keyboardRef={(r) => (keyboard.current = r)}
              onChangeAll={onChangeAll}
              onKeyPress={onKeyPress}
              layoutName={layout}
              inputName={inputName}
              inputPattern={pattern}
              maxLength={maxLength}
              onInit={(kb) => kb.setInput(inputs[activeField])}
            />
            <Button className="btn-xs btn-dark float-right" onClick={() => setShowKeyboard(false)}>
              Close
            </Button>
          </div>
        )}
      </div>
      <Form.Label>
        Student Date of Birth<sup>*</sup>
      </Form.Label>
      <Row className="mb-3 row-xs">
        <Form.Group as={Col}>
          <Form.Control
            as="select"
            defaultValue="Month"
            className="form-control"
            onChange={(e) => setMonth(e.target.value)}
          >
            <option>Month</option>
            {predefineMonth.map((item, index) => (
              <option key={index} value={predefineMonthNumeric[index]}>
                {item}
              </option>
            ))}
          </Form.Control>
          <Form.Text className="tx-13 tx-danger mg-t-0">{monthError}</Form.Text>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Control
            as="select"
            defaultValue="Day"
            className="form-control"
            onChange={(e) => setDay(e.target.value)}
          >
            <option>Day</option>
            {predefineDay.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </Form.Control>
          <Form.Text className="tx-13 tx-danger mg-t-0">{dayError}</Form.Text>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Control
            as="select"
            defaultValue="Year"
            className="form-control"
            onChange={(e) => setYear(e.target.value)}
          >
            <option>Year</option>
            {predefineYear.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </Form.Control>
          <Form.Text className="tx-13 tx-danger mg-t-0">{yearError}</Form.Text>
        </Form.Group>
      </Row>
      <div className="invalid-feedback tx-danger mg-b-0 mg-t-0 tx-13 text-center d-block">{apiError}</div>
      <div className="text-center mg-t-15">
        <button className="btn-purple btn-block btn" disabled={loginLoading} onClick={(e) => handleSubmit(e)}>
          Submit
        </button>
      </div>
    </Form>
  );
};

export default StudentConsentForm;
