/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import Student from '../screens/student';
import path from './path';
import Therapist from '../screens/therapist';
import useUrlParams from '../hooks/useUrlParams';
import { trackHitActivity } from '../services/api/student/authService';
import { USER_TYPES } from '../utils/constants/AppConstant';

const Login = React.lazy(() => import('../screens/shared/login/Login'));
const ForgetPasswordPage = React.lazy(() => import('../screens/shared/forgetPassword/ForgetPassword'));
const ResetPasswordPage = React.lazy(() => import('../screens/shared/resetPassword/ResetPassword'));
const StudentCssLoader = React.lazy(() => import('../components/common/ImportStudentCss'));
const V2CssLoader = React.lazy(() => import('../components/common/ImportV2Css'));

// Create a component that will determine and load the CSS based on the current route
const CssLoaderComponent = () => {
  const location = useLocation();
  const isStudentSystem = () => location.pathname.startsWith('/student');

  // Determine which CSS to load based on the current route
  const CssLoader = isStudentSystem() ? StudentCssLoader : V2CssLoader;

  return <CssLoader />;
};

export default function Routes() {
  return (
    <BrowserRouter>
      <React.Suspense fallback="">
        {/* Load the CSS based on the current route */}
        <CssLoaderComponent />
        <AllRoutes />
      </React.Suspense>
    </BrowserRouter>
  );
}

const AllRoutes = () => {
  const params = useUrlParams();
  const location = useLocation();
  const user = useSelector((state) => state?.user[0]);
  const [prevLocation, setPrevLocation] = useState('');

  useEffect(() => {
    if (params?.sentryError) {
      throw new Error('Sentry Error');
    }
  }, [params?.sentryError]);

  useEffect(() => {
    Sentry?.setUser({
      path: location.pathname,
      id: user?.details?.id,
      name: user?.details?.name,
      email: user?.details?.email,
    });
  }, [location.pathname, user?.details]);

  useEffect(() => {
    if (user?.loggedIn && user?.details?.role?.id === USER_TYPES.therapist) {
      // Update the previous location state with the current location when the component mounts
      setPrevLocation(window.location.href);

      (async () => {
        try {
          const saveData = {
            page: window.location.href,
            ref: prevLocation,
          };

          await trackHitActivity({
            data: saveData,
          });
        } catch (error) {
          // don't do anything
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user?.loggedIn]);

  return (
    <Switch>
      <Route path={path.student.index} component={Student} />
      <Route path={path.therapist.index} component={Therapist} />
      <Route path={path.login} component={Login} />
      <Route path={path.forgetPassword} component={ForgetPasswordPage} />
      <Route path={path.ResetPassword} component={ResetPasswordPage} />
      <Redirect from={path.root} to={path.login} />
    </Switch>
  );
};
