import React from 'react';
import Card from 'react-bootstrap/Card';
import FeatherIcon from 'feather-icons-react';

const SectionBox = ({ Title, Icon, HomeData, Button }) => (
  <Card className="mg-b-20 mg-lg-b-25">
    <Card.Header className="pd-y-10 pd-x-10 pd-md-x-15 d-block d-md-flex align-items-center justify-content-between bg-gray-100">
      <Card.Text className="tx-bold mg-b-0 d-flex align-items-center">
        {Icon && (
          <span className="wd-30 ht-30 bg-green tx-white d-inline-flex justify-content-center align-items-center pd-6 text-center rounded mg-r-10 section-icon">
            <FeatherIcon icon={Icon} />
          </span>
        )}
        <span className="tx-14 tx-md-14 tx-lg-16">{Title}</span>
      </Card.Text>
      {Button && Button}
    </Card.Header>
    <Card.Body className="pd-10 pd-lg-15">{HomeData}</Card.Body>
  </Card>
);

export default SectionBox;
