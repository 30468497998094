import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function TLModal({ title, data, size, footer, showCloseButton = true, headerContent, ...rest }) {
  return (
    <Modal {...rest} size={size} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header
        closeButton={showCloseButton}
        className={`d-md-flex d-block align-items-center ${!title && 'noTitle pd-0'}`}
      >
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        {headerContent}
      </Modal.Header>
      <Modal.Body>{data}</Modal.Body>
      {footer && (
        <Modal.Footer>
          <Button onClick={rest.onHide}>Close</Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default TLModal;
