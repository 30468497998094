import React from 'react';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Nav } from 'react-bootstrap';

const Schedule = () => (
  <Row>
    <Col lg={4} md={4} sm={6} xs={12} className="mg-t-5 mg-b-5">
      <Nav.Link
        href="https://global.gotomeeting.com/join/288745269"
        target="_blank"
        className="d-block pd-0 schedule-box"
      >
        <Card className="text-center media pd-10 bd bd-2 tx-bold align-items-center justify-content-center pos-relative ht-100p tx-color-02 bg-gray-100 rounded schedule-box">
          <Card.Body className="pd-0">
            <Card.Text>
              <span className="room-box text-center tx-15 pd-15">
                <span className="d-block tx-18 tx-bold">Meet Diana</span>
              </span>
            </Card.Text>
          </Card.Body>
        </Card>
      </Nav.Link>
    </Col>
  </Row>
);

export default Schedule;
