import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import TLModal from '../../common/Modal';
import { intakeGetConcerns, submitIntake } from '../../../../services/api/student/authService';

const yesNoChoices = [
  {
    text: 'Yes',
    value: 1,
  },
  {
    text: 'No',
    value: 0,
  },
];

const IntakeGenEdForm = ({ message, onSuccess }) => {
  const [modalShow, setModalShow] = useState(false);
  const [questions, setQuestions] = useState({
    parent_concerns: {
      question: 'What concerns do you have about your student?',
      answer: '',
      required: true,
      type: 'text',
    },
    school_concerns: {
      question: 'What concerns does the school have about your student?',
      answer: '',
      required: true,
      type: 'text',
    },
    has_concerned: {
      question: 'Has your student experienced this concern previously?',
      answer: '',
      required: true,
      type: 'choice',
      choices: yesNoChoices,
    },
    counseling_previously: {
      question: 'Has your student ever received counseling or mental health support previously?',
      answer: '',
      required: true,
      type: 'choice',
      choices: yesNoChoices,
    },
    counseling_details: {
      question:
        'If yes, please provide further details regarding length and type of treatment and date of last treatment (if your student is currently in counseling please write “current” and briefly describe what they are working on with their current counselor) below.',
      answer: '',
      required: false,
      type: 'text',
    },
    concerns: {
      question: 'Please check any current concerns: (check all that apply).',
      answer: [],
      required: false,
      type: 'choices',
      choices: [],
    },
    current_medications: {
      question: 'What medication(s) is your student currently using? If none, please type none',
      answer: '',
      required: true,
      type: 'text',
    },
    medical_history_diagnoses: {
      question: 'Previous diagnoses/mental health treatment',
      answer: '',
      required: false,
      type: 'text',
    },
    medical_history_conditions: {
      question: 'Previous medical conditions',
      answer: '',
      required: false,
      type: 'text',
    },
    medical_history_surgeries: {
      question: 'Previous surgeries',
      answer: '',
      required: false,
      type: 'text',
    },
    involuntarily_hospitalized: {
      question: 'Has your student ever been involuntarily hospitalized due to safety concerns?',
      answer: '',
      required: true,
      type: 'choice',
      choices: yesNoChoices,
    },
    hospitalized_details: {
      question: 'If yes, please explain below',
      answer: '',
      required: false,
      type: 'text',
    },
    family_resides_in_home: {
      question: 'Who all resides in the home with the student? Please include ages for any siblings.',
      answer: '',
      required: true,
      type: 'text',
    },
    family_family_relation: {
      question: 'How does the student get along with members of the household?',
      answer: '',
      required: true,
      type: 'text',
    },
    family_mental_concerns_history: {
      question: 'Is there any history of family mental health concerns?',
      answer: '',
      required: false,
      type: 'text',
    },
    school_attending_duration: {
      question: 'How long has the student been attending their current school?',
      answer: '',
      required: true,
      type: 'text',
    },
    school_attendance: {
      question: 'How is the student’s attendance?',
      answer: '',
      required: true,
      type: 'choice',
      choices: [
        {
          text: 'Excellent',
          value: 4,
        },
        {
          text: 'Good',
          value: 3,
        },
        {
          text: 'Fair',
          value: 2,
        },
        {
          text: 'Poor',
          value: 1,
        },
      ],
    },
    school_grades: {
      question: 'How are the student’s grades currently?',
      answer: '',
      required: true,
      type: 'choice',
      choices: [
        {
          text: 'Excellent',
          value: 4,
        },
        {
          text: 'Good',
          value: 3,
        },
        {
          text: 'Fair',
          value: 2,
        },
        {
          text: 'Poor',
          value: 1,
        },
      ],
    },
    has_discipline_concerns_in_3_months: {
      question: 'Has the student had any discipline concerns in the past 3 months?',
      answer: '',
      required: true,
      type: 'choice',
      choices: yesNoChoices,
    },
    discipline_details: {
      question: 'If yes, please explain below',
      answer: '',
      required: false,
      type: 'text',
    },
    school_related_concerns: {
      question: 'Do you have any other school-related concerns?',
      answer: '',
      required: false,
      type: 'text',
    },
    has_student_join_groups_extracurriculars: {
      question: 'Is your student involved in any student groups/extracurricular activities?',
      answer: '',
      required: true,
      type: 'choice',
      choices: yesNoChoices,
    },
    extracurriculars_details: {
      question: 'If yes, please explain below',
      answer: '',
      required: false,
      type: 'text',
    },
    has_join_religion_group: {
      question: 'Is your student a member of a religion/spiritual group?',
      answer: '',
      required: true,
      type: 'choice',
      choices: yesNoChoices,
    },
    religion_group_details: {
      question: 'If yes, please explain below',
      answer: '',
      required: false,
      type: 'text',
    },
    has_identify_with_ethnicity: {
      question: 'Does your student and/or your family identify with any culture or ethnicity',
      answer: '',
      required: true,
      type: 'choice',
      choices: yesNoChoices,
    },
    ethnicity_identify_details: {
      question: 'If yes, please explain below',
      answer: '',
      required: false,
      type: 'text',
    },
    has_student_arrested: {
      question: 'Has your student ever been arrested?',
      answer: '',
      required: true,
      type: 'choice',
      choices: yesNoChoices,
    },
    arrest_details: {
      question: 'If yes, please explain below',
      answer: '',
      required: false,
      type: 'text',
    },
    student_friends_peers: {
      question: 'Does your student have close friends and get along well with peers?',
      answer: '',
      required: true,
      type: 'choice',
      choices: yesNoChoices,
    },
    student_hobby: {
      question: 'What are your student’s favorite hobbies?',
      answer: '',
      required: true,
      type: 'text',
    },
    more_details: {
      question: 'Is there anything else we should know about your student or family?',
      answer: '',
      required: false,
      type: 'text',
    },
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const res = await intakeGetConcerns();
        const { concerns } = questions;
        concerns.choices = res.data.map((c) => ({
          text: c.name,
          value: c.id,
        }));
        setQuestions({
          ...questions,
          concerns,
        });
      } catch (e) {
        toast.error('Failed to get concerns list');
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalHide = () => {
    setModalShow(false);
  };

  const handleChange = (key, value) => {
    const newQuestions = { ...questions };
    newQuestions[key].answer = value;
    setQuestions(newQuestions);
  };

  const validate = () => {
    setErrorMessage({});
    const newErrorMessage = {};
    Object.keys(questions).forEach((key) => {
      const { answer, required } = questions[key];
      if (required && (!answer || answer?.length === 0)) {
        newErrorMessage[key] = 'This field is required';
      }
    });
    setErrorMessage(newErrorMessage);
    return Object.keys(newErrorMessage).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      toast.dismiss();
      toast.error('Please fill all the required fields', {
        autoClose: 3000,
      });
      return;
    }

    const details = {};
    Object.keys(questions).forEach((key) => {
      const question = questions[key];
      details[key] = question.answer;
    });

    try {
      setLoading(true);
      toast.dismiss();
      toast.loading('Submitting...', {
        toastId: 'loading',
      });
      const res = await submitIntake(details);
      toast.success(res?.data?.message || 'Thank you for completing intake form.', {
        autoClose: 2000,
      });
      onSuccess && onSuccess();
      modalHide();
    } catch (err) {
      setErrorMessage(err?.response?.data?.errors || {});
      toast.error(err?.response?.data?.message || 'Failed to submit intake form');
    } finally {
      toast.dismiss('loading');
      setLoading(false);
    }
  };

  return (
    <>
      <Card.Text className="mg-b-15 text-center tx-bold tx-20 d-block">
        {message ||
          'Please complete this intake form for your student/yourself. This form helps us learn more about your family and helps us create goals to work on together.'}
      </Card.Text>
      <Card.Text className="btn-box text-center">
        <Button className="btn btn-outline-yellow" onClick={() => setModalShow(true)}>
          Intake Form
        </Button>
      </Card.Text>
      <TLModal
        show={modalShow}
        onHide={modalHide}
        title="Intake Form"
        data={
          <form onSubmit={handleSubmit}>
            <div className="step-cont tl-max-h-500-i">
              {Object.keys(questions).map((key, index) => {
                const { question, answer, required, type, choices } = questions[key];

                if (type === 'text') {
                  return (
                    <div key={key} className="d-flex flex-column mg-b-8">
                      <label
                        htmlFor={key}
                        className={classNames({
                          'input-required': required,
                        })}
                      >
                        {index + 1}. {question}
                      </label>
                      <textarea
                        type="text"
                        id={key}
                        required={required}
                        onChange={(e) => handleChange(key, e.target.value)}
                        placeholder="Please enter your response here"
                      />
                      <div className="text-danger">{errorMessage[key] && errorMessage[key]}</div>
                    </div>
                  );
                }

                if (type === 'choice') {
                  return (
                    <div className="mg-b-8">
                      <div key={key} className="d-flex flex-column">
                        <label
                          className={classNames('mg-r-5 mg-b-0', {
                            'input-required': required,
                          })}
                        >
                          {index + 1}. {question}
                        </label>
                        <div className="d-flex">
                          {choices.map((choice) => {
                            const { text, value } = choice;
                            return (
                              <div key={value} className="mg-r-15 d-flex align-items-center">
                                <input
                                  type="radio"
                                  id={`${key}-${value}`}
                                  name={key}
                                  value={value}
                                  className="mg-r-5"
                                  onChange={(e) => handleChange(key, e.target.value)}
                                />
                                <label htmlFor={`${key}-${value}`} className="mg-0">
                                  {text}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="text-danger">{errorMessage[key] && errorMessage[key]}</div>
                    </div>
                  );
                }

                if (type === 'choices') {
                  return (
                    <div key={key} className="d-flex flex-column mg-b-8">
                      <label
                        className={classNames({
                          'input-required': required,
                        })}
                      >
                        {index + 1}. {question}
                      </label>
                      <div className="d-flex flex-wrap">
                        {choices.map((choice) => {
                          const { text, value } = choice;
                          return (
                            <div key={value} className="mg-r-15 d-flex">
                              <input
                                type="checkbox"
                                id={`${key}-${value}`}
                                name={key}
                                value={value}
                                className="mg-r-5"
                                onChange={(e) => {
                                  const val = e.target.value;
                                  const newAnswer = [...answer];
                                  if (newAnswer.includes(val)) {
                                    newAnswer.splice(newAnswer.indexOf(val), 1);
                                  } else {
                                    newAnswer.push(val);
                                  }
                                  handleChange(key, newAnswer);
                                }}
                              />
                              <label htmlFor={`${key}-${value}`} className="mg-0">
                                {text}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      <div className="text-danger">{errorMessage[key] && errorMessage[key]}</div>
                    </div>
                  );
                }

                return null;
              })}
            </div>
            <div className="tx-center">
              <Button type="submit" className="btn btn-dark" disabled={loading}>
                Complete Intake Form
              </Button>
            </div>
          </form>
        }
        size="lg"
      />
    </>
  );
};

export default IntakeGenEdForm;
