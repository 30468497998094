import { Button, ListGroup, Nav } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import Modal from '../../common/Modal';
import { profileDetails, toggleProfileDetails, backToOldTheme } from '../../../../services/api/student/authService';
import SidebarSkeleton from '../../skeleton/SidebarSkeleton';
import { sendAmplitudeData } from '../../../../utils/amplitude';
import TLGiveFeedbackBtn from '../../../common/TL-GiveFeedbackBtn';

const Sidebar = (props) => {
  const user = useSelector((state) => state.user[0]);
  const userType = user.type;
  const isStaffUser = user?.isStaffUser;
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [backThemeError, setBackThemeError] = useState('');
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [privateProfile, setPrivateProfile] = useState(0);

  const userDetails = {
    id: user?.id,
    name: user?.name,
    type: user?.type,
    page: window.location.pathname,
  };

  const getProfileDetails = async () => {
    try {
      const res = await profileDetails();
      const { data } = res.data;
      setProfile(data);
      setPrivateProfile(data.is_private);
      if (res.status === 200) {
        setLoading(false);
        if (!data.date_of_birth) {
          props.getStudentDetail(1);
        } else {
          props.getStudentDetail(0);
        }
      }
    } catch (_) {
      //
    }
  };
  const getToggleProfile = async (fetchDetails = true) => {
    try {
      await toggleProfileDetails();
    } catch (_) {
      //
    }

    try {
      if (fetchDetails) {
        const res = await profileDetails();
        const { data } = res.data;
        setProfile(data);
      }
    } catch (_) {
      //
    }
  };
  const handlePrivateProfile = async () => {
    setPrivateProfile(!privateProfile);
    try {
      await getToggleProfile();
    } catch (_) {
      //
    }
    sendAmplitudeData('View Detailed Profile');
  };

  const handleHideProfile = async () => {
    setPrivateProfile(!privateProfile);
    try {
      await getToggleProfile(false);
    } catch (_) {
      //
    }
    sendAmplitudeData('Hide Detailed Profile');
  };
  useEffect(() => {
    getProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOldTheme = async () => {
    setLoader(true);
    try {
      const res = await backToOldTheme();
      const { data } = res.data;
      window.open(data.link, '_self');
      setLoader(false);
    } catch (error) {
      setLoader(false);
      setShowModal(true);
      setBackThemeError(error?.response?.data?.message || 'Something went wrong');
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      {loading ? (
        <SidebarSkeleton />
      ) : (
        <>
          {loader && (
            <div className="loader_main">
              <div className="loader">
                <FeatherIcon className="tx-50" icon="loader" />
              </div>
            </div>
          )}
          <Modal
            size="md"
            data={
              backThemeError || (
                <p className="mg-0 tx-16 text-center tx-danger">
                  <FeatherIcon className="tx-80 d-block wd-10 ht-10 mg-l-auto mg-r-auto" icon="alert-triangle" />
                  Something went wrong. Please try again after sometime.
                </p>
              )
            }
            show={showModal}
            onHide={handleClose}
            centered
          />
          <ListGroup className="bg-gray-100 pd-10">
            <ListGroup.Item className="bg-white pd-10 mg-b-0 bd-0 rounded-0">
              <span className="tx-bold mg-r-5">Name:</span>
              <span>{profile.name}</span>
            </ListGroup.Item>
            <ListGroup.Item className="bg-white pd-10 mg-t-5 bd-0 rounded-0">
              <span className="tx-bold mg-r-5">School:</span>
              <span>{profile.school}</span>
            </ListGroup.Item>
            <ListGroup.Item className={`bg-white pd-10 mg-t-5 bd-0 rounded-0"  ${privateProfile ? 'd-none' : ''}`}>
              <span className="tx-bold mg-r-5">Gender:</span>
              <span>{profile && profile.gender}</span>
            </ListGroup.Item>
            <ListGroup.Item className={`bg-white pd-10 mg-t-5 bd-0 rounded-0"  ${privateProfile ? 'd-none' : ''}`}>
              <span className="tx-bold mg-r-5">DOB:</span>
              <span>{profile.date_of_birth}</span>
            </ListGroup.Item>
            {!isStaffUser ? (
              <ListGroup.Item className={`bg-white pd-10 mg-t-5 bd-0 rounded-0"  ${privateProfile ? 'd-none' : ''}`}>
                <span className="tx-bold mg-r-5">Grade:</span>
                <span>{profile && profile.grade}</span>
              </ListGroup.Item>
            ) : null}
          </ListGroup>
          {userType === 1 &&
            (privateProfile ? (
              <Nav.Link className="btn btn-outline-purple mg-t-15 tx-bold btn-block" onClick={handlePrivateProfile}>
                View Detailed Profile
              </Nav.Link>
            ) : (
              <Nav.Link className="btn btn-outline-purple mg-t-15 tx-bold btn-block" onClick={handleHideProfile}>
                Hide Detailed Profile
              </Nav.Link>
            ))}
          <Button className="btn btn-secondary mg-t-15 tx-white btn-block d-none" onClick={handleOldTheme}>
            <FeatherIcon icon="arrow-left" className="mg-r-5" />
            Switch Back To Old Theme
          </Button>
        </>
      )}
      <TLGiveFeedbackBtn userDetails={userDetails} />
    </>
  );
};

export default Sidebar;
