import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import TLModal from '../../../components/common/TL-Modal';
import TLDropDownButton from '../../../components/common/TL-DropDownButton';
import TLInputbox from '../../../components/common/TL-Inputbox';
import Textarea from '../../../components/common/Textarea';
import TLUpload from '../../../components/student/common/UploadBox';
import TLButton from '../../../components/common/TL-Button';
import { setNotification } from '../../../store/generalSlice';
import { isDevelopment } from '../../../utils/utils';
import { shareAresource } from '../../../services/api/therapist';

const ShareResourceModal = ({ showModal, hide, setModalShow }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  const categoryList = [
    {
      key: 1,
      label: 'Speech Therapy Tools',
    },
    {
      key: 2,
      label: 'Occupational Therapy Tools',
    },
    {
      key: 3,
      label: 'Counseling Tools',
    },
    {
      key: 4,
      label: 'Technology Help',
    },
    {
      key: 5,
      label: 'Physical Therapy',
    },
  ];
  const [data, setData] = useState({
    category: '',
    title: '',
    description: '',
    contentUrl: '',
    attachment: {
      file: null,
      name: '',
      size: '',
      link: '',
    },
  });
  const validate = () => {
    let isValid = true;
    const newErrors = {};

    if (data?.category === '') {
      newErrors.category = 'Please select a tool type';
      isValid = false;
    }
    if (data?.description === '') {
      newErrors.description = 'Please enter a tool description';
      isValid = false;
    }
    if (data?.title === '') {
      newErrors.title = 'Please enter a tool title';
      isValid = false;
    }

    setErrors(newErrors);
    return newErrors; // Return the object containing errors
  };

  const handleChange = ({ key, value }) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: '',
    }));
  };

  const resetError = (key) => {
    const temp = { ...errors };
    delete temp[key];
    setErrors(temp);
  };

  const onImageUpload = (e) => {
    resetError('image');
    const image = e.target.files[0];
    const { name, size } = image;

    // create kb or mb size
    const sizeInKb = size / 1024;
    const sizeInMb = sizeInKb / 1024;

    if (image && image.type === 'application/pdf') {
      setData((prev) => ({
        ...prev,
        attachment: {
          ...prev.attachment,
          file: image,
          name,
          size: sizeInKb > 1024 ? `${sizeInMb.toFixed(2)} MB` : `${sizeInKb.toFixed(2)} KB`,
        },
      }));
    } else {
      dispatch(
        setNotification({
          visible: true,
          title: 'Please select a valid PDF file.',
          type: 'error',
          delay: 4000,
        })
      );
    }
    e.target.value = '';
  };

  const onImageRemove = () => {
    setData((prev) => ({
      ...prev,
      attachment: {
        ...prev.attachment,
        file: null,
        name: '',
        size: '',
      },
    }));
  };
  const resetData = () => {
    setData({
      category: '',
      title: '',
      description: '',
      contentUrl: '',
      attachment: {
        file: null,
        name: '',
        size: '',
        link: '',
      },
    });
  };
  const onClose = () => {
    setShow(false);
    setModalShow(false);
    setErrors({});
    resetData();
    hide();
  };
  const handleSave = async (e) => {
    e.preventDefault();
    const errs = validate();

    if (Object.keys(errs).length > 0) {
      setErrors(errs);
      return;
    }

    const { category, title, description, contentUrl, attachment } = data;

    const formData = new FormData();
    formData.append('category', category);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('url', contentUrl);

    if (attachment?.file) {
      formData.append('file', attachment?.file, attachment?.name);
    }
    try {
      toast.loading(`Resource Adding.....`, {
        toastId: 'update',
      });
      setDisabled(true);

      const res = await shareAresource({
        data: formData,
      });

      // Placeholder for onClose function - replace with your logic
      onClose();

      if (res?.status === 201) {
        dispatch(
          setNotification({
            visible: true,
            title: (
              <div>
                <span>{res?.data?.message || 'Tools has been successfully uploaded.'}</span>
                <Link
                  className="mg-l-5 tx-primary"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalShow(true);
                    dispatch(setNotification({ visible: false, type: '', title: '', children: '', delay: 10000 }));
                  }}
                >
                  Share a Another Tools
                </Link>
              </div>
            ),
            type: 'success',
            delay: 5000,
          })
        );
      }
    } catch (error) {
      dispatch(
        setNotification({
          visible: true,
          title: error?.response?.data?.message || 'Something went wrong',
          type: 'error',
          delay: 4000,
        })
      );
      if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.log('err ', error);
      }
    } finally {
      setDisabled(false);
      toast.dismiss('update');
    }
  };

  return (
    <>
      <TLModal title="Share a Resource" size="md" show={showModal} onHide={onClose}>
        <div className="mg-t-0">
          <TLDropDownButton
            required
            searchable
            items={categoryList.map((s) => ({
              key: s.key,
              label: s.label,
            }))}
            label="Select Tool Category"
            defaultLabel="Select tool type"
            size="md"
            disabled={disabled}
            onItemClick={(e) => handleChange({ key: 'category', value: e })}
            error={errors?.category}
          />
        </div>
        <div className="mg-t-15">
          <TLInputbox
            label="Title"
            id="title"
            placeholder="Enter Tool Title"
            required
            disabled={disabled}
            onChange={(e) => handleChange({ key: 'title', value: e.target.value })}
            error={errors?.title}
          />
        </div>
        <div className="mg-t-15">
          <Textarea
            label="Description"
            id="description"
            placeholder="Enter Tool Description"
            required
            disabled={disabled}
            onChange={(e) => handleChange({ key: 'description', value: e.target.value })}
            error={errors?.description}
          />
        </div>
        <div className="mg-t-15">
          <TLInputbox
            label="Content Url"
            id="url"
            disabled={disabled}
            placeholder="Enter Content Url"
            onChange={(e) => handleChange({ key: 'contentUrl', value: e.target.value })}
          />
        </div>
        <div className="mg-t-15">
          <TLUpload
            label="Attachment"
            labelClassName="tx-interui tx-12 tx-uppercase tx-medium"
            onChange={onImageUpload}
            data={data.attachment}
            onDelete={onImageRemove}
            disabled={disabled}
            accept=".pdf"
          />
        </div>
        <div className="mg-t-15 text-center">
          <TLButton label="Submit" size="lg" disabled={disabled} onClick={handleSave} />
        </div>
      </TLModal>
    </>
  );
};

export default ShareResourceModal;
