import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

const reducers = {
  setUser: (user, action) => {
    user[0] = action.payload;
  },
  deleteUser: (user, action) => {
    Sentry?.setUser(null);
    user[0] = action.payload;
    return user;
  },
  getUser: (user) => user[0],
  // set user meeting url
  setMeetingUrl: (user, action) => {
    user[0].meetingUrl = action.payload;
  },
  setUserDetails: (user, action) => {
    user[0].details = action.payload;
  },
};

const InitialState = [];

InitialState.push({ loggedIn: false });
const slice = createSlice({
  name: 'user',
  initialState: InitialState,
  reducers,
});

export const { setUser, deleteUser, getUser, setMeetingUrl, setUserDetails } = slice.actions;

export default slice.reducer;
