/* eslint-disable no-restricted-syntax */
import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import React, { useImperativeHandle, useRef } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { debounce } from '../../utils';

function TLSearchBox(
  {
    onChange,
    placeholder = 'Search...',
    onClick,
    delay,
    className = '',
    isLoading = false,
    defaultValue,
    onClear,
    disabled,
    onInputClick,
    wrapperClassName = '',
    onSearch,
    value,
    noBtn,
    ...rest
  },
  ref
) {
  const inputRef = useRef();
  // create a debounced function
  const debouncedOnChange = debounce(onChange, delay);

  useImperativeHandle(ref, () => ({
    focus: () => {
      // Delaying focus due to issue with asynchronous rendering of the dropdown
      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      }, 100);
    },
    blur: () => {
      setTimeout(() => {
        inputRef.current && inputRef.current.blur();
      }, 100);
    },
  }));

  if (isLoading) {
    return <Skeleton height={40} width={200} />;
  }

  return (
    <div
      className={classNames(
        'with-close-btn search-input pos-relative wd-100p wd-md-auto search-form',
        wrapperClassName,
        {
          'search-input-with-btn': !noBtn,
        }
      )}
    >
      <FormControl
        tabIndex={0}
        disabled={disabled}
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        className={`me-2 form-control ${className} ${noBtn && 'right-border'}`}
        defaultValue={defaultValue}
        value={value}
        aria-label="Search"
        onChange={(e) => {
          // eslint-disable-next-line no-shadow
          const { value } = e.target;
          // check if delay is provided then call the debounced function
          if (delay) {
            debouncedOnChange(value);
          } else {
            onChange && onChange(value);
          }
        }}
        onClick={onInputClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch && onSearch(e.target.value);
          }
        }}
        {...rest}
      />
      {!noBtn && (
        <Button
          className="btn tl-btn-primary search-input-btn"
          type="button"
          onClick={() => {
            onSearch && onSearch(inputRef?.current?.value);
          }}
        >
          <FeatherIcon icon="search" className="d-inline" />
        </Button>
      )}
      <FeatherIcon
        icon="x"
        className={classNames('search-input-clear tl-cursor-pointer pos-absolute wd-15 ht-15', {
          'd-none': !inputRef?.current?.value || !value,
        })}
        onClick={() => {
          onChange && onChange('');
          onClear && onClear();
          if (inputRef?.current?.value) {
            inputRef.current.value = '';
          }
        }}
      />
    </div>
  );
}

export default React.forwardRef(TLSearchBox);
