import React, { useState, useEffect } from 'react';
import FeatherIcon from 'feather-icons-react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { Container } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import html2canvas from 'html2canvas';
import Skeleton from 'react-loading-skeleton';
import { Helmet } from 'react-helmet';
import ProgressChart from '../../components/student/common/ProgressChart';
import { progressDetails } from '../../services/api/student/authService';
import ProgressSkeleton from '../../components/student/skeleton/ProgressSkeleton';
import ProgressChartSkeleton from '../../components/student/skeleton/ProgressChartSkeleton';
import { GetActivity } from '../../utils/student';
import { APP_NAME } from '../../utils/constants/AppConstant';
import { sendAmplitudeData } from '../../utils/amplitude';

const Progress = () => {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState([]);
  const [process, setProcess] = useState([]);
  const [progressLoading, setProgressLoading] = useState(false);
  const [progressChartLoading, setProgressChartLoading] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);

  async function getData() {
    try {
      setProgressLoading(true);
      setProgressChartLoading(true);
      const res = await progressDetails();
      if (res) {
        setData(res.data.data.goals);
        setMessage(res.data.message);
        setProcess(res.data.data);
        setProgressLoading(false);
        setProgressChartLoading(false);
      }
    } catch (_) {
      //
    }
  }

  useEffect(() => {
    getData();
    GetActivity(window.location.pathname);
    sendAmplitudeData('Progress');
  }, []);

  const exportChart = (chartId, chartName) => {
    setDownloadLoader(true);
    const chart = window.document.getElementById(chartId);
    const canBody = chart.getElementsByClassName('card-body')[0];
    const downloadLink = window.document.createElement('a');
    html2canvas(canBody).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      downloadLink.setAttribute('href', imgData);
      const fileName = `${APP_NAME} ${chartName} Goal Progress.png`;
      downloadLink.setAttribute('download', fileName);
      downloadLink.style.display = 'none';
      downloadLink.click();
      sendAmplitudeData('Graph Download', { 'File Name': fileName });
      setDownloadLoader(false);
    });
  };

  if (progressLoading || progressChartLoading) {
    return (
      <Container>
        <h3 className="mg-b-10">
          <Row>
            <Col md={4}>
              <Skeleton className="ht-15" />
            </Col>
          </Row>
        </h3>
        {process && <ProgressSkeleton />}
        <ProgressChartSkeleton />
      </Container>
    );
  }
  return (
    <>
      <Helmet title={`Progress - Student - ${APP_NAME}`}>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      {downloadLoader && (
        <div className="loader_main">
          <div className="loader">
            <FeatherIcon className="tx-50" icon="loader" />
          </div>
        </div>
      )}
      <Container>
        <h4 className="d-block d-md-none mg-b-15">Progress</h4>

        {process.total_therapy_hours > 0 && (
          <div className="ProgressBar-box pd-10 pd-md-15 mg-b-10 mg-lg-b-15 bg-gray-100 bd bd-1 rounded">
            <h4 className="mg-t-0 mg-b-10">Total Hours Owned</h4>
            <ProgressBar className="ht-30 mg-b-10">
              <ProgressBar
                striped
                variant="success"
                now={process.session_hours_completed_percentage}
                label={`${process.session_hours_completed} Hours Completed`}
                className={`tx-16 tx-medium ${
                  process.session_hours_completed_percentage > 0 ? 'tl-progressbarmin' : ''
                } `}
              />
              <ProgressBar
                now={process.therapy_hours_remaining_percentage}
                label={`${process.therapy_hours_remaining} Hours Left`}
                className="bg-gray-200 tx-black tx-16 tx-medium"
              />
            </ProgressBar>
            <Row>
              <Col md={6} lg={4}>
                <label className="tx-medium mg-b-0-f" htmlFor="tl_hours">
                  Total Hours Completed:
                </label>
                <span id="tl_hours" className="mg-l-5">
                  {`${process.session_hours_completed} Hours`}
                </span>
              </Col>
              <Col md={6} lg={4} className="tx-lg-center tx-md-right">
                <label className="tx-medium mg-b-0-f" htmlFor="tlo_hours">
                  Total Hours Owned:
                </label>
                <span id="tlo_hours" className="mg-l-5">
                  {`${process.total_therapy_hours} Hours`}
                </span>
              </Col>
              <Col md={6} lg={4} className="tx-lg-right mg-md-t-5">
                <label className="tx-medium mg-b-0-f tx-right" htmlFor="tlr_hours">
                  Total Hours Remaining:
                </label>
                <span id="tlr_hours" className="mg-l-5">
                  {`${process.therapy_hours_remaining} Hours`}
                </span>
              </Col>
            </Row>
          </div>
        )}
        {data.length ? (
          data.map((chart, i) => {
            const count = i + 1;
            return (
              <Card className="mg-b-20 mg-lg-b-25" id={`chart${count}`} key={`chart${count}`}>
                <Card.Header className="pd-y-10 pd-x-10 pd-md-x-15 d-flex align-items-center justify-content-between bg-gray-100">
                  <Card.Text className="tx-bold mg-b-0 d-flex align-items-center">
                    <span className="tx-14 tx-md-14 tx-lg-16">{chart.name}</span>
                  </Card.Text>
                  <Button
                    onClick={() => exportChart(`chart${count}`, chart.name)}
                    id={`exportChart${count}`}
                    className="btn btn-success"
                  >
                    Download
                  </Button>
                </Card.Header>
                <Card.Body className="pd-10 pd-lg-15 pos-relative">
                  {data ? (
                    <ProgressChart ChartType="Line" ChartData={data} {...chart} />
                  ) : (
                    <Alert key={`danger-${i}`} variant="danger">
                      {message}
                    </Alert>
                  )}
                </Card.Body>
              </Card>
            );
          })
        ) : (
          <Alert key="alert-info" variant="info">
            {message}
          </Alert>
        )}
      </Container>
    </>
  );
};

export default Progress;
