import React from 'react';
import Card from 'react-bootstrap/Card';
import { withRouter } from 'react-router-dom';

const StudentLoginRightInfo = ({ isStaffLogin = false }) => (
  <Card className="border-0 d-block wd-lg-350 mg-r-auto mg-l-auto text-center bd-0 rounded-0 bg-gray-100">
    <span className="tx-14 tx-md-18 tx-bold instruction-title  pd-l-40 pd-r-40 pd-t-10 pd-b-10 rounded-circle mg-b-15 d-inline-block wd-auto">
      Example
    </span>
    <span className="pd-10 mg-b-5 instruction-title  d-block text-left">
      <strong className="mg-r-5">{isStaffLogin ? 'Staff' : 'Student'} Name:</strong>John Doe
    </span>
    <span className="pd-10 mg-b-5 instruction-title  d-block text-left">
      <strong className="mg-r-5">Date of Birth:</strong>
      {isStaffLogin ? 'May 13, 1992' : 'February 9, 2008'}
    </span>
    <span className="pd-10 mg-b-15 instruction-title  d-block text-left">
      Then please put <strong>JD</strong> in First box and <strong>{isStaffLogin ? '05131992' : '02092008'}</strong> in
      Second box.
    </span>
    <span className="text-center tx-12">
      If you still have trouble logging into the system then please call your respective therapist.
    </span>
  </Card>
);

export default withRouter(StudentLoginRightInfo);
