import React from 'react';
import Card from 'react-bootstrap/Card';
import FeatherIcon from 'feather-icons-react';

const StudentProfileTopBox = ({ data }) => {
  const profileName = data?.name;
  const profileGender = data?.gender;

  return (
    <Card className="media align-items-center justify-content-center pos-relative pd-10 pd-sm-15 pd-lg-25 bg-gray-100 mg-b-15 mg-lg-b-30 flex-row bd-0 rounded-0">
      <Card className="wd-60 wd-md-60 wd-lg-100 ht-60 ht-md-60 ht-lg-100 bg-white bd bd-3 bd-gray-900 rounded-circle justify-content-center align-items-center user-icon">
        <FeatherIcon className="d-inline-block wd-30 ht-30 wd-lg-45 ht-lg-50" icon="user" />
      </Card>
      <Card.Body className="media-body pd-0 pd-lg-l-20 pd-l-10">
        <Card.Text className="mg-b-0 tx-14 tx-md-18 tx-lg-24 lh-1">
          <strong>Name:</strong> <span className="tx-normal">{profileName}</span>
        </Card.Text>
        <Card.Text className="mg-b-0 mg-t-5 tx-14 tx-md-18 tx-lg-24 lh-1">
          <strong>Gender:</strong> <span className="tx-normal">{profileGender}</span>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default StudentProfileTopBox;
