import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { genEdSubmitConsent } from '../../../services/api/student/authService';
import { isDevelopment } from '../../../utils/utils';

const StudentGenEdConsent = ({ studentname, schoolname, language = 'en', modalHide, updateParent }) => {
  const [step, setStep] = useState(1);
  const [privacy, setPrivacy] = useState(0);
  const [teletherapy, setTeletherapy] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [name, setName] = useState('');
  const [relation, setRelation] = useState('Parent');
  const [authorize, setAuthorize] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const isEnglish = language === 'en';

  function incrementStep() {
    setStep(step + 1);
  }

  const handleSelect = function (selectedItems) {
    const schools = [];
    for (let i = 0; i < selectedItems.length; i++) {
      schools.push(selectedItems[i].value);
    }
    setReasons(schools);
  };
  const handleSubmit = async () => {
    if (authorize) {
      if (!reasons || reasons.length === 0) {
        setErrorMessage('Please select at least one reason');
        return;
      }
      if (!name) {
        setErrorMessage('Please enter your full name in the electronic signature');
        return;
      }
      if (!relation) {
        setErrorMessage('Please select your relation');
        return;
      }
    }

    setLoading(true);

    try {
      const payload = {
        privacy_practices: privacy,
        telepractice_treatment: teletherapy,
        authorize_school: authorize,
        reasons,
        name,
        relation,
      };
      const res = await genEdSubmitConsent(payload);
      if (res) {
        toast.success(res.data.message || 'Your consent has submitted.', {
          autoClose: 2000,
        });
        modalHide();
        updateParent();
      }
    } catch (e) {
      setErrorMessage(e?.response?.data?.message || 'Something went wrong');
      if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.log('err ', e);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form id="student_gen_ed_consent" name="student_gen_ed_consent">
      <ul className="steps justify-content-center">
        <li className={`step-item ${step === 1 ? 'active' : 'completed'} `}>
          <Link
            className="step-link"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <span className="step-number">1</span> <FeatherIcon icon="arrow-right" className="wd-15 mg-l-20" />
          </Link>
        </li>
        <li className={`step-item ${step === 2 ? 'active' : 'completed'} `}>
          <Link
            className="step-link"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <span className="step-number">2</span> <FeatherIcon icon="arrow-right" className="wd-15 mg-l-20" />
          </Link>
        </li>
        <li className={`step-item ${step === 3 ? 'active' : 'completed'} `}>
          <Link
            className="step-link"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <span className="step-number">3</span>
          </Link>
        </li>
      </ul>
      <div className="stepcontbox mg-t-30 mg-b-20">
        <div className="step_one" style={{ display: step === 1 ? 'block' : 'none' }}>
          {isEnglish ? (
            <h4 className="mg-b-15">Notice of Privacy Practices</h4>
          ) : (
            <h4 className="mg-b-15">Aviso Sobre Prácticas de Privacidad</h4>
          )}
          {isEnglish ? (
            <div className="step-cont">
              <p>
                This notice describes how medical information about you may be used and disclosed and how you can get
                access to this information. Please review it carefully.{' '}
              </p>
              <p>
                Your health record contains personal information about you and your health as it relates to your past,
                present or future physical or mental health or condition and related health care services. This is
                referred to as Protected Health Information (“PHI”). This Notice of Privacy Practices describes how we
                may use and disclose your PHI in accordance with applicable law, including the Health Insurance
                Portability and Accountability Act (“HIPAA”) It also describes your rights regarding how you may gain
                access to and control your PHI.
              </p>
              <p>This section explains your rights and some of our responsibilities to help you.</p>
              <p>
                <strong>For Treatment.</strong> Your PHI may be used and disclosed by those who are involved in your
                care for the purpose of providing, coordinating, or managing your health care treatment and related
                services. This includes consultation with clinical supervisors or other treatment team members.
              </p>
              <p>
                <strong>For Payment.</strong> We may use and disclose PHI so that we can receive payment for services
                provided to you.{' '}
              </p>
              <p>
                <strong>For Health Care Operations.</strong> We may use or disclose, as needed, your PHI in order to
                support our business activities including, but not limited to, quality assessment activities, employee
                review activities, licensing, and conducting or arranging for other business activities. For example, we
                may share your PHI with third parties that perform various business activities (e.g., scanning
                documents) provided we have a written contract with the business that requires it to safeguard the
                privacy of your PHI.
              </p>
              <p>USE AND DISCLOSURE OF YOUR HEALTH INFORMATION WITHOUT AUTHORIZATION</p>
              <p>
                Following is a list of the categories of uses and disclosures permitted by HIPAA without an
                authorization. Applicable law and ethical standards permit us to disclose information about you without
                your authorization only in a limited number of situations.
              </p>
              <p>
                <strong>Child Abuse or Neglect.</strong> We may disclose your PHI to a state or local agency that is
                authorized by law to receive reports of child abuse or neglect.
              </p>
              <p>
                <strong>Elder Abuse or Neglect.</strong> We may disclose your PHI to a state or local agency that is
                authorized by law to receive reports of elder abuse or neglect.
              </p>
              <p>
                <strong>Judicial and Administrative Proceedings.</strong> We may disclose your PHI pursuant to a
                subpoena (with your written consent), court order, administrative order or similar process.
              </p>
              <p>
                <strong>Required by Law.</strong> We must make disclosures to the government agencies for the purpose of
                investigating or determining our compliance with the requirements of the Privacy Rule.
              </p>
              <p>
                <strong>Health Oversight.</strong> If required, we may disclose PHI to a health oversight agency for
                activities authorized by law, such as audits, investigations, and inspections. Oversight agencies
                seeking this information include government agencies and organizations that provide financial assistance
                to the program (such as third-party payors based on your prior consent) and peer review organizations
                performing utilization and quality control.
              </p>
              <p>
                <strong>Law Enforcement.</strong> We may disclose PHI to a law enforcement official as required by law,
                in compliance with a subpoena (with your written consent), court order, administrative order or similar
                document, for the purpose of identifying a suspect, material witness or missing person, in connection
                with the victim of a crime, in connection with a deceased person, in connection with the reporting of a
                crime in an emergency, or in connection with a crime on the premises.
              </p>
              <p>
                <strong>Specialized Government Functions.</strong> We may review requests from U.S. military command
                authorities if you have served as a member of the armed forces, authorized officials for national
                security and intelligence reasons and to the Department of State for medical suitability determinations,
                and disclose your PHI based on your written consent, mandatory disclosure laws and the need to prevent
                serious harm.
              </p>
              <p>
                <strong>Public Health.</strong> If required, we may use or disclose your PHI for mandatory public health
                activities to a public health authority authorized by law to collect or receive such information for the
                purpose of preventing or controlling disease, injury, or disability, or if directed by a public health
                authority, to a government agency that is collaborating with that public health authority.
              </p>
              <p>
                <strong>Public Safety.</strong> We may disclose your PHI if necessary to prevent or lessen a serious and
                imminent threat to the health or safety of a person or the public. If information is disclosed to
                prevent or lessen a serious threat it will be disclosed to a person or persons reasonably able to
                prevent or lessen the threat, including the target of the threat.
              </p>
              <p>
                <strong>Verbal Permission.</strong> We may also use or disclose your information to family members that
                are directly involved in your treatment with your verbal permission.
              </p>
              <p>USE AND DISCLOSURE OF YOUR HEALTH INFORMATION WITH AUTHORIZATION</p>
              <p>
                Uses and disclosures not specifically permitted by applicable law will be made only with your written
                authorization, which may be revoked at any time, except to the extent that we have already made a use or
                disclosure based upon your authorization.{' '}
              </p>
              <p>Your Rights Regarding your PHI</p>
              <p>You have the following rights regarding PHI we maintain about you.</p>
              <p>
                <strong>Right to Request Restrictions.</strong> You have the right to request a restriction or
                limitation on the use or disclosure of your PHI for treatment, payment, or health care operations. We
                are not required to agree to your request unless the request is to restrict disclosure of PHI to a
                health plan for purposes of carrying out payment or health care operations, and the PHI pertains to a
                health care item or service that you paid for out of pocket. In that case, we are required to honor your
                request for a restriction.
              </p>
              <p>
                <strong>Right to Request Confidential Communication.</strong> You have the right to request that we
                communicate with you about health matters in a certain way or at a certain location. We will accommodate
                reasonable requests. We may require information regarding how payment will be handled or specification
                of an alternative address or other method of contact as a condition for accommodating your request. We
                will not ask you for an explanation of why you are making the request.
              </p>
              <p>
                <strong>Right of Access to Inspect and Copy.</strong> Unless your information was compiled in reasonable
                anticipation of, or for use in a civil, criminal, or administrative action or proceeding, you have the
                right, which may be restricted only in exceptional circumstances, to inspect and copy PHI that is
                maintained in a “designated record set”. A designated record set contains mental health/medical and
                billing records and any other records that are used to make decisions about your care. Your right to
                inspect and copy PHI will be restricted only in those situations where a licensed professional believes
                it is reasonably likely that access would endanger the life or physical safety of, or cause substantial
                harm to the individual or another person. We may charge a reasonable, fee for copies. If your records
                are maintained electronically, you may also request an electronic copy of your PHI. You may also request
                that a copy of your PHI be provided to another person.
              </p>
              <p>
                <strong>Right to Amend.</strong> If you feel that the PHI we have about you is incorrect or incomplete,
                you may ask us to amend the information although we are not required to agree to the amendment. If we
                deny your request for amendment, you have the right to file a statement of disagreement with us. We may
                prepare a rebuttal to your statement and will provide you with a copy. Please contact the Privacy
                Officer if you have any questions.
              </p>
              <p>
                <strong>Right to an Accounting of Disclosures.</strong> You have the right to request an accounting of
                certain of the disclosures that we make of your PHI. We may charge you a reasonable fee if you request
                more than one accounting in any 12-month period.
              </p>
              <p>
                <strong>Right to a Copy of this Notice.</strong> You have the right to a paper copy of this notice
              </p>
              <p>
                <strong>COMPLAINTS </strong>
              </p>
              <p>
                If you believe we have violated your privacy rights, you have the right to file a complaint in writing
                with the Secretary of Health and Human Services at 200 Independence Avenue, S.W. Washington, D.C. 20201
                or by calling (202) 619-0257. We will not retaliate against you for filing a complaint.
              </p>
            </div>
          ) : (
            <div className="step-cont">
              <p>
                Este aviso describe cómo se puede utilizar y divulgar su información médica y cómo puede acceder a ella.
                Le rogamos que lo lea detenidamente.
              </p>
              <p>
                Su historial médico contiene información personal sobre usted y su salud en relación con su salud física
                o mental pasada, presente o futura y con los servicios sanitarios relacionados. Esto se denomina
                Información Sanitaria Protegida ("PHI", por sus siglas en inglés).{' '}
              </p>

              <p>
                Este Aviso de Prácticas de Privacidad describe cómo podemos utilizar y divulgar su PHI de acuerdo con la
                ley aplicable, incluida la Ley de Portabilidad y Responsabilidad del Seguro Médico ("HIPAA"). También
                describe sus derechos sobre cómo puede acceder a su PHI y controlarla.
              </p>

              <p>Esta sección explica sus derechos y algunas de nuestras responsabilidades para ayudarle.</p>
              <p>
                <strong>Para tratamiento.</strong> Su PHI puede ser utilizada y divulgada por quienes intervienen en su
                tratamiento con el fin de proporcionar, coordinar o administrar su tratamiento médico y servicios
                relacionados.
              </p>

              <p>
                relacionados. Esto incluye consultas con supervisores clínicos u otros miembros del equipo de
                tratamiento.
              </p>

              <p>
                <strong>Para el pago.</strong> Podemos utilizar y divulgar la PHI para que podamos recibir el pago por
                los servicios prestados a usted.
              </p>
              <p>
                <strong>PPara operaciones de atención médica.</strong> Podemos usar o divulgar, según sea necesario, su
                PHI para apoyar nuestras actividades comerciales, incluidas, entre otras, las actividades de evaluación
                de la calidad, actividades de evaluación de la calidad, actividades de revisión de los empleados,
                concesión de licencias y realización u organización de otras actividades comerciales. Por ejemplo,
                podemos compartir su PHI con terceros que realicen diversas actividades empresariales (por ejemplo,
                escanear documentos) siempre que tengamos un contrato por escrito con la empresa que exija que proteja
                la privacidad de su PHI.
              </p>
              <p>USO Y DIVULGACIÓN DE SU INFORMACIÓN SANITARIA SIN AUTORIZACIÓN</p>

              <p>
                <strong>Abuso o abandono de menores.</strong> Podemos divulgar su PHI a una agencia estatal o local que
                esté autorizada por ley a recibir denuncias de maltrato o abandono infantil.
              </p>
              <p>
                <strong>Abuso o abandono de ancianos.</strong> Podemos revelar su PHI a una agencia estatal o local que
                está autorizado por ley a recibir denuncias de maltrato o abandono de ancianos.
              </p>
              <p>
                <strong>Procedimientos judiciales y administrativos.</strong> odremos divulgar su PHI en virtud de una
                citación judicial (con su consentimiento por escrito), orden judicial, orden administrativa o proceso
                similar
              </p>
              <p>
                <strong>Exigencias legales.</strong> Debemos revelar información a los organismos gubernamentales con el
                fin de investigar o determinar nuestro cumplimiento de los requisitos de la Norma de confidencialidad.
              </p>
              <p>
                <strong>Supervisión sanitaria.</strong> Si es necesario, podemos divulgar la PHI a una agencia de
                supervisión sanitaria para actividades autorizadas por la ley, como auditorías, investigaciones e
                inspecciones. Entre los organismos de supervisión que solicitan esta información incluyen organismos
                gubernamentales y organizaciones que prestan financiera al programa (como terceros pagadores con su
                consentimiento previo) y organizaciones de que realizan controles de utilización y calidad.
              </p>
              <p>
                <strong>Cumplimiento de la ley.</strong> Podremos divulgar la PHI a un funcionario encargado de hacer
                cumplir la ley según lo exija la ley, en cumplimiento de una citación (con su consentimiento por
                escrito), orden judicial, orden administrativa o documento similar, con el fin de identificar a un
                sospechoso, testigo material o persona desaparecida, en relación con la víctima de un delito, en
                relación con una persona fallecida, en relación con la denuncia de un delito en una emergencia o en
                relación con un delito en las instalaciones.
              </p>
              <p>
                <strong>Funciones gubernamentales especializadas.</strong> Podemos revisar solicitudes de autoridades de
                mando militar de EE.UU. si usted ha servido como miembro de las fuerzas armadas, funcionarios
                autorizados por razones de seguridad nacional e inteligencia y al Departamento de Estado para
                determinaciones de idoneidad médica, y divulgar su PHI basándonos en su consentimiento por escrito, las
                leyes de divulgación obligatoria y la necesidad de prevenir daños graves.
              </p>
              <p>
                <strong>Salud pública.</strong> Si es necesario, podemos utilizar o divulgar su PHI para actividades
                obligatorias de salud pública a una autoridad de salud pública autorizada por ley para recopilar o
                recibir dicha información con el fin de prevenir o controlar enfermedades, lesiones o discapacidades, o
                si así lo indica una autoridad de salud pública, a una agencia gubernamental que esté colaborando con
                dicha autoridad de salud pública.
              </p>
              <p>
                <strong>Seguridad pública.</strong> Podemos divulgar su PHI si es necesario para prevenir o reducir una
                amenaza grave e inminente para la salud o la seguridad de una persona o del público. Si la información
                se divulga para prevenir o reducir una amenaza grave, se divulgará a una persona o personas
                razonablemente capacitadas para prevenir o reducir la amenaza, incluido el objetivo de la amenaza.
              </p>
              <p>
                <strong>Permiso verbal.</strong> También podemos utilizar o divulgar su información a familiares que
                estén directamente implicados en su tratamiento con su permiso verbal.
              </p>
              <p>USO Y DIVULGACIÓN DE SUS DATOS SANITARIOS CON AUTORIZACIÓN</p>
              <p>
                Los usos y divulgaciones no permitidos específicamente por la legislación aplicable se realizarán
                únicamente con su autorización por escrito, que podrá ser revocada en cualquier momento, excepto en la
                medida en que ya hayamos realizado un uso o divulgación basándonos en su autorización.
              </p>
              <p>Sus derechos en relación con su PHI</p>
              <p>Usted tiene los siguientes derechos con respecto a la PHI que mantenemos sobre usted.</p>
              <p>
                <strong>Derecho a solicitar restricciones.</strong> Tiene derecho a solicitar una restricción o
                limitación en el uso o divulgación de su PHI para tratamiento, pago u operaciones de atención médica. No
                estamos obligados a aceptar su solicitud a menos que la solicitud sea para restringir la divulgación de
                la PHI a un plan de salud con el fin de llevar a cabo el pago o las operaciones de atención médica, y la
                PHI se refiere a un artículo o servicio de atención médica que usted pagó de su bolsillo. En ese caso,
                estamos obligados a atender su solicitud de restricción.
              </p>
              <p>
                <strong>Derecho a solicitar comunicación confidencial.</strong> Tiene derecho a solicitar que nos
                comuniquemos con usted sobre asuntos de salud de una determinada manera o en un determinado lugar.
                Atenderemos las solicitudes razonables. Podemos solicitar información sobre cómo se gestionará el pago o
                la especificación de una dirección alternativa u otro método de contacto como condición para acceder a
                su solicitud. No le pediremos explicaciones sobre los motivos de su solicitud.
              </p>
              <p>
                <strong>Derecho de acceso para inspeccionar y copiar</strong> A menos que su información haya sido
                recopilada en previsión razonable de, o para su uso en una acción o procedimiento civil, penal o
                administrativo, usted tiene derecho, que puede ser restringido sólo en circunstancias excepcionales, a
                inspeccionar y copiar la PHI que se mantiene en un "conjunto de registros designados". Un conjunto de
                registros designados contiene registros de salud mental/médicos y de facturación y cualquier otro
                registro que se utilice para tomar decisiones sobre su atención. Su derecho a inspeccionar y copiar la
                PHI sólo se restringirá en aquellas situaciones en las que un profesional autorizado considere que es
                razonablemente probable que el acceso ponga en peligro la vida o la seguridad física del individuo o de
                otra persona, o le cause un daño sustancial. Podremos cobrar una tarifa razonable por las copias. Si sus
                registros se mantienen electrónicamente, también puede solicitar una copia electrónica de su PHI.
                También puede solicitar que se proporcione una copia de su PHI a otra persona.
              </p>
              <p>
                <strong>Derecho de modificación.</strong> Si cree que la PHI que tenemos sobre usted es incorrecta o
                incompleta, puede pedirnos que modifiquemos la información, aunque no estamos obligados a aceptar la
                modificación. Si denegamos su solicitud de modificación, tiene derecho a presentarnos una declaración de
                desacuerdo. Podemos preparar una refutación a su declaración y le proporcionaremos una copia. Si tiene
                alguna pregunta, póngase en contacto con el responsable de la protección de datos.
              </p>
              <p>
                <strong>Derecho a un informe de divulgaciones.</strong> Tiene derecho a solicitar una relación de
                algunas de las divulgaciones que hagamos de su PHI. Podemos cobrarle una tarifa razonable si solicita
                más de un informe en un período de 12 meses.
              </p>
              <p>
                <strong>Derecho a una copia de este aviso.</strong> Tiene derecho a recibir una copia impresa de este
                aviso.
              </p>
              <p>
                <strong>QUEJAS</strong>
              </p>
              <p>
                Si cree que hemos violado sus derechos de privacidad, tiene derecho a presentar una queja por escrito a
                la Secretaría de Salud y Servicios Humanos en 200 Independence Avenue, S.W. Washington, D.C. 20201 o
                llamando al (202) 619-0257. No tomaremos represalias contra usted por presentar una queja.
              </p>
            </div>
          )}
          <Form.Check
            label={
              isEnglish
                ? 'By checking Accept, I confirm that I have read and understand these terms. Failure to complete these forms will result in services not being provided.'
                : 'Al firmar a continuación, confirmo que he leído y comprendo estas condiciones.'
            }
            name="group1"
            type="checkbox"
            id="understand_these_terms"
            onClick={() => setPrivacy(!privacy)}
          />
          <div className="text-center mg-t-20">
            <Button
              className="btn btn-dark"
              onClick={() => {
                incrementStep();
              }}
              disabled={privacy ? '' : 'disabled'}
            >
              Next
            </Button>
          </div>
        </div>
        <div className="step_two" style={{ display: step === 2 ? 'block' : 'none' }}>
          {isEnglish ? (
            <h4 className="mg-b-15">E-Therapy Telepractice Treatment Consent</h4>
          ) : (
            <h4 className="mg-b-15">E-Therapy Consentimiento de Tratamiento Telesalud</h4>
          )}

          {isEnglish ? (
            <div className="step-cont">
              <p>
                E-Therapy provides outpatient virtual counseling. Our counselors provide individual, family and group
                counseling. Your initial appointments determine what treatment will best serve your needs. Your
                counselor can be contacted via the STAR System with questions. After hours, in emergency situations,
                call 911 or proceed to an emergency room.{' '}
              </p>
              <p>
                <strong>Consent for Treatment:</strong>
              </p>
              <p>
                I consent to participate in counseling with E-Therapy. I will discuss the goals, objectives and methods
                of my treatment with my counselor and this plan may be modified over time. I may discuss the benefits,
                risks, alternatives, and nature of the treatment.
              </p>
              <p>
                Confidentiality: I acknowledge E-Therapy complies with HIPAA, and Federal and state laws regarding
                confidentiality. The session content and all relevant materials to the client’s treatment will be held
                confidential unless the client requests in writing to have all or portions of such content released to a
                specifically named person/persons. I have been given the opportunity to read and understand that my
                counselor may consult, receive supervision and or be quality reviewed to ensure that appropriate
                treatment is provided. Communication to outside parties will be provided through written consent and
                authorization. Confidentiality can be broken without consent as outlined below but not limited to:
              </p>
              <ol className="pd-l-15">
                <li className="mg-b-5">
                  If there is a clear, present, and immediate risk to the client or another person.
                </li>
                <li className="mg-b-5">
                  If the therapist has a reasonable suspicion that a client or other named victim is the perpetrator,
                  observer of, or actual victim of neglect, physical, emotional or sexual abuse of children under the
                  age of 18 years or suspicions as stated above in the case of an elderly person who may be subjected to
                  these abuses.
                </li>
                <li className="mg-b-5">
                  If a court of law issues a legitimate subpoena for information stated on the subpoena.
                </li>
                <li className="mg-b-5">
                  If a client is in therapy or being treated by order of a court of law, or if information is obtained
                  for the purpose of rendering an expert’s report to an attorney.
                </li>
              </ol>
              <p>
                Telehealth is live two-way audio and video electronic communications that allows therapists and clients
                to meet outside of a physical office setting.
              </p>
              <p>
                I understand that telehealth services are completely voluntary and that I can withdraw this consent at
                any time. I agree not to make or allow audio or video recordings of any portion of the sessions. I
                understand that the laws that protect privacy and confidentiality apply to telehealth. I understand that
                telehealth is performed over a secure communication system. I agree that the therapist and practice will
                not be held responsible if any outside party gains access to my personal information. I understand that
                if there is an emergency during a telehealth session, my therapist may call emergency services and/ or
                my emergency contact.{' '}
              </p>
            </div>
          ) : (
            <div className="step-cont">
              <p>
                E-Therapy ofrece asesoramiento virtual para pacientes ambulatorios. Nuestros consejeros proporcionan
                asesoramiento individual, familiar y de grupo. Sus citas iniciales determinan el tratamiento que mejor
                se adapte a sus necesidades. Su consejero puede ser contactado a través del sistema STAR con preguntas.
                Fuera del horario de atención, en situaciones de emergencia, llame al 911 o diríjase a una sala de
                emergencias.
              </p>
              <p>
                <strong>Consentimiento para el tratamiento:</strong>
              </p>
              <p>
                Doy mi consentimiento para participar en el asesoramiento con E-Therapy. Discutire las metas, objetivos
                y metodos de mi tratamiento con mi consejero y este plan puede ser modificado con el tiempo. Podré
                discutir los beneficios, riesgos, alternativas y naturaleza del tratamiento.
              </p>
              <p>
                Confidencialidad: Reconozco que E-Therapy cumple con HIPAA, y las leyes federales y estatales en materia
                de confidencialidad. El contenido de la sesión y todos los materiales relevantes para el tratamiento del
                cliente se mantendrán confidenciales a menos que el cliente solicite por escrito que todo o parte de
                dicho contenido sea divulgado a una persona o personas específicamente nombradas. Se me ha dado la
                oportunidad de leer y entender que mi consejero puede consultar, recibir supervisión y/o ser revisado
                para asegurar que se proporciona un tratamiento apropiado. La comunicación a partes externas se hará
                mediante consentimiento y autorización por escrito.
              </p>

              <p>
                <strong>
                  La confidencialidad puede romperse sin consentimiento como se indica a continuación pero no limitado
                  a:
                </strong>
              </p>

              <ol className="pd-l-15">
                <li className="mg-b-5">
                  Si existe un riesgo claro, presente e inmediato para el cliente u otra persona.
                </li>
                <li className="mg-b-5">
                  Si el terapeuta tiene una sospecha razonable de que un cliente u otra víctima mencionada es autor,
                  observador o víctima real de negligencia, abuso físico, emocional o sexual de niños menores de 18 años
                  o sospechas como las indicadas anteriormente en el caso de una persona mayor que pueda ser objeto de
                  estos abusos.
                </li>
                <li className="mg-b-5">
                  Si un tribunal de justicia emite una citación legítima para obtener la información indicada en la
                  citación.
                </li>
                <li className="mg-b-5">
                  Si un cliente está recibiendo terapia o tratamiento por orden de un tribunal de justicia, o si la
                  información se obtiene con el fin de presentar un informe pericial a un abogado.
                </li>
              </ol>
              <p>
                Telehealth is live two-way audio and video electronic communications that allows therapists and clients
                to meet outside of a physical office setting.
              </p>
              <p>
                La telesalud consiste en comunicaciones electrónicas bidireccionales de audio y vídeo en directo que
                permiten a los terapeutas y clientes reunirse fuera de un consultorio físico. Entiendo que los servicios
                de telesalud son completamente voluntarios y que puedo retirar este consentimiento en cualquier momento.
                Acepto no hacer ni permitir grabaciones de audio o vídeo de ninguna parte de las sesiones. Entiendo que
                las leyes que protegen la privacidad y la confidencialidad se aplican a la telesalud. Entiendo que la
                telesalud se realiza a través de un sistema de comunicación seguro. Estoy de acuerdo en que el terapeuta
                y la consulta no serán responsables si alguna parte externa accede a mi información personal. Entiendo
                que si hay una emergencia durante una sesión de telesalud, mi terapeuta puede llamar a los servicios de
                emergencia y/o a mi contacto de emergencia.;
              </p>
            </div>
          )}

          <Form.Check
            label={
              isEnglish
                ? 'By checking Accept, I confirm that I have read and understand these terms. Failure to complete these forms will result in services not being provided.'
                : 'Al firmar a continuación, confirmo que he leído y comprendo estas condiciones.'
            }
            name="group2"
            type="checkbox"
            id="understand_these_terms2"
            onClick={() => setTeletherapy(!teletherapy)}
          />
          <div className="text-center mg-t-20">
            <Button
              className="btn btn-dark"
              onClick={() => {
                incrementStep();
              }}
              disabled={teletherapy ? '' : 'disabled'}
            >
              Next
            </Button>
          </div>
        </div>

        <div className="step_three" style={{ display: step === 3 ? 'block' : 'none' }}>
          {isEnglish ? (
            <h4 className="mg-b-15">Authorization Consenting To Release Of Information - School</h4>
          ) : (
            <h4 className="mg-b-15">Autorización Que Consiente al Lanzamiento de la Información - Niño</h4>
          )}

          {isEnglish ? (
            <div className="bg-gray-100 pd-15 mg-b-15">
              <p>
                I authorize E-Therapy to discuss and exchange, in verbal or written form, any relevant information to
                the treatment of my child {studentname}, with the person, or any person(s) or staff of the institution,
                named below.
              </p>
              <p className="mg-b-0">
                <i>{schoolname}</i>
              </p>
            </div>
          ) : (
            ''
          )}

          <div className="mg-b-15">
            {isEnglish ? (
              <p>
                <strong>
                  Consenting to this release will help your therapist coordinate with your student’s school to best
                  serve the student.
                </strong>
              </p>
            ) : (
              ''
            )}

            <Form.Check
              label={
                isEnglish
                  ? 'I consent to the release (if checked please complete the rest of the form)'
                  : 'Yo autorizo a E-Therapy a discutir e intercambiar, en forma verbal o escrita, cualquier información relevante para el tratamiento de mi hijo. (Si está marcado, complete el resto del formulario)'
              }
              name="consent"
              type="radio"
              checked={authorize}
              id="understand_these_terms3"
              onChange={() => setAuthorize(1)}
            />
            <Form.Check
              label={isEnglish ? 'I do NOT consent' : 'No consiento'}
              name="consent"
              type="radio"
              checked={!authorize}
              id="understand_these_terms4"
              onChange={() => setAuthorize(0)}
            />
          </div>

          <Form.Group className="mb-3">
            <Form.Label>
              <strong>{isEnglish ? 'For the following reason(s)' : 'Por la(s) siguiente(s) razón(es):'}</strong>
            </Form.Label>
            <Form.Control
              as="select"
              id="reason"
              name="reason"
              className="form-control mg-b-15 mx-wd-300"
              multiple
              onChange={(e) => {
                handleSelect(e.target.selectedOptions);
              }}
            >
              <option disabled>{isEnglish ? 'Select Reason' : 'Seleccione la razón'}</option>
              <option value="Assessments">{isEnglish ? 'Assessments' : 'Evaluación psicológica'}</option>
              <option value="Medical Records">{isEnglish ? 'Medical Records' : ' Historial médico'}</option>
              <option value="Coordination of Care">
                {isEnglish ? 'Coordination of Care' : ' Coordinación de cuidados'}
              </option>
              <option value="Other">{isEnglish ? 'Other' : 'Otros'}</option>
            </Form.Control>
          </Form.Group>
          <p>
            {isEnglish
              ? 'You may revoke this consent at any time. Unless otherwise revoked or renewed, this consent is in effect for one year from the date of the last session. This consent is subject to all conditions outlined in the Office Policies & Consent to Psychotherapy Services.'
              : 'Puede revocar este consentimiento en cualquier momento. A menos que sea revocado o renovado, este consentimiento estará en vigor durante un año a partir de la fecha de la última sesión. Este consentimiento está sujeto a todas las condiciones descritas en las Políticas del Consultorio y Consentimiento para Servicios de Psicoterapia.'}
          </p>
          <p>
            {isEnglish
              ? 'By typing in your name below you are signing this Agreement electronically. You agree your electronic signature is the legal equivalent of your manual signature on this Agreement.'
              : ''}
          </p>
          <Row>
            <Col sm={6} md={6} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>{isEnglish ? 'Electronic Signature' : 'Firma Electrónica'}</Form.Label>
                <Form.Control
                  type="text"
                  name="electronic_signature"
                  placeholder={isEnglish ? 'Full Name' : 'Nombre Completo'}
                  autoComplete="off"
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={6} md={6} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>{isEnglish ? 'Date' : 'Fecha'}</Form.Label>
                <Form.Control type="text" name="date" value={format(new Date(), 'MM-dd-yyyy')} readOnly />
              </Form.Group>
            </Col>
            <Col sm={6} md={6} lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>{isEnglish ? 'Relationship to Student' : 'Relación con el estudiante'}</Form.Label>
                <Form.Control
                  as="select"
                  value={relation}
                  id="relationship_to_student"
                  name="relationship_to_student"
                  className="form-control"
                  onChange={(e) => setRelation(e.target.value)}
                >
                  <option value="Parent">{isEnglish ? 'Parent' : 'Padre'}</option>
                  <option value="Legal Guardian">{isEnglish ? 'Legal Guardian' : 'Tutor Legal'}</option>
                  <option value="Self">{isEnglish ? 'Self' : 'Ser'}</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <div className="text-center mg-t-20">
            <Button className="btn btn-dark" onClick={handleSubmit} disabled={loading}>
              Finish
            </Button>
            {errorMessage && <span className="tx-danger tx-16 mg-t-15 d-block tx-center">{errorMessage}</span>}
          </div>
        </div>
      </div>
    </Form>
  );
};

export default StudentGenEdConsent;
