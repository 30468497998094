import React from 'react';
import FeatherIcon from 'feather-icons-react';

function InternalServerError() {
  return (
    <div className="content content-fixed content-auth-alt">
      <div className="container ht-100p tx-center">
        <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
          <div className="wd-70p wd-sm-250 wd-lg-300 mg-b-15">
            <FeatherIcon className="wd-20p ht-20p tx-danger" icon="alert-triangle" />
          </div>
          <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">500 Internal Server Error</h1>
          <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">
            Oopps. There was an error, please try again later.
          </h5>
          <p className="tx-color-03 mg-b-30">
            The server encountered an internal server error and was unable to complete your request.
          </p>
          <div className="mg-b-40">
            <a className="btn btn-white pd-x-30" href="/">
              Back to Home
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InternalServerError;
