import React from 'react';
import { Link } from 'react-router-dom';

const BrandLogo = () => (
  <Link to="/" className="df-logo d-flex align-items-center">
    {/* <FeatherIcon icon="star" className="d-inline-block mg-r-5 tx-black" /> */}
    <span className="tx-primary">STAR</span>
  </Link>
);

export default BrandLogo;
