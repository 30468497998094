import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Skeleton from 'react-loading-skeleton';

const ScheduleSkeleton = () => (
  <Row>
    <Col md={6} className="mg-t-5 mg-b-5">
      <Card className="text-center media pd-10 bd bd-2 tx-bold align-items-center justify-content-center pos-relative ht-100p tx-color-02 rounded">
        <Card.Body className="media-body pd-0 wd-100p">
          <Card.Title className="mg-b-5 wd-60p mg-l-auto mg-r-auto">
            <Skeleton className="ht-15 wd-60p" />
          </Card.Title>
          <Card.Text className="tx-center">
            <p className="mg-0 wd-75p mg-l-auto mg-r-auto d-inline-block">
              <Skeleton className="ht-10" />
            </p>
            <p className="mg-0 wd-60p mg-l-auto mg-r-auto d-inline-block">
              <Skeleton className="ht-10" />
            </p>
            <p className="mg-0 wd-50p mg-l-auto mg-r-auto d-inline-block">
              <Skeleton className="ht-10" />
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);

export default ScheduleSkeleton;
