import React from 'react';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import DefaultBioPic from '../../../../assets/images/user.jpg';

const ProfileBox = ({ name, phone, email, image }) => (
  <Col md={6} className="mg-t-5 mg-b-5">
    <Card className="media pd-10 bd bd-2 align-items-center justify-content-center pos-relative ht-100p rounded d-sm-flex flex-sm-row flex-md-column flex-lg-row">
      <Card className="wd-60 ht-60 wd-lg-80 ht-lg-80 bg-gray-100 bd bd-2 rounded d-flex align-self-start justify-content-center pd-5">
        <Card.Img src={image || DefaultBioPic} alt="Bio Pic" className="wd-auto ht-auto mx-wd-70 max-ht-70 box-img" />
      </Card>
      <Card.Body className="media-body pd-0 wd-100p-f pd-sm-l-15 wd-less-80 mg-t-10 mg-sm-0 mg-md-t-10 mg-lg-t-0 pd-md-0 pd-lg-15 pd-lg-0-f pd-lg-l-15-f">
        <Card.Text className="mg-b-5 tx-16 tx-lg-16 tx-semibold">{name}</Card.Text>
        <div>
          {email && (
            <p className="mg-b-0 tx-14 tx-sm-14 tx-lg-14">
              <span className="tx-bold tx-color-02 mg-r-5">Email:</span>
              <a href={`mailto:${email}`} className="tx-color-02">
                {email}
              </a>
            </p>
          )}
          {phone && (
            <p className="mg-b-0 tx-14 tx-sm-14 tx-lg-14">
              <span className="tx-bold tx-color-02 mg-r-5">Phone:</span>
              <a href={`tel:${phone}`} className="tx-color-02">
                {phone}
              </a>
            </p>
          )}
        </div>
      </Card.Body>
    </Card>
  </Col>
);

export default ProfileBox;
