import React from 'react';
import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import Button from 'react-bootstrap/Button';

// variant: primary | secondary | success | danger | warning | info | light | dark
// outline-primary for outline
// size: xs | sm | lg \ empty for normal

function TLButton({
  label = '',
  type = 'button',
  className = '',
  size = 'sm',
  disabled,
  suffixIcon = '',
  suffixIconClassName = '',
  prefixIcon = '',
  prefixIconClassName = '',
  isRounded,
  onClick,
  variant = 'primary',
  isUppercase = true,
  btnRef,
  ...rest
}) {
  return (
    <Button
      type={type}
      variant={variant}
      className={classNames(
        'tx-medium',
        {
          'rounded-pill': isRounded,
          'tx-uppercase': isUppercase,
        },
        className
      )}
      disabled={disabled}
      onClick={onClick}
      size={size}
      ref={btnRef}
      {...rest}
    >
      {prefixIcon && (
        <FeatherIcon
          icon={prefixIcon}
          className={classNames(`wd-20 ht-20  `, prefixIconClassName, {
            'mg-r-4': label,
          })}
        />
      )}
      {label}
      {suffixIcon && <FeatherIcon icon={suffixIcon} className={`wd-20 ht-20 mg-l-4 ${suffixIconClassName}`} />}
    </Button>
  );
}

export default TLButton;
