import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { deleteUser } from '../../../store/userSlice';
import { toastError } from '../../../services/toast';
import path from '../../../routes/path';
import { authStorage } from '../../../services/localStorage/authStorage';
import { logout } from '../../../services/api/student/authService';
import { GetActivity } from '../../../utils/student';
import { sendAmplitudeData } from '../../../utils/amplitude';
import { setLogoutFrom } from '../../../store/generalSlice';

const Logout = () => {
  const [downloadLoader, setDownloadLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const logoutUser = async () => {
    setDownloadLoader(true);
    try {
      const res = await logout();
      if (res.status === 200) {
        authStorage.removeToken();
        dispatch(deleteUser({ loggedIn: false }));
      }
    } catch (error) {
      toastError(error?.response?.data?.message || 'Something went wrong');
    } finally {
      dispatch(deleteUser({ loggedIn: false }));
      dispatch(setLogoutFrom('student'));
      authStorage.removeToken();
      setDownloadLoader(false);
      history.push(path.student.login);
    }
  };

  useEffect(() => {
    GetActivity(window.location.pathname);
    logoutUser();
    sendAmplitudeData('Logout');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {downloadLoader && (
        <div className="loader_main">
          <div className="loader">
            <FeatherIcon className="tx-50" icon="loader" />
          </div>
        </div>
      )}
    </>
  );
};

export default Logout;
