import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function TLFeedspace({
  as = 'button',
  id: feedspaceId = '',
  className = '',
  hidden = false,
  label = '',
  userDetails,
  ...rest
}) {
  const Component = as;
  const feedspace = useSelector((state) => state?.feedspace);
  const hasInstantiated = feedspace?.hasInstantiated;
  const id = feedspaceId || feedspace?.id;

  useEffect(() => {
    if (id && userDetails?.id && hasInstantiated) {
      window.feedspace.setUserDetails(userDetails);
    }
  }, [hasInstantiated, id, userDetails]);

  return (
    <Component data-feedspace={id} className={`${className} ${hidden ? 'd-none' : ''} `} {...rest}>
      {label || 'Feedback'}
    </Component>
  );
}

export default TLFeedspace;
