/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import enLocale from '@fullcalendar/core/locales/en-au';
import interactionPlugin from '@fullcalendar/interaction';
import list from '@fullcalendar/list';
import FeatherIcon from 'feather-icons-react';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { Helmet } from 'react-helmet';
import Datatable from '../../components/student/common/Datatable';
import { schedule, scheduleCalendar } from '../../services/api/student/authService';
import CalendarSkeleton from '../../components/student/skeleton/CalendarSkeleton';
import { GetActivity } from '../../utils/student';
import { APP_NAME } from '../../utils/constants/AppConstant';
import { sendAmplitudeData } from '../../utils/amplitude';

const Schedule = () => {
  const calendarRef = useRef();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eventsLoader, setEventsLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showEventModal, setShowEventModal] = useState(false);
  const [eventTitle, setEventTitle] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [eventStartTime, setEventStartTime] = useState('');
  const [eventEndTime, setEventEndTime] = useState('');
  const [therapistName, setTherapistName] = useState('');

  const theme = useSelector((state) => state.theme);
  async function getData(date, loader = false) {
    if (loader) setLoading(true);
    const res = await scheduleCalendar(date);
    const { data } = res.data;
    const results = [];
    data.forEach((event) => {
      results.push({
        title: `${event.title}`,
        start: `${event.start}`,
        end: `${event.end}`,
        therapist: event.therapist,
      });
    });
    setEvents(results);
    setLoading(false);
  }

  useEffect(() => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      api.updateSize();
    }
  }, [theme.fontSize]);

  useEffect(() => {
    const newDate = format(new Date(), 'yyyy-MM');
    getData(newDate, true);
    GetActivity(window.location.pathname);
    sendAmplitudeData('My Schedules');
  }, []);

  const nextHandle = async () => {
    setEventsLoader(true);
    const api = calendarRef.current.getApi();
    api.next();
    const newDate = format(new Date(api.currentDataManager.data.currentDate), 'yyyy-MM');
    await getData(newDate);
    setEventsLoader(false);
    setDisabled(false);
  };
  const prevHandle = async () => {
    setEventsLoader(true);
    const api = calendarRef.current.getApi();
    api.prev();
    const newDate = format(new Date(api.currentDataManager.data.currentDate), 'yyyy-MM');
    await getData(newDate);
    setEventsLoader(false);
    setDisabled(false);
  };

  const todayHandle = async () => {
    setEventsLoader(true);
    const api = calendarRef.current.getApi();
    api.today();
    const newDate = format(new Date(), 'yyyy-MM');
    await getData(newDate);
    setEventsLoader(false);
    setDisabled(true);
  };

  function tConvert(time) {
    let t = time;
    // Check correct time format and split into components
    t = t.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [t];

    if (t.length > 1) {
      // If t format correct
      t = t.slice(1); // Remove full string match value
      t[3] = +t[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      t[0] = +t[0] % 12 || 12; // Adjust hours
    }
    return t.join(''); // return adjusted time or original string
  }

  return (
    <>
      <Helmet title={`My Schedules - Student - ${APP_NAME}`}>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container>
        <Modal show={showEventModal} onHide={() => setShowEventModal(false)} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>Schedule Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="row-sm">
              {eventTitle && (
                <Col sm={6}>
                  <label className="mg-b-8 tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                    Service
                  </label>
                  <p className="event-start-date">{eventTitle}</p>
                </Col>
              )}

              {therapistName && (
                <Col sm={6}>
                  <label className="mg-b-8 tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                    Therapist
                  </label>
                  <p className="event-start-date">{therapistName}</p>
                </Col>
              )}

              {eventDate && (
                <Col sm={6}>
                  <label className="mg-b-8 tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Date</label>
                  <p className="event-start-date">{eventDate}</p>
                </Col>
              )}

              {eventStartTime && eventEndTime && (
                <Col sm={6}>
                  <label className="mg-b-8 tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">Time</label>
                  <p className="event-start-date">{`${eventStartTime} - ${eventEndTime}`}</p>
                </Col>
              )}
            </Row>
          </Modal.Body>
        </Modal>
        <h4 className="d-block d-md-none mg-b-15">My Schedule</h4>
        <Datatable apiMethod={schedule} noDataMessage="You don't have any upcoming schedules" />
        {loading ? (
          <CalendarSkeleton />
        ) : (
          <div className={`mg-t-30 mg-b-20 calendar-content-body pos-relative ${disabled ? 'disable' : ''}`}>
            {eventsLoader && (
              <div className="loader_main pos-absolute-f">
                <div className="loader">
                  <FeatherIcon className="tx-50" icon="loader" />
                </div>
              </div>
            )}
            <FullCalendar
              noEventsText="No schedules found for this month"
              locale={enLocale}
              headerToolbar={{
                start: 'todayButton customPrev,customNext',
                center: 'title',
                end: 'dayGridMonth,listMonth',
              }}
              themeSystem="Simplex"
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, list]}
              events={events}
              eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                meridiem: 'short',
                hour12: true,
              }}
              views={{
                timeGridWeek: {
                  titleFormat(date) {
                    return `${date.start.month < 9 ? `0${date.start.month + 1}` : date.start.month + 1}/${
                      date.start.day < 9 ? `0${date.start.day}` : date.start.day
                    }/${date.start.year} - ${date.end.month < 9 ? `0${date.end.month + 1}` : date.end.month + 1}/${
                      date.end.day < 9 ? `0${date.end.day}` : date.end.day
                    }/${date.end.year}`;
                  },
                  dayHeaderContent(date) {
                    const span = document.createElement('span');
                    const span1 = document.createElement('span');
                    const spanText = date.text.split(' ');
                    // eslint-disable-next-line prefer-destructuring
                    span.innerHTML = spanText[0];
                    // eslint-disable-next-line prefer-destructuring
                    span1.innerHTML = spanText[1];
                    const arrayOfDomNodes = [span, span1];
                    return { domNodes: arrayOfDomNodes };
                  },
                  dayHeaderFormat: { weekday: 'short', day: '2-digit' },
                },
                listMonth: {
                  // titleFormat(date) {
                  //   return `${date.start.month < 9 ? `0${date.start.month + 1}` : date.start.month + 1}/${
                  //     date.start.day < 9 ? `0${date.start.day}` : date.start.day
                  //   }/${date.start.year} - ${date.end.month < 9 ? `0${date.end.month + 1}` : date.end.month + 1}/${
                  //     date.end.day < 9 ? `0${date.end.day}` : date.end.day
                  //   }/${date.end.year}`;
                  // },
                  listDaySideFormat: { day: '2-digit' },
                  listDayFormat: { weekday: 'short' },
                },
                timeGridDay: {
                  titleFormat(date) {
                    return `${date.start.month < 9 ? `0${date.start.month + 1}` : date.start.month + 1}/${
                      date.start.day < 9 ? `0${date.start.day}` : date.start.day
                    }/${date.start.year}`;
                  },
                },
              }}
              eventClick={(event) => {
                setEventTitle(event.event._def.title);
                const startTime = tConvert(event.event._instance.range.start.toUTCString().split(' ')[4]);
                const endTime = tConvert(event.event._instance.range.end.toUTCString().split(' ')[4]);
                setEventStartTime(startTime);
                setTherapistName(event.event.extendedProps.therapist);
                setEventEndTime(endTime);
                setEventDate(
                  event.event._instance.range.start.toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  })
                );
                setShowEventModal(true);
              }}
              slotEventOverlap={false}
              ref={calendarRef}
              customButtons={{
                customPrev: {
                  type: 'prev',
                  text: 'Prev',
                  click: prevHandle,
                },
                customNext: {
                  type: 'next',
                  text: 'Next',
                  click: nextHandle,
                },
                todayButton: {
                  type: 'today',
                  text: 'Today',
                  click: todayHandle,
                },
              }}
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default Schedule;
