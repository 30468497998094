import React from 'react';
import HeaderTop from './HeaderTop';
import Navigation from './Navigation';
import { authStorage } from '../../../services/localStorage/authStorage';

const Header = ({ login }) => (
  <header
    className={`navbar navbar-header navbar-header-fixed d-xxs-block remove-ht pd-xxs-t-10 pd-xxs-b-10 d-block ${
      !authStorage.isAuthenticated() ? ' no-menu' : ' ht-auto'
    }`}
  >
    <HeaderTop />
    {authStorage.isAuthenticated() && login && <Navigation />}
  </header>
);

export default Header;
