/* eslint-disable no-shadow */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import path from '../path';
import Route from '../CustomRoute';

const TherapistHomePage = React.lazy(() => import('../../screens/therapist/home'));
const SchoolsPage = React.lazy(() => import('../../screens/therapist/schools'));
const SchoolInfoPage = React.lazy(() => import('../../screens/therapist/schools/SchoolInfo'));
const SpeechTherapyToolsPage = React.lazy(() => import('../../screens/therapist/speech-therapy-tools'));
const OccupationalTherapyToolsPage = React.lazy(() => import('../../screens/therapist/occupational-therapy-tools'));
const CounselingToolsPage = React.lazy(() => import('../../screens/therapist/counseling-tools'));
const PhysicalTherapyPage = React.lazy(() => import('../../screens/therapist/physical-therapy'));
const TechnologyHelpPage = React.lazy(() => import('../../screens/therapist/technology-help'));
const OnBoardingTasksPage = React.lazy(() => import('../../screens/therapist/on-boarding-tasks'));
const FaqPage = React.lazy(() => import('../../screens/therapist/faq'));
const StarHelpPage = React.lazy(() => import('../../screens/therapist/training/StarHelp'));
const ZoomHelpPage = React.lazy(() => import('../../screens/therapist/training/ZoomHelp'));
const OnboardingVideosPage = React.lazy(() => import('../../screens/therapist/training/OnboardingVideos'));
const ProfessionalDevelopment = React.lazy(() => import('../../screens/therapist/training/ProfessionalDevelopment'));
const ProfilePage = React.lazy(() => import('../../screens/therapist/profile'));
const NotificationsPage = React.lazy(() => import('../../screens/therapist/notifications'));
const ChangePasswordPage = React.lazy(() => import('../../screens/therapist/ChangePassword'));
const SessionDocumentsPage = React.lazy(() => import('../../screens/therapist/sessions/session-documents'));
const AddNonScheduleSessionPage = React.lazy(() =>
  import('../../screens/therapist/sessions/non-schedule-session/index')
);
const BillingReport = React.lazy(() => import('../../screens/therapist/billing/index'));
const Sessions = React.lazy(() => import('../../screens/therapist/sessions/index'));
const Student = React.lazy(() => import('../../screens/therapist/students/index'));
const AssignedFrequencyPage = React.lazy(() => import('../../screens/therapist/students/AssignedFrequency'));
const AddSessionPage = React.lazy(() => import('../../screens/therapist/sessions/add-session'));
const PendingSessionsPage = React.lazy(() => import('../../screens/therapist/sessions/pending'));
const StudentInfoPage = React.lazy(() => import('../../screens/therapist/students/Student'));
const NewsletterPage = React.lazy(() => import('../../screens/therapist/newsletter'));
const ReferStudentPage = React.lazy(() => import('../../screens/therapist/students/ReferStudent'));
const SchedulesPage = React.lazy(() => import('../../screens/therapist/schedules'));
const CASpecialTraining = React.lazy(() => import('../../screens/therapist/training/CASpecialTraining'));
const FLVSHostedIepSchedulePage = React.lazy(() => import('../../screens/therapist/schedules/FlvsHostedIepSchedule'));
const GenedConsentDetails = React.lazy(() => import('../../screens/therapist/students/GenedConsentDetails'));
const AssessmentReservationsExam = React.lazy(() => import('../../screens/therapist/assessment-reservations-exam'));
const SchoolSessionManagementPage = React.lazy(() => import('../../screens/therapist/school-session-management'));
export default function TherapistRouter() {
  return (
    <React.Suspense
      fallback=<div>
        <div className="mg-b-20 d-flex justify-content-between flex-wrap tl-row-gap-10">
          <Skeleton width={200} height={20} />
          <Skeleton width={200} height={20} />
        </div>
        <Skeleton height="100vh" width="100%" />
      </div>
    >
      <Switch>
        <Route isPrivate path={`${path.therapist.home}`} component={TherapistHomePage} />
        <Route isPrivate path={`${path.therapist.schools}/:id`} component={SchoolInfoPage} />
        <Route isPrivate path={`${path.therapist.schools}`} component={SchoolsPage} />
        <Route isPrivate path={`${path.therapist['onboarding-videos']}`} component={OnboardingVideosPage} />
        <Route isPrivate path={`${path.therapist['speech-therapy-tools']}`} component={SpeechTherapyToolsPage} />
        <Route
          isPrivate
          path={`${path.therapist['occupational-therapy-tools']}`}
          component={OccupationalTherapyToolsPage}
        />
        <Route isPrivate path={`${path.therapist['counseling-tools']}`} component={CounselingToolsPage} />
        <Route isPrivate path={`${path.therapist['physical-therapy']}`} component={PhysicalTherapyPage} />
        <Route isPrivate path={`${path.therapist['technology-help']}`} component={TechnologyHelpPage} />
        <Route isPrivate path={`${path.therapist.faq}`} component={FaqPage} />
        <Route isPrivate path={`${path.therapist['star-help']}`} component={StarHelpPage} />
        <Route isPrivate path={`${path.therapist['zoom-help']}`} component={ZoomHelpPage} />
        <Route isPrivate path={`${path.therapist['ca-special-training']}`} component={CASpecialTraining} />
        <Route isPrivate path={`${path.therapist['professional-development']}`} component={ProfessionalDevelopment} />
        <Route isPrivate path={`${path.therapist['onboarding-tasks']}`} component={OnBoardingTasksPage} />
        <Route isPrivate path={`${path.therapist.notification}`} component={NotificationsPage} />
        <Route isPrivate path={`${path.therapist.profile}`} component={ProfilePage} />
        <Route isPrivate path={`${path.therapist.changePassword}`} component={ChangePasswordPage} />
        <Route isPrivate path={`${path.therapist['session-documents']}`} component={SessionDocumentsPage} />
        <Route isPrivate path={`${path.therapist.schoolSessionManagement}`} component={SchoolSessionManagementPage} />
        <Route isPrivate path={`${path.therapist['add-non-schedule-session']}`} component={AddNonScheduleSessionPage} />
        <Route isPrivate path={path.therapist.billing} component={BillingReport} />
        <Route isPrivate path={path.therapist['add-session']} component={AddSessionPage} />
        <Route isPrivate path={path.therapist['pending-sessions']} component={PendingSessionsPage} />
        <Route isPrivate path={path.therapist.sessions} component={Sessions} />
        <Route isPrivate path={path.therapist.student} component={Student} />
        <Route isPrivate path={path.therapist['gened-consent-details']} component={GenedConsentDetails} />
        <Route isPrivate path={path.therapist.assignedFrequency} component={AssignedFrequencyPage} />
        <Route isPrivate path={path.therapist.newsletter} component={NewsletterPage} />
        <Route isPrivate path={path.therapist['student-info']} component={StudentInfoPage} />
        <Route isPrivate path={path.therapist['refer-students']} component={ReferStudentPage} />
        <Route isPrivate path={path.therapist.schedules} component={SchedulesPage} />
        <Route isPrivate path={`${path.therapist['flvs-hosted-iep']}`} component={FLVSHostedIepSchedulePage} />
        <Route isPrivate path={`${path.therapist['assessment-calendar']}`} component={AssessmentReservationsExam} />
        {/* <Route path={`${path.therapist.index}/add-session`} component={AddSessionPage} />
        <Route path={`${path.therapist['my-students']}/:id`} component={StudentPage} />
        <Route path={`${path.therapist.schools}/:id`} component={SchoolInfoPage} />
        <Route path={path.therapist['my-students']} component={MyStudentPage} />
        <Route path={path.therapist['assigned-frequency']} component={AssignedFrequency} />
        <Route path={path.therapist.schools} component={AssignedSchools} />
        <Route path={path.therapist['my-sessions']} component={Sessions} />
        <Route path={path.therapist['onboarding-videos']} component={OnboardingVideosPage} />
        <Route path={path.therapist['star-help']} component={StarHelpPage} />
        <Route path={path.therapist.billing} component={BillingReport} /> */}

        <Redirect from={path.root} to={path.therapist.home} />
      </Switch>
    </React.Suspense>
  );
}
