import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Skeleton from 'react-loading-skeleton';

const ProgressSkeleton = () => (
  <div className="ProgressBar-box pd-10 pd-md-15 mg-b-10 mg-lg-b-15 bd bd-1 rounded bg-gray-100">
    <h4 className="mg-t-0 mg-b-10">
      <Row>
        <Col md={4}>
          <Skeleton className="ht-10" />
        </Col>
      </Row>
    </h4>
    <Skeleton className="ht-20 mg-b-10" />
    <Row>
      <Col md={6} lg={4}>
        <Skeleton className="ht-10" />
      </Col>
      <Col md={6} lg={4} className="tx-lg-center tx-md-right">
        <Skeleton className="ht-10" />
      </Col>
      <Col md={6} lg={4} className="tx-lg-right mg-md-t-5">
        <Skeleton className="ht-10" />
      </Col>
    </Row>
  </div>
);

export default ProgressSkeleton;
