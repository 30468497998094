import { API_THERAPIST_PATH, API_VERSION_PATH } from '../../utils/constants/AppConstant';
import http from './httpService';

export const getAssignedSchools = (params) => http.get(`${API_THERAPIST_PATH}/schools?${params}`);

export const getProfileData = ({ id, params = '' }) => http.get(`${API_THERAPIST_PATH}/${id}?${params}`);

export const getLeadAssignedSchools = ({ id, params = '' }) =>
  http.get(`${API_THERAPIST_PATH}/${id}/lead-assigned-schools?${params}`);

export const updateProfile = ({ id = '', data }) => http.post(`${API_THERAPIST_PATH}/${id}`, data);

export const getUserZohoInfo = ({ id, params = '' }) => http.get(`${API_THERAPIST_PATH}/${id}?${params}`);

export const getBillingList = (therapistId, params) =>
  http.get(`${API_THERAPIST_PATH}/${therapistId}/billing?${params}`);

export const myAvailability = ({ id, params = '' }) => http.get(`${API_THERAPIST_PATH}/${id}/availability?${params}`);

export const addAvailability = ({ id, data }) => http.post(`${API_THERAPIST_PATH}/${id}/availability`, data);

export const updateAvailability = ({ userId, id, data }) =>
  http.put(`${API_THERAPIST_PATH}/${userId}/availability/${id}`, data);

export const deleteAvailability = ({ userId, id }) => http.delete(`${API_THERAPIST_PATH}/${userId}/availability/${id}`);

export const getAllStudent = ({ params = '' }) => http.get(`${API_THERAPIST_PATH}/students?${params}`);

export const getTherapistLicenses = ({ id, params = '' }) => http.get(`${API_THERAPIST_PATH}/${id}/licences?${params}`);

export const addTherapistLicense = ({ id, data }) => http.post(`${API_THERAPIST_PATH}/${id}/licences`, data);

export const deleteTherapistLicense = ({ id, licenseId }) =>
  http.delete(`${API_THERAPIST_PATH}/${id}/licences/${licenseId}`);

export const updateTherapistMobileDeviceStatus = ({ id, data }) =>
  http.post(`${API_THERAPIST_PATH}/${id}/notifications/update-mobile-device-status`, data);

export const therapistLogExtraHours = ({ id, data }) =>
  http.post(`${API_THERAPIST_PATH}/${id}/notifications/log-extra-hours`, data);

export const getTherapistNotification = ({ id, params = '' }) =>
  http.get(`${API_THERAPIST_PATH}/${id}/notifications?${params}`);

export const getTherapistWorkingLicence = ({ id, params = '' }) =>
  http.get(`${API_THERAPIST_PATH}/${id}/working-licence?${params}`);

export const getSpecialCA = ({ id }) => http.get(`${API_THERAPIST_PATH}/${id}/california-training`);

export const getTherapistACHStatus = ({ id, params = '' }) =>
  http.get(`${API_THERAPIST_PATH}/${id}/ach/status?${params}`);

export const addTherapistACHDetails = ({ id, data }) => http.post(`${API_THERAPIST_PATH}/${id}/ach`, data);

export const getTherapistW9FormStatus = ({ id, params = '' }) =>
  http.get(`${API_THERAPIST_PATH}/${id}/w9/status?${params}`);

export const getTherapistLicensesRemaining = ({ id, params = '' }) =>
  http.get(`${API_THERAPIST_PATH}/${id}/licences/remaining?${params}`);

export const getTherapistW9Data = ({ id }) => http.get(`${API_THERAPIST_PATH}/${id}/w9`);

export const uploadTherapistW9File = ({ id, data }) => http.post(`${API_THERAPIST_PATH}/${id}/w9`, data);

export const trackLetsGetStartedVideo = ({ id, data }) =>
  http.post(`${API_THERAPIST_PATH}/${id}/notifications/log-training-video`, data);

export const shareAresource = ({ data }) => http.post(`${API_VERSION_PATH}/tools/suggest`, data);

export const getTherapistPreviousContracts = ({ id, params = '' }) =>
  http.get(`${API_THERAPIST_PATH}/${id}/previous-contract?${params}`);
export const sovDecision = ({ data }) => http.put(`${API_VERSION_PATH}/sov/decision`, data);
export const sovRejectReason = ({ data }) => http.put(`${API_VERSION_PATH}/sov/reject-reason`, data);
export const getSovContractRenewalForm = ({ params = '' }) =>
  http.get(`${API_VERSION_PATH}/sov/download?${params}`, {
    responseType: 'arraybuffer',
  });
export const sovContractRenewalFormUpload = ({ data }) => http.post(`${API_VERSION_PATH}/sov/upload`, data);
